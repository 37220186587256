import React, { useEffect, useState } from 'react';
import { inject, observer } from "mobx-react";
import TopNav from '../LandingPage/TopNav/TopNav';
import PhotoComponent from '../shared/PhotoComponent';
import AddressComponent from '../shared/AddressComponent';
import { handleError } from '../shared/ErrorHandler';
import LoadingPage from '../shared/LoadingPage';
import ReCAPTCHA from "react-google-recaptcha";

import { styles } from "./Settings.css";


const Settings = inject("userStore")(observer((props) => {
	const [isActive, setIsActive] = useState("Personal");
	const [photoPath, setPhotoPath] = useState("");
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		let cancel = false;
		if (!props.userStore.currentUser) {
			props.userStore.getLoginStatus();
		}
		props.userStore.pullUser().then(() => {
			setIsLoading(false);
			if (cancel) return;
			setPhotoPath(props.userStore.userInfo.photoPath);
		}).catch((error) => {
			setIsLoading(false);
			handleError(error, props.history, props.userStore.getLoginStatus);
		});
		return () => {
			cancel = true;
		};
	}, []);

	const itemOnClick = (key) => {
		setIsActive(key);
	};

	const handlePhotoSubmit = (file) => {
		props.userStore.uploadUserPhoto(file).then(() => {
		});
	};

	const LeftPaneNav = (<div className='setting-nav'>
		<div
			key={"Personal"}
			className={`settings-left-nav ${isActive === "Personal" && "m-active"}`}
			onClick={() => itemOnClick("Personal")}
		>
			Personal
		</div>
		<div
			key={"Account"}
			className={`settings-left-nav ${isActive === "Account" && "m-active"}`}
			onClick={() => itemOnClick("Account")}
		>
			Account
		</div>
		<div
			key={"help"}
			className={`settings-left-nav ${isActive === "Help" && "m-active"}`}
			onClick={() => itemOnClick("Help")}
		>
			Help
		</div>
	</div>);

	if (!props.userStore.userInfo) {
		return null;
	}
	return (
		<div className={"page"}>
			<TopNav isLoggedIn={!!props.userStore.currentUser} />
			{!isLoading && <>
				<div className="flex-box-row settings-photo-line ">
					<PhotoComponent photoPath={photoPath} handlePhotoSubmit={handlePhotoSubmit} showPreview={true} />
					{props.userStore.userInfo && <div style={{ "alignSelf": "center", "marginLeft": "40px" }}>
						<p style={{ "fontSize": "xxx-large" }}>{props.userStore.userInfo.name}</p>
						<p>{props.userStore.userInfo.email}</p>
						<p>{props.userStore.userInfo.phone}</p>
					</div>}
				</div>
				<div className="settings-container">
					<div className="settings-left-pane">
						{LeftPaneNav}
					</div>
					<div className="settings-right-pane">
						{isActive === "Personal" && props.userStore.userInfo && <PersonalComponent userInfo={props.userStore.userInfo} />}
						{isActive === "Account" && <PasswordComponent />}
						{isActive === "Help" && <HelpComponent />}
					</div>
				</div></>}
			{isLoading && <LoadingPage />}
		</div>);
}));

export default Settings;

const PersonalComponent = inject("userStore")(observer((props) => {
	const [name, setName] = useState(props.userInfo.name);
	const [phone, setPhone] = useState(props.userInfo.phone);
	const [address, setAddress] = useState(props.userInfo.address);
	const [zipcode, setZipcode] = useState(props.userInfo.zipcode);
	const [disableSave, setDisableSave] = useState(true);

	const handleSave = e => {
		props.userStore.userInfo.name = name;
		props.userStore.userInfo.phone = phone;
		props.userStore.userInfo.address = address;
		props.userStore.userInfo.zipcode = zipcode;
		setDisableSave(true);
		props.userStore.updateUser().then(() => {
		}).catch((error) => {
			setDisableSave(true);
			handleError(error, props.history);
		});
	};

	const handleCancel = e => {
		setName(props.userInfo.name);
		setPhone(props.userInfo.phone);
		setAddress(props.userInfo.address);
		setZipcode(props.userInfo.zipcode)
		setDisableSave(true);
	};

	const handleNameChange = e => {
		setName(e.target.value);
		setDisableSave(false);
	};

	const handlePhoneChange = e => {
		setPhone(e.target.value);
		setDisableSave(false);
	};

	const handleAddressChange = address => {
		setAddress(address);
		setDisableSave(false);
	};

	const handleZipcodeChange = zipcode => {
		setZipcode(zipcode);
		setDisableSave(false);
	};

	return (
		<div className="setting-personal-container">
			<div className="flex-box-row setting-personal">
				<label>Name: </label>
				<input id="name" className="settings-input" value={name ?? ""} onChange={handleNameChange} />
			</div>
			<div className="flex-box-row setting-personal">
				<label>Phone: </label>
				<input id="phone" className="settings-input" placeholder="(XXX)XXX-XXXX" value={phone ?? ""} onChange={handlePhoneChange} />
			</div>
			<div className="flex-box-row setting-personal">
				<AddressComponent
					handleAddressChange={handleAddressChange}
					handleZipcodeChange={handleZipcodeChange}
					address={address ?? ""}
					zipcode={zipcode ?? ""}
					oneLineLayout={true} />
			</div>
			<div className="flex-box-row settings-button-container">
				<button className="settings-button" onClick={handleSave} disabled={disableSave}>Save</button>
				<button className="settings-button" onClick={handleCancel} disabled={disableSave}>Cancel</button>
			</div>
		</div>
	);
}));


const PasswordComponent = inject("authStore")(observer((props) => {
	const [oldPassword, setOldPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setComfirmPassword] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [disableSave, setDisableSave] = useState(true);

	const handlePasswordChange = e => {
		if (oldPassword === "") {
			setErrorMessage("Please input old password");
		}

		if (newPassword === "") {
			setErrorMessage("Please input new password");
		}

		if (confirmPassword === "") {
			setErrorMessage("Please input confirm new password");
		}

		if (confirmPassword !== newPassword) {
			setErrorMessage("Password input are different");
		}

		props.authStore.resetPasswordSettings(oldPassword, newPassword).then(() => {
			setDisableSave(true);
		}).catch((error) => {
			setErrorMessage(error);
		});
	}

	const handleCancel = e => {
		setOldPassword("");
		setNewPassword("");
		setComfirmPassword("");
		setErrorMessage("");
		setDisableSave(true);
	}

	const handleOldPasswordChange = e => {
		setErrorMessage("");
		setOldPassword(e.target.value);
		setDisableSave(false);
	}

	const handleNewPasswordChange = e => {
		setErrorMessage("");
		setNewPassword(e.target.value);
		setDisableSave(false);
	}

	const handleConfirmPasswordChange = e => {
		setErrorMessage("");
		setComfirmPassword(e.target.value);
		setDisableSave(false);
	}

	return (
		<div className="setting-personal-container">
			<div>{errorMessage}</div>
			<div className="flex-box-row setting-personal">
				<label>Old password: </label>
				<input
					id="oldpassword"
					type="password"
					className="settings-input"
					onChange={handleOldPasswordChange}
					value={oldPassword}
				/>
			</div>
			<div className="flex-box-row setting-personal">
				<label>New password: </label>
				<input
					id="newpassword0"
					type="password"
					className="settings-input"
					onChange={handleNewPasswordChange}
					value={newPassword}
				/>
			</div>
			<div className="flex-box-row setting-personal">
				<label>Confirm new password: </label>
				<input
					id="newpassword1"
					type="password"
					className="settings-input"
					onChange={handleConfirmPasswordChange}
					value={confirmPassword}
				/>
			</div>
			<div className="flex-box-row settings-button-container">
				<button className="settings-button" onClick={handlePasswordChange} disabled={disableSave}>Save</button>
				<button className="settings-button" onClick={handleCancel} disabled={disableSave}>Cancel</button>
			</div>
		</div>
	);
}));

const HelpComponent = inject("authStore")(observer((props) => {
	const [feedback, setFeedback] = useState("");
	const [disableSave, setDisableSave] = useState(true);
	const [isVerified, setIsVerified] = useState(false);

	const handleFeedbackChange = e => {
		setFeedback(e.target.value);
		setDisableSave(false);
	};

	const submitFeedback = e => {
		setDisableSave(true);
		props.authStore.sendFeedback(feedback).then(() => {
			setFeedback("");

		}).catch((error) => {
			setDisableSave(false);
			handleError(error, props.history);
		});
	}

	const cancelFeedback = e => {
		setFeedback("");
		setDisableSave(true);
	}

	const handleCaptchaChange = (value) => {
		setIsVerified(!!value);
	};

	return (
		<div>
			<p>Admin email: admin@dragoncanoe.com</p>
			<p>Feedback: </p>
			<textarea
				style={{ "width": "100%", "height": "200px" }}
				onChange={handleFeedbackChange}
				value={feedback}
			/>
			<div className="flex-box-row settings-button-container">
				<ReCAPTCHA
					sitekey="6LdVU5YmAAAAAL-ybQCUKMeNbpt9R9LAcepevIJL"
					onChange={handleCaptchaChange}
				/>
				<button className="settings-button" onClick={submitFeedback} disabled={disableSave || !isVerified}>Send</button>
				<button className="settings-button" onClick={cancelFeedback} disabled={disableSave || !isVerified}>Cancel</button>
			</div>
		</div>
	);
}));