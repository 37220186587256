import { makeAutoObservable } from 'mobx';
import agent from '../agent';

export class QuestionStore {
  questions = [];
  question = null;
  pageCount = 1;

  constructor() {
    makeAutoObservable(this);
  }

  getQuestions(page, params) {
    return agent.Question.getQuestions(page, params)
      .then((response) => {
        this.questions = response.questions;
        this.pageCount = response.page_count;
      });
  }

  createQuestion(title, content, tags, state) {
    return agent.Question.createQuestion("create", title, content, tags, state);
  }

  getQuestion(shortUrl) {
    return agent.Question.getQuestion(shortUrl).then((response) => { this.question = response; });
  }

  deleteQuestion(id) {
    return agent.Question.deleteQuestion(id);
  }

  updateQuestion(id, title, content, tags, state) {
    return agent.Question.updateQuestion(id, title, content, tags, state).then((response) => {
      this.question = response.question;
    });
  }

  voteQuestion(id, vote) {
    return agent.Question.voteQuestion(id, vote);
  }
}
export default new QuestionStore();
