import React, { useEffect, useState } from "react";
import TopNav from './TopNav/TopNav';
import SearchProfile from '../SearchBar/SearchBar';
import agents from '../assets/agents.jpg';
import homepage2 from '../assets/homepage2.jpg';
import profile2 from '../assets/profile2.jpg';
import homepage4 from '../assets/homepage4.jpg';
import offer from '../assets/offer.jpg';
import event from '../assets/event.jpg';
import profile from '../assets/profile.jpg';
import question from '../assets/question.jpg';
import { observer, inject } from "mobx-react";
import { Link } from 'react-router-dom';

import styles from './LandingPage.css';


const LandingPage = inject("userStore")(observer((props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!props.userStore.currentUser);
  useEffect(() => {
    if (!isLoggedIn) {
      props.userStore.getLoginStatus().then(() => {
        setIsLoggedIn(!!props.userStore.currentUser);
      });
    }
  }, []);

  return (
    <div className="page">
      <TopNav isLoggedIn={isLoggedIn} />
      <div className="search-area">
        <p className='serach-text'>Search Real Estate Service</p>
        <SearchProfile history={props.history} />
      </div>
      <div className="landing-catogary-text ">For home buyers, sellers and owners</div>
      <div className='landing-page-bottom'>
        <div className='text-container'>
          <Link to="/profiles/0" className="landing-item-title">Find a Realtor</Link>
          <img src={agents} className='landing-image' />
          <ul className='landing-list-type'>
            <li>Seach for your realtor nationwide <Link to="/profiles/0" className="landing-item-title"><i class="fas fa-external-link-alt"></i></Link></li>
            <li>Submit your request specifying your desired commission rates, and let our network of experienced Buyer Agents compete to represent you <Link to="/offer" className="landing-item-title"><i class="fas fa-external-link-alt"></i></Link></li>
          </ul>
        </div>
        <div className='text-container'>
          <Link to="/offer" className="landing-item-title">Create Purchase Offer</Link>
          <img src={offer} className='landing-image' />
          <ul className='landing-list-type'>
            <li>Save tone of commissions with drafting your own purchase offer as the buyer</li>
            <li>Our questionnaire form helps you create your purchase offer only at $25</li>
          </ul>
        </div>
        <div className='text-container'>
          <Link to="/events/0" className="landing-item-title">Attend Event</Link>
          <img src={profile2} className='landing-image' />
          <ul className='landing-list-type'>
            <li>Seek out events hosted by realtors and business owners to gain valuable real estate knowledge</li>
          </ul>
        </div>
        <div className='text-container'>
          <Link to="/questions/0" className="landing-item-title">Ask Question</Link>
          <img src={question} className='landing-image' />
          <ul className='landing-list-type'>
            <li>Share your real estate queries to gain insightful responses</li>
          </ul>
        </div>
      </div>
      <hr />
      <div className="landing-catogary-text ">For Realtor and real estate business owner</div>
      <div className="landing-catogary-note">Real Estate Broker, Real Estate Agent, Real Estate Appraiser, Real Estate Inspector, Landscaper, Home Stager, Cleaning Service Provider, Repair and Remodel Service Provider, Moving Service Provider...</div>
      <div className='landing-page-bottom'>
        <div className='text-container'>
          <Link to="/profile-personal" className="landing-item-title">Create Business Profile</Link>
          <img src={profile} className='landing-image' />
          <ul className='landing-list-type'>
            <li>Create your profile to advertise your business</li>
          </ul>
        </div>
        <div className='text-container'>
          <div className="landing-item-title">Build your social network</div>
          <img src={homepage4} className='landing-image' />
          <ul className='landing-list-type'>
            <li>Connect all real estate business owners</li>
          </ul>
        </div>
        <div className='text-container'>
          <Link to="/events/0" className="landing-item-title">Host an Event</Link>
          <img src={event} className='landing-image' />
          <ul className='landing-list-type'>
            <li>Host events to attract potential customers</li>
          </ul>
        </div>
        <div className='text-container'>
          <Link to="/questions/0" className="landing-item-title">Answer Question</Link>
          <img src={homepage2} className='landing-image' />
          <ul className='landing-list-type '>
            <li>Enhance your visibility by providing answers to questions</li>
          </ul>
        </div>
      </div>
    </div>
  );
}));

export default LandingPage;
