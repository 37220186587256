import { makeAutoObservable } from 'mobx';
import agent from '../agent';

export class CommentsStore {
  commentErrors = undefined;
  comments = [];

  constructor() {
    makeAutoObservable(this);
  }

  loadComments(targetId, commentType) {
    return agent.Comments.loadComments(targetId, commentType)
      .then((response) => { this.comments = response.comment; });
  }

  createComment(comment, targetId, commentType, parentId, replyUserId, replyType) {
    this.isCreatingComment = true;
    return agent.Comments.create(comment, targetId, parentId, replyUserId, replyType)
      .then(() => this.loadComments(targetId, commentType));
  }

  deleteComment(targetId, commentType, id) {
    return agent.Comments.delete(id)
      .then(() => { this.loadComments(targetId, commentType); });
  }

  updateComment(targetId, commentType, commentId, comment) {
    return agent.Comments.update(commentId, comment)
      .then(() => { this.loadComments(targetId, commentType); });
  }

  reportComment(commentId, reportContent) {
    return agent.Report.reportProblem(`comment_${commentId}`, reportContent);
  }
}

export default new CommentsStore();
