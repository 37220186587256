import React from "react";
import TopNav from "../LandingPage/TopNav/TopNav";

const NotFoundPage = () => {
    return (
        <div className="page">
            <TopNav isLoggedIn={false} />
            <h1>404 - Page Not Found</h1>
            <p>The page you are looking for does not exist.</p>
            {/* You can include navigation links or a search bar here */}
        </div>
    );
};

export default NotFoundPage;
