
import React from 'react';
import "./Footer.css";


const Footer = props => {
    return (
        <div className='page-footer'>
            <div>&copy; Antares Technology LLC 2024</div>
            <div>Contact: admin@dragoncanoe.com</div>
        </div>
    );
};

export default Footer;