
export function hiddenEmail(email) {
    if (email === null || email === undefined || email === "") {
        return email;
    }
    const parts = email.split('@');

    // Hide characters in username part
    const hiddenUsername = parts[0].substring(0, 3) + '*'.repeat(parts[0].length - 3);

    // Concatenate hidden username with domain part
    return hiddenUsername + '@' + parts[1];
}