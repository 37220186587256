export function handleError(error, history, checkLoginStatus) {
    var redirectPage = "/";
    if (error.status === 401) {
        if (checkLoginStatus) {
            checkLoginStatus();
            return;
        } else {
            redirectPage = "/login";
        }
    } else if (error.status === 404) {
        redirectPage = "/not-found";
    } else {
        redirectPage = "/network-error";
    }
    if (history) {
        history.push(redirectPage);
    } else {
        window.location.href = redirectPage;
    }
}