import React, { useState } from "react";
import TopNav from '../LandingPage/TopNav/TopNav';
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { validateEmail } from "../shared/ValidateEmail";

import styles from "./Login.css";


const Login = inject("authStore", "userStore")(observer((props) => {
	const [password, setPassword] = useState("");
	const [email, setEmail] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	const handleEmailChange = e => {
		setEmail(e.target.value);
		setErrorMessage("");
	};

	const handlePasswordChange = e => {
		setErrorMessage("");
		setPassword(e.target.value);
	};

	const handleSubmitForm = e => {
		e.preventDefault();

		if (email === "") {
			setErrorMessage("Email is empty");
			return;
		}

		if (validateEmail(email)) {
			setErrorMessage("Please correct email");
			return;
		}

		if (password === "") {
			setErrorMessage("Password is empty");
			return;
		}
		setErrorMessage("");
		props.authStore.login(email, password).then(() => {
			if (props.authStore.errorMessage === "") {
				const prevLocation = localStorage.getItem('prevLocation') || '/';
				localStorage.removeItem('prevLocation');
				props.history.push(prevLocation);
			} else {
				setErrorMessage(props.authStore.errorMessage);
			}
		})
	};

	return (
		<div className="page">
			<TopNav isLoggedIn={!!props.userStore.currentUser} />
			<form className="login-form" onSubmit={handleSubmitForm}>
				<div className="login-text">Sign In</div>
				<input
					className="login-input"
					type="email"
					placeholder="Email"
					onChange={handleEmailChange}
				/>
				<input
					className="login-input"
					type="password"
					placeholder="Password"
					onChange={handlePasswordChange}
				/>
				<div style={{ "fontSize": "small" }}>By signing in you agree <Link to="terms">terms of use</Link> and <Link to="privacy">privacy policy</Link></div>
				<button
					className="login-button"
					type="submit"
				>
					Sign in
				</button>
				<Link to='/reset' className="reset-link">Forgot password</Link>
				<div className="error-message">{errorMessage}</div>
			</form>
		</div>
	);
}));

export default Login;