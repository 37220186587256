import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import agent from "../agent";


const DownloadButton = ({ id, enabled, iconClassname, className, iconButton = false }) => {
  const handleDownload = () => {
    agent.File.downloadPDF(id)
      .then(res => {
        const fileURL = window.URL.createObjectURL(res.body);
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `purchase-document.pdf`);
        document.body.appendChild(fileLink);

        fileLink.click();

        window.URL.revokeObjectURL(fileURL);
        fileLink.parentNode.removeChild(fileLink);
      })
      .catch(err => {
        console.error('Download error:', err);
        if (err.status === 401) {
          const pathWithoutHost = window.location.pathname + window.location.search;
          localStorage.setItem('prevLocation', pathWithoutHost);
          window.location.href = "/login";
        }
      });
  };

  const downloadTooltip = <Tooltip>Download</Tooltip>;
  return (<>
    {!iconButton &&
      <button onClick={handleDownload} disabled={!enabled} className={`${className} ${enabled ? '' : 'disabled'}`}>Download File</button>}
    {iconButton &&
      <OverlayTrigger placement="bottom" overlay={downloadTooltip}>
        <span onClick={handleDownload} className={`${iconClassname} ${enabled ? '' : 'disabled'}`}>
          <i className="fa fa-download"></i>
        </span >
      </OverlayTrigger>}
  </>
  );
};

export default DownloadButton;
