import React, { useState, useEffect } from 'react';
import StatesSelect from "./StatesSelect";
import { observer } from "mobx-react";
import { addreessForm } from './PurchaseForm';

import styles from "./AddressComponent.css";

const AddressComponent = observer(({ value, onChange, errors }) => {
    const [address, setAddress] = useState(null);

    useEffect(() => {
        if (value)
            setAddress(value);
        else
            setAddress(addreessForm);
    }, [value]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setAddress((prevAddress) => {
            const updatedAddress = { ...prevAddress, [name]: value };
            onChange(updatedAddress);
            return updatedAddress;
        });
    };

    if (!address) {
        return null;
    }
    return (
        <div>
            <input
                name="street"
                value={address.street}
                onChange={handleChange}
                placeholder="Street"
                className="address_input_border"
            />
            {errors && errors.street && <label className='error-message-form '>{errors.street}</label>}
            <div className="address_container_2">
                <input
                    name="apt"
                    value={address.apt}
                    onChange={handleChange}
                    placeholder="Apartment, suite, etc. (optional)"
                    className="address_input_border"
                    style={{ "marginRight": "4px" }}
                />
                <input
                    name="city"
                    value={address.city}
                    onChange={handleChange}
                    placeholder="City"
                    className="address_input_border"
                    style={{ "marginRight": "4px" }}
                />
                <input
                    name="county"
                    value={address.county}
                    onChange={handleChange}
                    placeholder="County"
                    className="address_input_border"
                    style={{ "marginRight": "4px" }}
                />
                <input
                    name="zipcode"
                    value={address.zipcode}
                    onChange={handleChange}
                    placeholder="Zip Code"
                    className="address_input_border"
                    style={{ "marginRight": "4px" }}
                />

                <StatesSelect
                    className="address_input_border"
                    value={address.state}
                    onChange={handleChange}
                />
            </div>
            {errors && errors.city && <div className='error-message-form '>{errors.city}</div>}
            {errors && errors.zipcode && <div className='error-message-form '>{errors.zipcode}</div>}
            {errors && errors.state && <div className='error-message-form '>{errors.state}</div>}
            {false && <input
                name="country"
                value={address.country}
                onChange={handleChange}
                placeholder="Country"
                className="address_input_border"
            />}
        </div>
    );
});

export default AddressComponent;
