import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { modules, formats } from '../shared/QuillEditorConfig';
import { timeAgo } from "../shared/TimeConverter";
import { hiddenEmail } from "../shared/hidenEmail";
import ProfileLink from "../Profile/ProfileLink";
import { handleError } from "../shared/ErrorHandler";

import styles from "./AnswersComp.css";


const AnswersComp = inject("answerStore")(observer((props) => {
    useEffect(() => {
        if (props.parentId) {
            props.answerStore.loadAnswers(props.parentId);
        }
    }, [props.parentId]);

    const Answers = observer((props) => {
        return (
            <ul>
                {props.answers.map((answer, index) => (
                    <AnswerComp key={index} answer={answer} parentId={props.parentId} />
                ))}
            </ul>
        );
    });

    return (
        <div style={{ "paddingTop": "20px" }}>
            <p style={{ "fontSize": "25px" }}>Answers:</p>
            {props.answerStore.answers.length > 0 &&
                <Answers answers={props.answerStore.answers} parentId={props.parentId} />
            }
            <PostAnswerComponent parentId={props.parentId} />
        </div>
    );
}));


const AnswerComp = inject("answerStore")(observer((props) => {
    const [isEdting, setIsEditing] = useState(false);
    const [content, setContent] = useState(props.answer.content);
    const [vote, setVote] = useState(Number(props.answer.vote));

    const handleAnswerDelete = e => {
        props.answerStore.deleteAnswer(props.answer.id, props.parentId).catch((error) => {
            handleError(error, props.history);
        });
    }

    const handleAnswerEdit = e => {
        setIsEditing(true)
    }

    const onVoteUp = e => {
        props.answerStore.voteAnswer(props.answer.id, 1).then((response) => {
            setVote(vote + Number(response.vote));
        }).catch((error) => {
            handleError(error, props.history);
        });
    }

    const onVoteDown = e => {
        props.answerStore.voteAnswer(props.answer.id, -1).then((response) => {
            setVote(vote + Number(response.vote));
        }).catch((error) => {
            handleError(error, props.history);
        });
    }

    const handleEditCancel = e => {
        setIsEditing(false);
    }

    const handleEditDone = e => {
        props.answerStore.updateAnswer(props.answer.id, props.parentId, content).catch((error) => {
            handleError(error, props.history);
        });
        setIsEditing(false);
    }

    const handleContentChange = value => {
        setContent(value);
    }

    return (
        <div className="answer-container">
            <div style={{ "paddingRight": "20px" }}>
                <span className="vote-icon" onClick={onVoteUp}><i className="fa fa-angle-up"></i></span >
                <div className="vote-text">{vote}</div>
                <span className="vote-icon" onClick={onVoteDown}><i className="fa fa-angle-down"></i></span >
            </div>
            {!isEdting && <div style={{ "paddingTop": "10px" }}>
                <div dangerouslySetInnerHTML={{ __html: content }}></div>
                <div className="row-container" style={{ "marginTop": "20px" }}>
                    <div className="flex-box-row">
                        {!props.answer.profileId && <div>{hiddenEmail(props.answer.authorEmail)}</div>}
                        {props.answer.profileId && <ProfileLink
                            author={props.answer.authorEmail}
                            userId={props.answer.authorId}
                            hiddenEmail={!props.answer.is_editable}
                        />}
                        <div style={{ "marginLeft": "10px" }}>posted {timeAgo(props.answer.creation_time)}</div>
                    </div>
                    {props.answer.is_editable && < div className="flex-box-row">
                        <div className="report-event" style={{ "marginRight": "20px" }} onClick={handleAnswerDelete}>Delete</div>
                        <div className="report-event" onClick={handleAnswerEdit}>Edit</div>
                    </div>}
                </div>
            </div>}
            {isEdting && <div style={{ "width": "100%" }}>
                <ReactQuill
                    id="Description"
                    className="create-answer-textarea"
                    value={content}
                    onChange={handleContentChange}
                    modules={modules}
                    formats={formats}
                />
                <div className="row-container" style={{ "marginLeft": "10px", "marginTop": "20px" }}>
                    <div className="flex-box-row">
                        {!props.answer.profileId && <div>{hiddenEmail(props.answer.authorEmail)}</div>}
                        {props.answer.profileId && <ProfileLink
                            author={props.answer.authorEmail}
                            userId={props.answer.authorId}
                            hiddenEmail={!props.answer.is_editable}
                        />}
                        <div style={{ "marginLeft": "10px" }}>posted {timeAgo(props.answer.creation_time)}</div>
                    </div>
                    <div className="flex-box-row">
                        <button className="comment-button" onClick={handleEditCancel} style={{ "marginRight": "20px" }}>Cancel</button>
                        <button className="comment-button" onClick={handleEditDone}>Update</button>
                    </div>
                </div>
            </div>}
        </div >
    );
}));


const PostAnswerComponent = inject("answerStore", "userStore")(observer((props) => {
    const [content, setContent] = useState("");

    const handleEditDone = e => {
        props.answerStore.addAnswer("create", props.parentId, content).catch((error) => {
            handleError(error, props.history);
        });
    }

    const handleContentChange = value => {
        setContent(value);
    }

    return (
        <div style={{ "marginTop": "30px" }}>
            <ReactQuill
                id="Description"
                className="create-answer-textarea"
                placeholder='Write your answer'
                value={content}
                onChange={handleContentChange}
                modules={modules}
                formats={formats}
            />
            <div style={{ "float": "left" }}>
                <button className="answer-button" onClick={handleEditDone}>Add your answer</button>
            </div>
        </div>
    );
}));


export default AnswersComp;