import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { modules, formats } from '../shared/QuillEditorConfig';
import AnswersComp from "./AnswersComp";
import TopNav from '../LandingPage/TopNav/TopNav';
import { timeAgo } from "../shared/TimeConverter";
import { hiddenEmail } from "../shared/hidenEmail";
import ProfileLink from "../Profile/ProfileLink";
import TagsInput from "../shared/TagsComp";
import StatesSelect from "../shared/StatesSelect"
import { handleError } from "../shared/ErrorHandler";

import styles from "./QuestionDetailView.css";


const QuestionDetailView = inject("questionStore", "userStore")(observer((props) => {
    const pathnames = props.location.pathname.split("/");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [content, setContent] = useState("");
    const [title, setTitle] = useState("");
    const [vote, setVote] = useState(0);
    const [state, setState] = useState("");
    const [tags, setTags] = useState([]);

    useEffect(() => {
        if (!props.userStore.currentUser) {
            props.userStore.getLoginStatus();
        }
        props.questionStore.getQuestion(pathnames[pathnames.length - 1]).catch((error) => {
            setIsLoading(false);
            setError("Failed to load question. Please try again later.");
        });
    }, []);

    useEffect(() => {
        if (props.questionStore.question) {
            setContent(props.questionStore.question.content);
            setTitle(props.questionStore.question.title);
            setIsLoading(false);
            setVote(Number(props.questionStore.question.vote));
            setState(props.questionStore.question.state);
            if (props.questionStore.question.tags) {
                setTags(props.questionStore.question.tags.split(","));
            } else {
                setTags([]);
            }
        }
    }, [props.questionStore.question]);


    const handleEditQuestion = e => {
        setIsEditing(true);
    }

    const handleDeleteQuestion = e => {
        props.questionStore.deleteQuestion(props.questionStore.question.id)
            .then(() => {
                props.history.push("/questions/0'");
            })
            .catch((error) => {
                handleError(error, props.history);
            });
    }

    const handleCancelEdit = e => {
        setIsEditing(false);
    }

    const handleEditDone = e => {
        props.questionStore.updateQuestion(
            props.questionStore.question.id,
            title,
            content,
            tags.join(","),
            state
        ).catch((error) => {
            setIsLoading(false);
            setError("Failed to load question. Please try again later.");
        });
        setIsEditing(false);
    }

    const handleContentChange = value => {
        setContent(value);
    }

    const handleTitleChange = e => {
        setTitle(e.target.value);
    }

    const askQuestionHandler = id => {
        props.history.push("/create_question")
    }

    const onVoteUp = e => {
        props.questionStore.voteQuestion(props.questionStore.question.id, 1).then((response) => {
            setVote(vote + Number(response.vote));
        }).catch((error) => {
            handleError(error, props.history);
        });
    }

    const onVoteDown = id => {
        props.questionStore.voteQuestion(props.questionStore.question.id, -1).then((response) => {
            setVote(vote + Number(response.vote));
        }).catch((error) => {
            handleError(error, props.history);
        });
    }

    const handleStateChange = e => {
        setState(e.target.value);
    };

    const handleTagsChange = tags => {
        setTags(tags);
    }

    const TagList = observer((props) => {
        const tags = props.tags.split(",");
        return tags.map((value, index) => (
            <div key={index} className="badge">
                {value}
            </div>
        ));
    });

    if (props.questionStore.question === null) {
        return null;
    }

    return (<div className="page">
        <TopNav isLoggedIn={!!props.userStore.currentUser} />
        <div className="question-container">
            <button onClick={askQuestionHandler} className="ask-question-button">Ask Question</button>
            <div className="questino-title">{title}</div>
            <div className="flex-box-row">
                <div style={{ "paddingRight": "20px", "paddingTop": "10px" }}>
                    <span className="vote-icon" onClick={onVoteUp}><i className="fa fa-angle-up"></i></span >
                    <div className="vote-text">{vote}</div>
                    <span className="vote-icon" onClick={onVoteDown}><i className="fa fa-angle-down"></i></span >
                </div>
                {!isEditing && <div style={{ "paddingTop": "20px" }}>
                    <div dangerouslySetInnerHTML={{ __html: content }}></div>
                    <div className="row-container" style={{ "marginTop": "20px" }}>
                        <div className="flex-box-row">
                            {!props.questionStore.question.profileId && <div>{hiddenEmail(props.questionStore.question.authorEmail)}</div>}
                            {props.questionStore.question.profileId && <ProfileLink
                                author={props.questionStore.question.authorEmail}
                                userId={props.questionStore.question.authorId}
                                hiddenEmail={!props.questionStore.question.is_editable}
                            />}
                            <div style={{ "marginLeft": "10px" }}>posted {timeAgo(props.questionStore.question.creatTime)}</div>
                        </div>
                        {props.questionStore.question.is_editable && < div className="flex-box-row">
                            <div onClick={handleDeleteQuestion} className="update-question">Delete</div>
                            <div onClick={handleEditQuestion} className="update-question">Edit</div>
                        </div>}
                    </div>
                    <div className="flex-box-row" style={{ "marginTop": "10px", "flexWrap": "wrap" }}>
                        {props.questionStore.question.state && <><div style={{ "marginRight": "10px" }}>State: </div><div style={{ "marginRight": "10px" }} className="badge"> {props.questionStore.question.state}</div></>}
                        {props.questionStore.question.tags && <><div style={{ "marginRight": "10px" }}>Tags: </div><TagList tags={props.questionStore.question.tags} /></>}
                    </div>
                </div>}
                {isEditing && <div style={{ "paddingTop": "20px", "width": "100%" }}>
                    <div className="flex-box-row">
                        <input
                            value={title}
                            onChange={handleTitleChange}
                            className="create-question-input"
                        />
                        <StatesSelect
                            className="state-select"
                            value={state}
                            onChange={handleStateChange}
                        />
                    </div>
                    <ReactQuill
                        id="question"
                        className="create-question-textarea"
                        value={content}
                        onChange={handleContentChange}
                        modules={modules}
                        formats={formats}
                    />
                    <TagsInput className="create-question-input" onChange={handleTagsChange} tags={tags} />
                    <div className="row-container" style={{ "marginLeft": "10px", "marginTop": "20px" }}>
                        <div className="flex-box-row">
                            {!props.questionStore.question.profileId && <div>{hiddenEmail(props.questionStore.question.authorEmail)}</div>}
                            {props.questionStore.question.profileId && <ProfileLink
                                author={props.questionStore.question.authorEmail}
                                userId={props.questionStore.question.authorId}
                                hiddenEmail={!props.questionStore.question.is_editable}
                            />}
                            <div style={{ "marginLeft": "10px" }}>posted {timeAgo(props.questionStore.question.creatTime)}</div>
                        </div>
                        <div className="flex-box-row">
                            <button className="comment-button" onClick={handleCancelEdit} style={{ "marginRight": "20px" }}>Cancel</button>
                            <button className="comment-button" onClick={handleEditDone}>Update</button>
                        </div>
                    </div>
                </div>}
            </div>
            <AnswersComp parentId={props.questionStore.question.id} />
        </div>
    </div >);
}));

export default QuestionDetailView;