import React, { useState, useEffect } from 'react';
import agent from '../agent';
import TopNav from '../LandingPage/TopNav/TopNav';

import style from "./OfferRequest";
import 'react-datepicker/dist/react-datepicker.css';
import { inject, observer } from 'mobx-react';
import { useLocation, Link } from 'react-router-dom';
import { generateAddressStringFromObject } from '../shared/GenerateAddressString';
import { loanVerification, getLabelByValue, getOfferRequestStatusText } from "../shared/options";


const OfferPreview = inject("authStore", "userStore")(observer((props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const [offerRequest, setOfferRequest] = useState(null);
  const [status, setStatus] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(!!props.userStore.currentUser);

  const navigateToLink = (link) => {
    props.history.push(link)
  };

  useEffect(() => {
    if (!isLoggedIn) {
      props.userStore.getLoginStatus().then(() => {
        setIsLoggedIn(!!props.userStore.currentUser);
      });
    }
  }, []);

  useEffect(() => {
    try {
      if (!!id && id !== "") {
        agent.Offer.getPurchaseRequest(id).then((response) => {
          if (response && response.content) {
            setOfferRequest(response.content);
            setStatus(response.status);
          }
        });
      }
    } catch (error) {
      console.error('Error during get offer request:', error.response || error);
    }
  }, [id]);

  if (!offerRequest)
    return null;

  return <div className="page">
    <TopNav isLoggedIn={isLoggedIn} />
    <div className="offer-container">
      <label className='form-title'>Find an agent to make purchase offer{status ? ` (${getOfferRequestStatusText(status.status)})` : ''}</label>
      <div className='category-title'>Property</div>
      <div>You would purchase the property ({offerRequest.property.type}) located:</div>
      <div><b>{generateAddressStringFromObject(offerRequest.property.address)}</b></div>
      <div className='category-title'>Buyer</div>
      <div>You are <b>{offerRequest.buyers.type}</b> buyer(s)</div>
      {offerRequest.buyers.data.map((buyer, index) => {
        return <div key={index} style={{ "marginLeft": "20px" }}>
          <div style={{ "marginLeft": "-20px" }}>Buyer{offerRequest.buyers.data.length > 1 ? ` ${index + 1}:` : ":"}</div>
          <div>Name: <b>{`${buyer.firstName} ${buyer.lastName}`}</b></div>
          <div>Address: <b>{generateAddressStringFromObject(buyer.address)}</b></div>
          <div>Phone: <b>{buyer.phone}</b></div>
          <div>Email: <b>{buyer.email}</b></div>
        </div>
      })}
      <div className='category-title'>Agent</div>
      {offerRequest.agent.type == "flatRage" && <div> The commission you offer to agent : <b>${offerRequest.agent.commission}</b> </div>}
      {offerRequest.agent.type == "fullService" && <div>The commission you offer to agent: <b>{offerRequest.agent.commission}</b> % of property price</div>}
      <div>You <b>{offerRequest.agent.remote ? "" : "don't"} accept</b> your agent work <b>remotely</b></div>
      <div>You want your offer completed within: <b>{offerRequest.agent.dayToMakeOffer} day(s)</b></div>
      <div>You have comment for your agent: <b>{offerRequest.agent.comment}</b></div>
      <div className='category-title'>Financing</div>
      <div>The purchase price you would like to pay:<b>$ {offerRequest.purchase.price}</b></div>
      {offerRequest.purchase.type === "allCash" && <div>You will pay the propery all cash</div>}
      {offerRequest.purchase.type === "financing" && <div>
        <div>You will pay the property with financing. Loan type is <b>{offerRequest.purchase.loanType}</b></div>
        <div>The downpayment percentage: <b>$ {offerRequest.purchase.downPayment}</b></div>
        <div>Expect or your pre-approval loan annual rate: <b>{offerRequest.purchase.loanFixedRate}</b> %</div>
        <div>length of loan: <b>{offerRequest.purchase.lengthOfLoan}</b> month(s)</div>
        <div>Your have <b>{getLabelByValue(offerRequest.purchase.loanVerification, loanVerification)}</b></div>
      </div>}
      <div className='flex-box-row' style={{ "justifyContent": "center" }}>
        <button onClick={() => navigateToLink("/offer")}>Make another offer</button>
      </div>
    </div>
  </div>
}));
export default OfferPreview;

