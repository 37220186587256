import { makeAutoObservable, observable, action } from 'mobx';
import agent from '../agent';

class RatingStore {
    constructor() {
        makeAutoObservable(this);
    }

    getRating(targetId, targetType) {
        return agent.Rating.getRating(targetId, targetType);
    }

    postRating(targetId, targetType, rating) {
        return agent.Rating.postRating(targetId, targetType, rating);
    }
}

export default new RatingStore();
