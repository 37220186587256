export const profileOptions = [
    "Agent",
    "Appraiser",
    "Broker",
    "Home Cleaning Expert",
    "Home Stager",
    "Inspector",
    "Landscaper",
    "Moving expert",
    "Loan Agent",
    "Photographer",
    "Remodeling Expert",
    "Other service provider"
];