import React from 'react';
import './ResultPopup.css';

const ResultPopup = ({ message, isSuccess, onClose }) => {
    return (
        <div className="popup-overlay">
            <div className={`popup ${isSuccess ? 'success' : 'failure'}`}>
                <div className="popup-content">
                    <div className='flex-box-row' style={{ "justifyContent": "flex-end" }}>
                        <span className="close-btn" onClick={onClose}><i className="fa fa-times"></i></span>
                    </div>
                    {!isSuccess && <span className='failure'><i className="fa fa-times-circle"></i></span>}
                    {isSuccess && <span className='success'><i className="fa fa-check-circle"></i></span>}
                    <p>{message}</p>
                </div>
            </div>
        </div>
    );
};

export default ResultPopup;
