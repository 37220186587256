import React, { useState } from 'react';
import styles from './TopNav.css';
import { Link } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import logo from '../../assets/dc_logo.png';


const TopNav = inject("authStore")(observer((props) => {
    const [isVisible, setVisible] = useState(false);

    const toggleCollapsedMenu = () => {
        setVisible(!isVisible);
    }

    const handleLogout = e => {
        props.authStore.logout().then(() => window.location.reload());
    };

    const LoggedInView = (
        <div>
            <Link className='top-nav-bar large-view' to='/profile-personal'>For a business</Link>
            <Link className='top-nav-bar small-view' to='/profile-personal'>
                <span className='icon'>
                    <i className="fas fa-user-circle"></i>
                </span>
            </Link>
            <Link className='top-nav-bar large-view' to='/settings'>Settings</Link>
            <Link className='top-nav-bar small-view' to='/settings'>
                <span className='icon'>
                    <i className="fa fa-cog"></i>
                </span>
            </Link>
            <button className='top-nav-bar large-view' onClick={handleLogout}>Log out</button>
            <button className='top-nav-bar small-view' onClick={handleLogout}>
                <span className='icon'>
                    <i className="fas fa-sign-out-alt"></i>
                </span>
            </button>
        </div>
    );

    const LoggedOutView = (
        <div>
            <Link className='top-nav-bar large-view' to='/login'>Sign In</Link>
            <Link className='top-nav-bar large-view' to='/signup'>Register</Link>
            <Link className='top-nav-bar small-view' to='/login'>
                <span className='icon'>
                    <i className="fas fa-sign-in-alt"></i>
                </span>
            </Link>
            <Link className='top-nav-bar small-view' to='/signup'>
                <span className='icon'>
                    <i className="fas fa-user-plus"></i>
                </span>
            </Link>
        </div>
    );

    const smallView = (
        <div className='top-nav-pop-up'>
            <Link className='top-nav-bar' to='/events/0'>Events</Link>
            <Link className='top-nav-bar' to='/questions/0'>Questions</Link>
            <Link className='top-nav-bar' to='/offer'>Offer</Link>
            {props.isLoggedIn && <Link className='top-nav-bar' to='/profile-personal'>For a business</Link>}
            {props.isLoggedIn && <Link className='top-nav-bar' to='/settings'>Settings</Link>}
            {!props.isLoggedIn && <Link className='top-nav-bar' to='/login'>Sign In</Link>}
            {!props.isLoggedIn && <Link className='top-nav-bar' to='/signup'>Register</Link>}
        </div>
    )

    return (
        <div>
            <div className='top-nav expand-view'>
                <div className="">
                    <Link className='top-nav-bar' to='/' style={{ "width": "100px" }}>
                        <img src={logo} className='logo' />
                        <label>Dragon Canoe</label>
                    </Link>
                    <Link className='top-nav-bar' to='/events/0'>Events</Link>
                    <Link className='top-nav-bar' to='/questions/0'>Questions</Link>
                    <Link className='top-nav-bar' to='/offer'>Offer</Link>
                </div>
                {props.isLoggedIn && LoggedInView}
                {!props.isLoggedIn && LoggedOutView}
            </div>
            <div className='top-nav collapse-view'>
                <Link className='top-nav-bar' to='/' style={{ "width": "200px" }}>
                    <img src={logo} className='logo' />
                    <label>Dragon Canoe</label>
                </Link>
                <div>
                    <span onClick={toggleCollapsedMenu} className='top-nav-bar'><i className='fa fa-ellipsis-h'></i></span>
                    {isVisible && smallView}
                    <button className='top-nav-bar large-view' onClick={handleLogout}>Log out</button>
                    <button className='top-nav-bar small-view' onClick={handleLogout}>
                        <span className='icon'>
                            <i className="fas fa-sign-out-alt"></i>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
}));

export default TopNav;