import React, { useState } from 'react';

const TagsInput = (props) => {
    const [tags, setTags] = useState(props.tags ? props.tags : []);
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleInputKeyDown = (event) => {
        if (event.key === 'Enter' && inputValue.trim() !== '') {
            var newTag = inputValue.trim().toLowerCase();
            if (tags.length >= 10) {
                return;
            }
            if (newTag.length > 25) {
                newTag = newTag.substring(0, 25);
            }
            if (!tags.includes(newTag)) {
                const newTags = [...tags, newTag]
                setTags(newTags);
                setInputValue('');
                if (props.onChange) {
                    props.onChange(newTags);
                }
            }
        }
    };

    const handleTagDelete = (tag) => {
        const updatedTags = tags.filter((t) => t !== tag);
        setTags(updatedTags);
        if (props.onChange) {
            props.onChange(updatedTags);
        }
    };

    return (
        <div >
            <input
                type="text"
                placeholder="Add tags (max 10 tags and each tag less than 25 letters)"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleInputKeyDown}
                className={props.className}
            />
            <div>
                {tags.map((tag) => (
                    <div key={tag} className="tag" style={{ "margin": "10px" }}>
                        {tag}
                        <button onClick={() => handleTagDelete(tag)}>X</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TagsInput;
