import React, { useState } from 'react';
import styles from "./FloatButton.css";

const FloatButton = (props) => {
    const handleClick = () => {
        if (props.onClick) {
            props.onClick();
        }
    };

    return (
        <button
            className={`float-button ${props.className}`}
            onClick={handleClick}
        >
            <span className='icon'>
                <i className="fas fa-plus"></i>
            </span>
        </button>
    );
};

export default FloatButton;
