import React, { useState } from "react";
import TopNav from '../LandingPage/TopNav/TopNav';
import { inject, observer } from "mobx-react";
import { Spinner } from '../Spinner/Spinner'

import { styles } from './ResetPassword.css';


const ResetPassword = inject("authStore", "userStore")(observer((props) => {
	const [isPasswordReset, setIsPasswordReset] = useState(false);
	const [isRedirecting, setIsRedirecting] = useState(false);
	const [email, setEmail] = useState("");

	const handleEmailChange = e => {
		setEmail(e.target.value);
	};

	const handleSubmitForm = e => {
		e.preventDefault();
		setIsPasswordReset(true);
		setIsRedirecting(true);
		props.authStore.resetPassword(email).then(() => {
			setIsRedirecting(false);
			props.history.replace("/");
		});
	};

	const passwordResetPage = (
		<>
			<div> Password reset successfully. New password is sent to your email.</div>
			{isRedirecting &&
				(
					<>
						<div>Redirecting</div>
						<Spinner />
					</>
				)
			}
		</>
	);
	const passwordNotResetPage = (
		<div className="reset-password-container">
			<form onSubmit={handleSubmitForm}>
				<input
					className="reset-input"
					type="email"
					placeholder="Email"
					onChange={handleEmailChange}
				/>
				<button className="reset-button" type="submit">
					Reset password
				</button>
			</form>
		</div>
	);

	const resetPassword = isPasswordReset ? passwordResetPage : passwordNotResetPage;

	return (
		<div className="page">
			<TopNav isLoggedIn={!!props.userStore.currentUser} />
			{resetPassword}
		</div>
	);
}));

export default ResetPassword;