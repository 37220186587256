import { makeAutoObservable, observable } from 'mobx';
import agent from '../agent';

class EventsStore {

  events = observable(new Map());

  eventDetail = {
    id: '',
    title: '',
    createTime: '',
    content: '',
    eventTime: '',
    eventType: '',
    address: '',
    zipcode: '',
    author: '',
    timezone: '',
    meetingLink: ''
  };

  draftEvent = {
    id: '',
    title: '',
    content: '',
    eventTime: '',
    eventType: '',
    address: '',
    zipcode: '',
    timezone: '',
    meetingLink: ''
  };

  pagesCount = 0;

  constructor() {
    makeAutoObservable(this);
  }

  setSearchFilterZipcode(zipcode) {
    this.searhEventFilter.zipcode = zipcode;
  }

  setSearchFilterTimeRange(timeRange) {
    this.searhEventFilter.timeRange = timeRange;
  }

  setSearchFilterEventType(eventType) {
    this.searhEventFilter.eventType = eventType;
  }

  setSearchFilterDistance(distance) {
    this.searhEventFilter.distance = distance;
  }

  getEvents(id, params) {
    return agent.Events.getEvents(id, params)
      .then((response) => {
        this.events.clear();
        response.events.forEach((value, key) => {
          this.events.set(value.id, value);
        });
        this.pagesCount = response.page_count;
      });
  }

  getEventDetail(eventId) {
    return agent.Events.getEventDetail(eventId)
      .then((response) => {
        this.eventDetail = response;
      });
  }

  postEvent(eventId) {
    return agent.Events.postEvent(eventId, this.draftEvent)
      .then((response) => {
        this.eventDetail = response;
      });
  }

  postEventLike(eventId) {
    return agent.Events.postEventLike(eventId)
      .then((response) => {
        const eventId = response.eventId;
        if (this.events.has(eventId)) {
          const event = this.events.get(eventId);
          event.likes_count = response.likes;
          this.events.set(eventId, event);
        }
        if (this.eventDetail.id === response.eventId) {
          this.eventDetail.likes_count = response.likes;
        }
      });
  }

  joinEvent(eventId) {
    return agent.Events.joinEvent(eventId)
      .then((response) => {
        if (this.eventDetail.id === response.eventId) {
          this.eventDetail.attendees_count = response.attendees_count;
        }
      });
  }

  abandonEvent(eventId, isAbandoned) {
    return agent.Events.abandonEvent(eventId, isAbandoned)
      .then((response) => {
        this.eventDetail = response;
      });
  }

  reportEvent(eventId, reportContent) {
    return agent.Report.reportProblem(`event_${eventId}`, reportContent);
  }

  setPostEventId(id) {
    this.draftEvent.id = id;
  }

  setPostEventTitle(title) {
    this.draftEvent.title = title;
  }

  setPostEventTime(eventTime) {
    this.draftEvent.eventTime = eventTime;
  }

  setPostEventContent(content) {
    this.draftEvent.content = content;
  }

  setPostEventType(eventType) {
    this.draftEvent.eventType = eventType;
  }

  setPostEventAddress(address) {
    this.draftEvent.address = address;
  }

  setPostEventZipcode(zipcode) {
    this.draftEvent.zipcode = zipcode;
  }

  setPostEventTimezone(timezone) {
    this.draftEvent.timezone = timezone;
  }

  setPostEventMeetingLink(meetingLink) {
    this.draftEvent.meetingLink = meetingLink;
  }

  clearDraftEvent() {
    this.draftEvent.id = "";
    this.draftEvent.title = "";
    this.draftEvent.eventTime = "";
    this.draftEvent.content = "";
    this.draftEvent.eventType = "";
    this.draftEvent.address = "";
    this.draftEvent.zipcode = "";
    this.draftEvent.timezone = "";
    this.draftEvent.meetingLink = "";
  }

  assignToDraftEvent(event) {
    this.draftEvent.id = event.id;
    this.draftEvent.title = event.title;
    this.draftEvent.eventTime = event.eventTime;
    this.draftEvent.content = event.content;
    this.draftEvent.eventType = event.eventType;
    this.draftEvent.address = event.address;
    this.draftEvent.zipcode = event.zipcode;
    this.draftEvent.timezone = event.timezone;
    this.draftEvent.meetingLink = event.meetingLink;
  }
}

export default new EventsStore();
