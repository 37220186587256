import React from 'react';
import { API_ROOT } from "../shared/Constant";


const TermsPage = props => {
    return (
        <div>
            <embed src={`${API_ROOT}/static/documents/terms_service.pdf`} type="application/pdf" width="100%" height="600px" />
        </div>
    );
};

export default TermsPage;