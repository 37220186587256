import React, { useState, useEffect } from "react";
import OptionGroup from "./OptionGroup";
import { observer } from "mobx-react";
import agent from '../agent';
import { parseJson } from "./SafeJsonParser";

import style from "./VoteComponent.css";

const VoteComponent = observer((props) => {
    const [vote, setVote] = useState(null);
    const [option, setOption] = useState([]);
    const [description, setDescription] = useState("");
    const [voteResult, setVoteResult] = useState([]);
    const [enableCheck, setEnableCheck] = useState(false);
    const [resultView, setResultView] = useState(false);
    const [voteSubmitted, setVoteSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        let cancel = false;
        agent.Vote.getVote(props.type).then((response) => {
            if (cancel) return;
            if (response.message === "") {
                setVote(null);
            }
            else {
                setVote(response.message);
            }
        }).catch((error) => {
            setErrorMessage("Failed to get vote. Please try again");
        });
        return () => {
            cancel = true;
        }
    }, []);

    const handleSelectChange = (option) => {
        setErrorMessage("");
        setOption(option);
    };

    const changeDescription = e => {
        setErrorMessage("");
        setDescription(e.target.value);
    }

    const submitVote = e => {
        setErrorMessage("");
        if (option.length === 0) {
            return;
        }

        agent.Vote.postVote(vote.id, option[0], description).then((response) => {
            setDescription("");
            setEnableCheck(true);
            setVoteSubmitted(true);
        }).catch((error) => {
            setErrorMessage("Failed to submit vote. Please try again");
        })
    }

    const checkVote = e => {
        setErrorMessage("");
        if (resultView) {
            setResultView(false);
            return;
        }
        agent.Vote.getVoteResult(vote.id, option, description).then((response) => {
            setVoteResult(response.result);
            setResultView(true);
        }).catch(() => {
            setErrorMessage("Failed to check vote. Please try again");
        })
    }

    const VoteResultComp = observer((props) => {
        const options = parseJson(vote.options);
        return options && options.map((option, index) => {
            return <div>
                {props.voteResult[index] ? props.voteResult[index] : 0}: {option}
            </div>
        });
    });

    const checkLabel = resultView ? "Return" : "Result";
    const submitLabel = voteSubmitted ? "Voted" : "Submit";

    return (
        <div className="vote">
            {vote && <>
                <h2>{vote.title}</h2>
                {!resultView &&
                    <>
                        <OptionGroup
                            options={parseJson(vote.options)}
                            onChange={handleSelectChange}
                            containerStyle="checbox-group"
                            type={"radio"}
                            value={[option]}
                        />
                        <input
                            id="reportOthers"
                            className="vote-description"
                            type="text"
                            placeholder="Let us know the reason you select this option"
                            onChange={changeDescription}
                        />
                    </>}
                <div style={{ "margin": "10px" }}>
                    {resultView && <VoteResultComp voteResult={voteResult} />}
                </div>
                <div className="flex-box-row">
                    <button className="vote-button" onClick={submitVote} disabled={option.length === 0 || voteSubmitted}>{submitLabel}</button>
                    <button className="vote-button" onClick={checkVote} disabled={!enableCheck}>{checkLabel}</button>
                </div>
                <div>{errorMessage}</div>
            </>}
            {!vote && welcomeWords}
        </div>
    );
});

const welcomeWords = (<div>
    Welcome to our newly published website! We're excited to have you here, but please bear with us as we work out any kinks that may arise during this early stage. As a new site, there may be some instability or inconvenience, but we're committed to making your experience as smooth as possible. We appreciate your patience and understanding as we continue to improve and refine our offerings. In the meantime, feel free to explore what we have to offer and let us know if you have any questions or feedback. Thank you for visiting our site!
</div>);

export default VoteComponent;
