import React, { useState, useEffect } from 'react';
import TopNav from '../LandingPage/TopNav/TopNav';
import { Link } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import { validateEmail } from "../shared/ValidateEmail";

import styles from "./Signup.css";


const Signup = inject("authStore", "userStore")(observer((props) => {
	const [password, setPassword] = useState("");
	const [email, setEmail] = useState("");
	const [error, setError] = useState("");
	const [secondPassword, setSecondPasswordInput] = useState("");
	const [termCheck, setTermCheck] = useState(false);
	const [code, setCode] = useState("");
	const [countdown, setCountdown] = useState(100);
	const [isCounting, setIsCounting] = useState(false);
	const [disabled, setDisabled] = useState(false);

	const startCountdown = () => {
		setIsCounting(true);

		setDisabled(true);
		const countdownTimer = setInterval(() => {
			setCountdown((prevCountdown) => {
				if (prevCountdown > 0) {
					return prevCountdown - 1;
				} else {
					setIsCounting(false);
					setDisabled(false);
					clearInterval(countdownTimer);
					return prevCountdown;
				}
			});
		}, 1000);
	};

	const handleEmailChange = e => {
		setError("");
		setEmail(e.target.value);
	}

	const handlePasswordChange = e => {
		setError("");
		setPassword(e.target.value);
	}

	const handleVerifyPasswordChange = e => {
		setSecondPasswordInput(e.target.value);
	}

	const handleCodeChange = e => {
		setCode(e.target.value);
	}

	const sendCode = e => {
		startCountdown();
		props.authStore.validateEmail(email).then(() => {
		})
	}

	const handleSubmitForm = e => {
		e.preventDefault();
		setError("");

		if (props.authStore.email === "") {
			setError("Email is empty");
			return;
		}

		if (validateEmail(email)) {
			setError("Please input a valid email");
			return;
		}

		if (password === "") {
			setError("Password is empty");
			return;
		}

		if (password !== secondPassword) {
			setError("Input password don't match");
			return;
		}

		if (!termCheck) {
			setError("Agree terms of use and privacy policy to continue.");
			return;
		}
		props.authStore.register(email, password, code).then(() => {
			if (props.authStore.errorMessage === "") {
				props.history.replace("/");
			} else {
				setError(props.authStore.errorMessage);
			}
		});
	};

	const errorElem = error !== "" ? <div className='error-message'>{error}</div> : null;

	return (
		<div className="page">
			<TopNav isLoggedIn={!!props.userStore.currentUser} />
			<div className="row">
				<div className="signup-form">
					<div className="signup-text">Sign Up</div>
					<input
						className="signup-input"
						type="email"
						placeholder="Email"
						onChange={handleEmailChange}
					/>
					<div className='row-container'>
						<input
							className="validation-input"
							placeholder="Code"
							onChange={handleCodeChange}
						/>
						<button
							className="validation-button"
							type="submit"
							onClick={sendCode}
							disabled={disabled}
						>
							{isCounting ? `Send code in ${countdown}s` : 'Send code'}
						</button>
					</div>
					<input
						className="signup-input"
						type="password"
						placeholder="Password"
						onChange={handlePasswordChange}
					/>
					<input
						className="signup-input"
						type="password"
						placeholder="Verify password"
						onChange={handleVerifyPasswordChange}
					/>
					<label>
						<input
							style={{ "marginRight": "10px" }}
							type="checkbox"
							checked={termCheck}
							onChange={() => setTermCheck(!termCheck)}
						/>
						Agree <Link to="terms">terms of use</Link> and <Link to="privacy">privacy policy</Link>
					</label>
					<button
						className="signup-button"
						type="submit"
						onClick={handleSubmitForm}
					>
						Sign up
					</button>
					<p className="reset-link" >
						<Link to="login">Have an account?</Link>
					</p>
					{errorElem}
				</div>
			</div>
		</div>
	);
}));

export default Signup;