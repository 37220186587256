import React, { useEffect, useState } from "react";
import TopNav from '../LandingPage/TopNav/TopNav';
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Spinner } from '../Spinner/Spinner'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from 'react-paginate';
import AddressComponent from "../shared/AddressComponent";
import profile2 from '../assets/profile2.jpg';
import profile3 from '../assets/profile3.jpg';
import ImageSequence from "../shared/ImageSequence";
import { hiddenEmail } from "../shared/hidenEmail";
import FloatButton from "../shared/FloatButton";
import { parseJson } from "../shared/SafeJsonParser";
import LoadingPage from "../shared/LoadingPage";
import ReCAPTCHA from "react-google-recaptcha";

import styles from "./Events.css";


const EventsComponent = inject("eventsStore", "userStore")(observer((props) => {
	const [isLoading, setIsLoading] = useState(true);
	const pathnames = props.location.pathname.split("/");
	const [pageOffset, setPageOffset] = useState(Number(pathnames[pathnames.length - 1]));
	const [searchParams, setSearchParams] = useState(props.location.search);
	const [error, setError] = useState("");
	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight
	});
	const [showPopupPostEvent, setShowPopupPostEvent] = useState(false);

	useEffect(() => {
		if (pageOffset < 0) {
			return;
		}
		let cancel = false;
		if (!props.userStore.currentUser) {
			props.userStore.getLoginStatus();
		}
		props.eventsStore.getEvents(pageOffset, searchParams).then(() => {
			setIsLoading(false);
			if (cancel) return;
			props.eventsStore.clearDraftEvent();
			props.history.push(`/events/${pageOffset}${searchParams}`);
		}).catch((error) => {
			setIsLoading(false);
			setError(error);
		});
		const handleResize = () => {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight
			});
		};
		window.addEventListener('resize', handleResize);
		return () => {
			cancel = true;
			window.removeEventListener('resize', handleResize);
		};
	}, [pageOffset, searchParams]);

	const handlePageChange = (event) => {
		setPageOffset(event.selected);
	};

	const handleSearch = (params) => {
		setSearchParams(params);
	}

	const handleLikes = id => {
		props.eventsStore.postEventLike(id);
	}

	const handleFloatButtonClick = e => {
		setShowPopupPostEvent(!showPopupPostEvent);
	}

	const EventList = observer((props) => {
		const array = Array.from(props.events, ([key, value]) => ({ key, value }));

		return array.map(({ key, value }, index) => (
			<div key={value.id} className="event-container">
				<Link to={`/event/${value.shortUrl}`}>{value.title}</Link>
				<div className="event-body" dangerouslySetInnerHTML={{ __html: value.content }}></div>
				<div className="events-info">
					<div className="flex-box-row ">
						<div style={{ "marginRight": "8px" }} className="large-view">Posted by: {hiddenEmail(value.author)}</div>
						<div className="large-view">Update: {value.creatTime?.split(".")[0]}</div>
					</div>
					<div className="flex-box-row ">
						<div style={{ "marginRight": "6px" }} className="event-icon">
							<span style={{ "color": "#bd1f1f" }}><i className="fas fa-user-alt"></i></span>
						</div>
						<div>{value.attendees_count}</div>
						<div style={{ "marginRight": "4px" }} className="event-icon" onClick={() => handleLikes(value.id)}>
							<span style={{ "color": "#bd1f1f" }}><i className="fa fa-heart"></i></span>
						</div>
						<div>{value.likes_count}</div>
					</div>
				</div>
			</div>
		));
	});

	const images = [
		profile2,
		profile3,
		// Add more image URLs as needed
	];
	const IntroductionOfEvent = props => {
		return (
			<div className='introduction-event-container'>
				<ImageSequence className="introduction-image" images={images} interval={4000} />
				<div style={{ "width": "320px" }}>To post event, <Link to="/login">login</Link> and get your <Link to="/personal-profile">business profile</Link> verified</div>
			</div>
		);
	}

	return (
		<div className="page ">
			<TopNav isLoggedIn={!!props.userStore.currentUser} />
			<div className="events-container">
				<div className="event-list-container">
					<SearchEvents searchParams={searchParams} onSearchSubmit={handleSearch} />
					{!isLoading && <EventList events={props.eventsStore.events} />}
					{isLoading && <LoadingPage />}
					{error && <div>Loading event error</div>}
					<ReactPaginate
						breakLabel="..."
						nextLabel="next >"
						onPageChange={handlePageChange}
						pageRangeDisplayed={5}
						pageCount={Number(props.eventsStore.pagesCount)}
						previousLabel="< previous"
						renderOnZeroPageCount={null}
						containerClassName="react-paginate"
						forcePage={pageOffset}
						activeClassName="react-paginate-active"
						hrefAllControls
						pageLinkClassName="react-paginate-item"
					/>
				</div>
				{!!props.userStore.currentUser && (showPopupPostEvent || windowSize.width > 800)
					&& <PostEventsComponent onClose={handleFloatButtonClick} smallView={windowSize.width <= 800} />}
				{!props.userStore.currentUser && <IntroductionOfEvent />}
				{!!props.userStore.currentUser && <FloatButton onClick={handleFloatButtonClick} className="post-float-button" />}
			</div>
		</div>
	);
}));

const SearchEvents = inject("eventsStore")(observer((props) => {
	const params = new URLSearchParams(props.searchParams);
	const [zipcode, setZipCode] = useState("");
	const [distance, setDistance] = useState("0");
	const [eventType, setEventType] = useState("0");
	const [timeRange, setTimeRange] = useState("0");

	useEffect(() => {
		if (params.has("zipcode")) {
			const data = params.get("zipcode").toString();
			setZipCode(data);
		}

		if (params.has("distance")) {
			const data = params.get("distance").toString();
			setDistance(data)
		}

		if (params.has("eventType")) {
			const data = params.get("eventType").toString();
			setEventType(data);
		}

		if (params.has("timeRange")) {
			const data = params.get("timeRange").toString();
			setTimeRange(data);
		}
	}, []);

	const handleZipcodeChange = e => {
		setZipCode(e.target.value);
	};

	const handleDistanceChange = e => {
		setDistance(e.target.value);
	};

	const handleTimeRangeChange = e => {
		setTimeRange(e.target.value);
	};

	const handleEventTypeChange = e => {
		setEventType(e.target.value);
	};

	const seachEvents = e => {
		const paramsString = `?zipcode=${zipcode}&distance=${distance}&eventType=${eventType}&timeRange=${timeRange}`;
		props.onSearchSubmit(paramsString);
	}

	const distanceDropDown = (
		<select id="distance" className="events-select" onChange={handleDistanceChange} value={distance}>
			<option value="0">Any distance</option>
			<option value="5">5 miles</option>
			<option value="10">10 miles</option>
			<option value="15">15 miles</option>
			<option value="25">25 miles</option>
		</select>
	);

	const typeDropDown = (
		<select id="event-type" className="events-select" onChange={handleEventTypeChange} value={eventType}>
			<option value="0">Any type</option>
			<option value="1">In person</option>
			<option value="2">Online</option>
		</select>
	);

	const daysDropDown = (
		<select id="time-range" className="events-select" onChange={handleTimeRangeChange} value={timeRange}>
			<option value="0">Any day</option>
			<option value="1">Next 3 days</option>
			<option value="2">This week</option>
			<option value="3">This month</option>
			<option value="10">Last 3 days</option>
			<option value="20">Last week</option>
			<option value="30">Last month</option>
		</select>
	);

	return (
		<div>
			<div className="search-event-container">
				<div className="flex-box-row">
					{distanceDropDown}
					{typeDropDown}
					{daysDropDown}
				</div>
				<div className="flex-box-row">
					<p className="search-event-text">Events near </p>
					<input
						className="search-event-input"
						type="text"
						placeholder="zipcode"
						onChange={handleZipcodeChange}
						value={zipcode}
					/>
					<div className={"event-search-button"} onClick={seachEvents}>
						<span className={"event-search-icon"}><i className="fas fa-search"></i></span>
					</div>
				</div>
			</div>
		</div>);
}));


const PostEventsComponent = inject("eventsStore", "userStore")(observer((props) => {
	const [typeOfEvent, setTypeOfEvent] = useState("1");
	const [posting, setPosting] = useState(false);
	const [postDone, setPostDone] = useState(false);
	const [dateTime, handleDateTimeChange] = useState(new Date());
	const [meetingLink, setMeetingLink] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [address, setAddress] = useState("");
	const [zipcode, setZipcode] = useState("");
	const [enablePostEvent, setEnablePostEvent] = useState(false);
	const [isVerified, setIsVerified] = useState(false);

	const handleTypeOfEventChange = e => {
		setTypeOfEvent(e.target.value);
		setErrorMessage("");
		setEnablePostEvent(true);
	};

	const handleTitleChange = e => {
		props.eventsStore.setPostEventTitle(e.target.value);
		setErrorMessage("");
		setEnablePostEvent(true);
	};

	const handleContentChange = e => {
		setErrorMessage("");
		props.eventsStore.setPostEventContent(e.target.value);
		setEnablePostEvent(true);
	};

	const handleMeetingLinkChange = e => {
		setErrorMessage("");
		setMeetingLink(e.target.value);
		setEnablePostEvent(true);
	}

	const postEvent = e => {
		if (props.eventsStore.draftEvent.title === "") {
			setErrorMessage("Title can't be empty");
			return;
		}

		e.preventDefault();
		setPosting(true);
		setEnablePostEvent(false);
		props.eventsStore.setPostEventTime(dateTime.getTime());
		props.eventsStore.setPostEventTimezone(dateTime.getTimezoneOffset());
		props.eventsStore.setPostEventType(typeOfEvent);
		props.eventsStore.setPostEventMeetingLink(meetingLink);
		props.eventsStore.setPostEventAddress(address);
		props.eventsStore.setPostEventZipcode(zipcode);
		props.eventsStore.postEvent("create").then((event) => {
			setPostDone(true);
			setPosting(false);
			props.eventsStore.clearDraftEvent();
		}).catch((error) => {
			setPosting(false);
			setEnablePostEvent(true);
			if (error.status === 401) {
				setErrorMessage("Login to post event");
			} else {
				setErrorMessage("Unknow error. Please try again.");
				if (error.response?.text) {
					const errorText = parseJson(error.response?.text);
					if (errorText.message) {
						setErrorMessage(errorText.message);
					}
				} else {
					setErrorMessage("Poset event error");
				}
			}
		});
	};

	const handleAddressChange = address => {
		setAddress(address);
		setEnablePostEvent(true);
	};

	const handleZipcodeChange = zipcode => {
		setZipcode(zipcode);
		setEnablePostEvent(true);
	};

	const postAnotherEvent = e => {
		e.preventDefault();
		setPostDone(false);
		setEnablePostEvent(true);
	};

	const handleCaptchaChange = (value) => {
		setIsVerified(!!value);
	};

	const typeOfEventComp = (
		<>
			<div className="event-type">
				<input
					type="radio"
					id="inperson"
					className="event-type-radio"
					name="eventtype"
					value="1"
					onChange={handleTypeOfEventChange}
					defaultChecked
				/>
				<label className="event-type-label">In person</label>
				<input
					type="radio"
					id="online"
					className="event-type-radio"
					name="eventtype"
					value="2"
					onChange={handleTypeOfEventChange}
				/>
				<label className="event-type-label">Online</label>
			</div>
			{typeOfEvent === "2" &&
				<input
					className="post-event-input"
					type="text"
					placeholder={"Online event meeting link"}
					value={meetingLink}
					onChange={handleMeetingLinkChange}
				/>
			}
			{typeOfEvent === "1" && <AddressComponent
				address={""}
				zipcode={""}
				handleAddressChange={handleAddressChange}
				handleZipcodeChange={handleZipcodeChange} />
			}
		</>
	);

	const postDoneComponent = (
		<>
			{props.smallView && <span className="post-close-icon" onClick={props.onClose}>
				<i className="fa fa-times"></i>
			</span>}
			<Link style={{ "margin": "16px" }} to={`/event/${props.eventsStore.eventDetail.shortUrl}`}>View your post </Link>
			<button
				className="post-event-button"
				type="submit"
				onClick={postAnotherEvent}
			>
				Post another event
			</button>
		</>
	);

	return (
		<div className="post-event-container">
			{!postDone && (
				<>
					{props.smallView && <span className="post-close-icon" onClick={props.onClose}>
						<i className="fa fa-times"></i>
					</span>}
					<input
						className="post-event-input"
						type="text"
						placeholder="Title"
						onChange={handleTitleChange}
					/>
					<textarea
						className="event-content"
						type="text"
						placeholder="description"
						onChange={handleContentChange}
					/>
					{typeOfEventComp}
					<div className="date-time-picker-container">
						<div className="date-time-label">Event date: </div>
						<DatePicker
							showIcon
							showTimeSelect
							selected={dateTime}
							onChange={handleDateTimeChange}
							dateFormat="Pp"
							className="date-time-picker"
						/>
					</div>
					<ReCAPTCHA
						style={{ "paddingTop": "20px" }}
						sitekey="6LdVU5YmAAAAAL-ybQCUKMeNbpt9R9LAcepevIJL"
						onChange={handleCaptchaChange}
					/>
					<button className="post-event-button" type="submit" onClick={postEvent} disabled={!enablePostEvent || !isVerified}>
						{!posting && <div>Post Event</div>}
						{posting && <Spinner className="post-event-spinner" />}
					</button>
					<div className="error-message">{errorMessage}</div>
				</>
			)}
			{postDone && postDoneComponent}
		</div>
	);
}));


export default EventsComponent;