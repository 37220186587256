import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { hiddenEmail } from "../shared/hidenEmail";
import TopNav from '../LandingPage/TopNav/TopNav';
import { Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import LoadingPage from "../shared/LoadingPage";
import StatesSelect from "../shared/StatesSelect"
import { timeAgo } from "../shared/TimeConverter";

import styles from "./QuestionListView.css";


const QuestionListView = inject("questionStore", "userStore")(observer((props) => {
    const [isLoading, setIsLoading] = useState(true);
    const pathnames = props.location.pathname.split("/");
    const [pageOffset, setPageOffset] = useState(Number(pathnames[pathnames.length - 1]));
    const [searchParams, setSearchParams] = useState(props.location.search);
    const [error, setError] = useState("");
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        if (pageOffset < 0) {
            return;
        }
        if (!props.userStore.currentUser) {
            props.userStore.getLoginStatus();
        }
        props.questionStore.getQuestions(pageOffset, searchParams).then(() => {
            setIsLoading(false);
            props.history.push(`/questions/${pageOffset}${searchParams}`);
        }).catch((error) => {
            setIsLoading(false);
            setError(error);
        });
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [pageOffset, searchParams]);

    const handlePageChange = (event) => {
        setPageOffset(event.selected);
    };

    const handleSearch = (params) => {
        setSearchParams(params);
    }

    const askQuestionHandler = id => {
        props.history.push("/create_question")
    }


    const QuestionList = observer((props) => {
        return props.questions.map((value, index) => (
            <div key={value.id} className="event-container">
                <Link to={`/question/${value.shortUrl}`} style={{ "fontSize": "30px" }}>{value.title}</Link>
                <div className="event-body" dangerouslySetInnerHTML={{ __html: value.content }}></div>
                <div className="row-container">
                    <div className="flex-box-row">
                        <div style={{ "marginRight": "8px" }} className="large-view">{hiddenEmail(value.authorEmail)}</div>
                        <div className="large-view">posted {timeAgo(value.creatTime)}</div>
                    </div>
                    <div className="flex-box-row">
                        {value.state && <div style={{ "marginLeft": "20px" }}>State: {value.state}</div>}
                        <div style={{ "marginLeft": "20px" }}>Vote: {value.vote}</div>
                        <div style={{ "marginLeft": "20px" }}>Answers: {value.answerCount}</div>
                    </div>
                </div>
            </div>
        ));
    });

    return (
        <div className="page ">
            <TopNav isLoggedIn={!!props.userStore.currentUser} />
            <div className="questions-container">
                <div className="row-container " style={{ "marginLeft": "20px" }}>
                    <SearchEvents searchParams={searchParams} onSearchSubmit={handleSearch} />
                    <button onClick={askQuestionHandler} className="ask-question-button">Ask Question</button>
                </div>
                {!isLoading && <QuestionList questions={props.questionStore.questions} />}
                {isLoading && <LoadingPage />}
                {error && <div>Loading event error</div>}
                <ReactPaginate
                    breakLabel="..."
                    nextLabel="next >"
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={5}
                    pageCount={props.questionStore.pageCount} //TODO
                    previousLabel="< previous"
                    renderOnZeroPageCount={null}
                    containerClassName="react-paginate"
                    forcePage={pageOffset}
                    activeClassName="react-paginate-active"
                    hrefAllControls
                    pageLinkClassName="react-paginate-item"
                />
            </div>
        </div>
    );
}));


const SearchEvents = inject("eventsStore")(observer((props) => {
    const params = new URLSearchParams(props.searchParams);
    const [search, setSearch] = useState("");
    const [state, setState] = useState("");

    useEffect(() => {
        if (params.has("search")) {
            const data = params.get("search").toString();
            setSearch(data);
        }

        if (params.has("state")) {
            const data = params.get("state").toString();
            setState(data)
        }
    }, []);

    const handleSearchChange = e => {
        setSearch(e.target.value);
    };

    const handleStateChange = e => {
        setState(e.target.value);
        const paramsString = `?search=${search}&state=${e.target.value}`;
        if (props.onSearchSubmit) {
            props.onSearchSubmit(paramsString);
        }
    };


    const seachEvents = e => {
        const paramsString = `?search=${search}&state=${state}`;
        if (props.onSearchSubmit) {
            props.onSearchSubmit(paramsString);
        }
    }

    return (
        <div className="row-container">
            <StatesSelect
                value={state}
                onChange={handleStateChange}
            />
            <div className="flex-box-row" style={{ "marginLeft": "20px" }}>
                <input
                    className="search-input"
                    type="text"
                    placeholder="Search"
                    onChange={handleSearchChange}
                    value={search}
                />
                <div className={"event-search-button"} onClick={seachEvents}>
                    <span className={"event-search-icon"}><i className="fas fa-search"></i></span>
                </div>
            </div>
        </div>);
}));


export default QuestionListView;