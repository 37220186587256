import React, { useState } from 'react';
import { inject, observer } from "mobx-react";
import { hiddenEmail } from "../shared/hidenEmail";
import NotificationBar from "../shared/NotificationBar";
import { handleError } from '../shared/ErrorHandler';

import styles from "./ProfileLink.css";


const ProfileLink = inject("profileStore")(observer((props) => {
	const [showNotification, setShowNotification] = useState(false);

	const getProfileView = e => {
		setShowNotification(false);
		props.profileStore.getProfileByUserId(props.userId).then((response) => {
			if (response && response.shortUrl) {
				const redirectLink = `/profile/${response.shortUrl}`;
				if (props.history) {
					props.history.push(redirectLink);
				} else {
					window.location.replace(redirectLink);
				}
			} else {
				setShowNotification(true);
			}
		}).catch((error) => {
			setShowNotification(true);
			handleError(error, props.history);
		});
	}

	const author = hiddenEmail(props.author);
	const message = `${author} don't have a profile`
	return <div>
		<div onClick={getProfileView} className="link-div">{author}</div>
		{showNotification && <NotificationBar message={message} />}
	</div>;

}));



export default ProfileLink;