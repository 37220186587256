import React, { useEffect, useState } from "react";
import TopNav from '../LandingPage/TopNav/TopNav';
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from 'react-paginate';
import RatingComponent from "../shared/RatingComponent";
import { profileOptions } from "../shared/ProfileOptions";
import defautPhoto from '../assets/default-photo.jpg';
import Badge from "../shared/Badge";
import VoteComponent from "../shared/VoteComponent";
import { API_ROOT } from "../shared/Constant";
import LoadingPage from "../shared/LoadingPage";

import style from "./ProfileList.css";


const ProfilesComponent = inject("profileStore", "userStore")(observer((props) => {
	const pathnames = props.location.pathname.split("/");
	const [pageOffset, setPageOffset] = useState(Number(pathnames[pathnames.length - 1]));
	const [searchParams, setSearchParams] = useState(props.location.search);
	const [error, setError] = useState("");
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (pageOffset < 0) {
			return;
		}
		if (!props.userStore.currentUser) {
			props.userStore.getLoginStatus();
		}
		props.profileStore.getProfiles(pageOffset, searchParams).then(() => {
			props.history.push(`/profiles/${pageOffset}${searchParams}`);
			setIsLoading(false);
		}).catch((error) => {
			setIsLoading(false);
			setError(error);
		});
	}, [pageOffset, searchParams]);

	const handlePageChange = (profile) => {
		setPageOffset(profile.selected);
	};

	const handleSearch = (params) => {
		setSearchParams(params);
	}

	const ProfileList = observer((props) => {
		const profileArray = [];
		if (props.profiles) {
			props.profiles.forEach((profile) => {
				profileArray.push(profile);
			});
		}
		profileArray.sort((a, b) => { return a.id < b.id });

		return profileArray.map((profile) => (
			<div key={profile.id} className="profile-container">
				<div className="flex-box-row">
					<img alt="profile" className="profile-image" src={!!profile.photo && profile.photo !== "" ? `${API_ROOT}${profile.photo}` : defautPhoto} />
					<div className="profile-items-container-left">
						<div>{profile.business}</div>
						<Link to={`/profile/${profile.shortUrl}`}>{profile.name}</Link>
						<div>{profile.phone}</div>
						<div>{profile.email}</div>
						<RatingComponent isEditable={false} value={Number(profile.rating)} />
					</div>
					<div className="profile-items-container-right" >
						<div className="flex-box-row">
							{Number(profile.status) > 1 && <Badge text={"Verified"} />}
							{profile.flatRate && <Badge text={"Flat Rate"} />}
						</div>
						<div>Company: {profile.company}</div>
						<div className="limit-length-div">Skills: {profile.skills}</div>
						<div className="limit-length-div">languages: {profile.languages}</div>
						<div className="limit-length-div">Areas served: {profile.servingAreas}</div>
					</div>
				</div>
			</div>
		));
	});

	return (
		<div className="page ">
			<TopNav isLoggedIn={!!props.userStore.currentUser} />
			{!isLoading && <div className="profiles-container">
				<div className="profiles-container-left">
					<SearchProfiles searchParams={searchParams} onSearchSubmit={handleSearch} />
					<ProfileList profiles={props.profileStore.profiles} />
					{error && <div>Loading profiles error</div>}
					<ReactPaginate
						breakLabel="..."
						nextLabel="next >"
						onPageChange={handlePageChange}
						pageRangeDisplayed={5}
						pageCount={Number(props.profileStore.pagesCount)}
						previousLabel="< previous"
						renderOnZeroPageCount={null}
						containerClassName="react-paginate"
						forcePage={pageOffset}
						activeClassName="react-paginate-active"
						hrefAllControls
						pageLinkClassName="react-paginate-item"
					/>
				</div>
				<VoteComponent type={2} />
			</div>}
			{isLoading && <LoadingPage />}
		</div>
	);
}));

const SearchProfiles = inject("profileStore")(observer((props) => {
	const params = new URLSearchParams(props.searchParams);
	const [zipcode, setZipCode] =
		useState(params && params.has("zipcode") ? params.get("zipcode").toString() : "");
	const [profileType, setProfileType] =
		useState(params && params.has("profileType") ? params.get("profileType").toString() : "0");

	const handleZipcodeChange = e => {
		setZipCode(e.target.value);
	};

	const handleProfileTypeChange = e => {
		setProfileType(e.target.value);
	};

	const seachProfiles = e => {
		const paramsString = `?zipcode=${zipcode}&profileType=${profileType}`;
		props.onSearchSubmit(paramsString);
	}

	const profiles = profileOptions.map((option, index) => {
		return <option key={index} value={option}>{option}</option>
	});

	const typeDropDown = (
		<select id="profile-type" className="profile-select" onChange={handleProfileTypeChange} value={profileType}>
			<option value="Any">Any type</option>
			{profiles}
		</select>
	);

	return (
		<div className="profile-search-container">
			{typeDropDown}
			<p style={{ "alignSelf": "center" }}>near </p>
			<input
				className="profile-search-input"
				type="text"
				placeholder="zipcode"
				onChange={handleZipcodeChange}
				value={zipcode}
			/>
			<div className={"profile-search-button"} onClick={seachProfiles}>
				<span className={"profile-search-icon"}><i className="fas fa-search"></i></span>
			</div>
		</div>
	);
}));

export default ProfilesComponent;