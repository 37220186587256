import React, { useState, useEffect } from "react";
import TopNav from '../LandingPage/TopNav/TopNav';
import { inject, observer } from "mobx-react";
import MultiStepForm from "../shared/MultiStepForm";

import styles from "./PurchaseFormView.css";


const PurchaseFormView = inject("authStore", "userStore")(observer((props) => {
    const [isLoggedIn, setIsLoggedIn] = useState(!!props.userStore.currentUser);

    useEffect(() => {
        props.userStore.getLoginStatus().then(() => {
            setIsLoggedIn(!!props.userStore.currentUser);
        });
    }, []);

    return (
        <div className="page">
            <TopNav isLoggedIn={isLoggedIn} />
            <div className="offer-container">
                <MultiStepForm history={props.history} />
            </div>
        </div>
    );
}));

export default PurchaseFormView;