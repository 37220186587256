export function getLabelByValue(value) {
    const item = loanVerification.find(item => item.value === value);
    return item ? item.label : value;
}

export const loanVerification = [
    { value: 'preQualification', label: "Loan pre-qualification letter" },
    { value: 'preApproval', label: "Loan pre-Approval letter" },
    { value: 'underwrittern', label: "Loan pre-approval underwrittern letter" },
];

export function getOfferRequestStatusText(status) {
    if (status === 0) {
        return "Submitted";
    } else if (status === 1) {
        return "Picked by agent";
    } else if (status === 2) {
        return "Completed";
    } else if (status === -1) {
        return "Draft";
    }
    return "Unknown";
}