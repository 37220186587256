import React, { useState, useEffect } from 'react';
import defautPhoto from '../assets/default-photo.jpg';
import { API_ROOT, MAX_FILE_SIZE } from "../shared/Constant";

import styles from "./PhotoComponent.css";

const PhotoComponent = props => {
  const [photoPath, setPhotoPath] = useState(defautPhoto);
  const [isPhotoSelected, setIsPhotoSelected] = useState(false);
  const [file, setFile] = useState(null);
  const [errorMesage, setErrorMessage] = useState("");

  useEffect(() => {
    if (props.photoPath) {
      setPhotoPath(`${API_ROOT}${props.photoPath}`);
    }
  }, [props.photoPath]);


  const handlePhotoChange = e => {
    setErrorMessage("");
    if (e.target.files[0].size > MAX_FILE_SIZE) {
      setErrorMessage("File size exceeds 200KB.");
      return;
    }
    setIsPhotoSelected(true);
    setPhotoPath(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
  }

  const handlePhotoSubmit = e => {
    setErrorMessage("");
    setIsPhotoSelected(false);
    if (props.handlePhotoSubmit) {
      props.handlePhotoSubmit(file);
    }
  }

  const handlePhotoCancel = e => {
    setErrorMessage("");
    setIsPhotoSelected(false);
    if (props.photoPath) {
      setPhotoPath(`${API_ROOT}${props.photoPath}`);
    }
    else {
      setPhotoPath(defautPhoto);
    }
  }

  return (
    <div >
      <label className="flex-box-row" htmlFor="fileInput">
        {props.showPreview && <img id="icon" src={photoPath} alt="defautPhoto" />}
        <div className="add-icon">
          {!isPhotoSelected && <span><i className="fa fa-plus"></i></span>}
        </div>
      </label>
      <input id="fileInput" type="file" accept="image/*" onChange={handlePhotoChange} />
      <div className="submit-icon">
        {isPhotoSelected && <span onClick={handlePhotoSubmit}><i className="fa fa-upload"></i></span>}
        {isPhotoSelected && <span onClick={handlePhotoCancel}> <i className="fa fa-times"></i></span>}
      </div>
      <div className='error-message'>{errorMesage}</div>
    </div>
  );
}


export default PhotoComponent;