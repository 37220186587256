import { makeAutoObservable } from 'mobx';
import agent from '../agent';

export class AnswerStore {
  answers = [];

  constructor() {
    makeAutoObservable(this);
  }

  loadAnswers(parentId) {
    return agent.Answer.getAnswers(parentId)
      .then((response) => { this.answers = response.answers; });
  }

  addAnswer(id, parentId, content) {
    return agent.Answer.addAnswer(id, parentId, content)
      .then(() => this.loadAnswers(parentId));
  }

  deleteAnswer(id, parentId) {
    return agent.Answer.deleteAnswer(id, parentId)
      .then(() => { this.loadAnswers(parentId); });
  }

  updateAnswer(id, parentId, content) {
    return agent.Answer.updateAnswer(id, parentId, content)
      .then(() => { this.loadAnswers(parentId); });
  }

  voteAnswer(id, vote) {
    return agent.Answer.voteAnswer(id, vote);
  }
}
export default new AnswerStore();
