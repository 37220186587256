export const booleanOptions = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' }
];

export const addreessForm = {
    street: '',
    state: '',
    city: '',
    zipcode: '',
    county: '',
    apt: '',
    country: ''
}
export const personForm = {
    businessEntity: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    role: '',
    address: addreessForm,
};

export const purchaseRequest = {
    currentStep: 1,
    property: {
        address: addreessForm,
        type: '',
        state: '',
        zipcode: '',
    },
    buyers: {
        type: '',
        data: [personForm]
    },
    agent: {
        commission: '',
        unit: '',
        type: '',
        remote: '',
        comment: '',
        dayToMakeOffer: '',
    },
    purchase: {
        price: '',
        type: '',
        downPayment: '',
        loanType: '',
        loanFixedRate: '',
        lengthOfLoan: '',
        loanVerification: ''
    },
};

export const purchaseDoc = {
    currentStep: 1,
    property: {
        address: addreessForm,
        type: '',
        parcelNumber: '',
        includeLand: '',
        legalDescription: '',
        builtBefore1978: '',
        ownMineralRight: '',
    },
    buyers: {
        type: '',
        data: [personForm]
    },
    sellers: {
        type: '',
        data: [personForm]
    },
    purchase: {
        price: '',
        priceInWords: '',
        type: '',
        downPayment: '',
        loanAmount: '',
        allCashAmount: '',
        dayOfVerificationFund: '',
        loanType: '',
        loanFixedRate: '',
        loanArmRate: '',
        loanPoint: '',
        lengthOfLoan: '',
        loanApplicationDay: ''
    },
    deposit: {
        amount: '',
        amountInWords: '',
        dueDate: '',
        method: ''
    },
    contigencies: {
        choice: [],
        inspection: {
            removeDate: '',
            negotiationPeriod: ''
        },
        loan: {
            removeDate: ''
        },
        appraisal: {
            removeDate: '',
            negotiationPeriod: ''
        },
        exchange: {
            removeDate: '',
            address: addreessForm
        }
    },
    itemsOnSale: {
        items: []
    },

    questions: {
        survey: {
            reportDays: '',
            correctDays: '',
        },
        title: {
            reviewDays: '',
            correctDays: ''
        },
        disclosure: {
            dueDate: '',
        },
        expiration: {
            dueDate: '',
        }
    },

    closing: {
        closeDays: '',
        sellerVacateDays: '',
        verificationDays: '',
        cost: '',
    },
    addenda: {
        items: ['']
    }
};

export const requestFieldOfPurchaseDoc = {
    property: {
        address: 'addressCheck',
        type: 'stringCheck',
        includeLand: 'stringCheck',
    },
    buyers: 'personsCheck',
    sellers: 'personsCheck',
    purchase: 'purchaseCheck',
    deposit: {
        amount: 'numberCheck',
        amountInWords: 'stringCheck',
        dueDate: 'numberCheck',
        method: 'stringCheck'
    },
    contigencies: 'contigenciesCheck',
    questions: {
        survey: {
            reportDays: 'numberCheck',
            correctDays: 'numberCheck',
        },
        title: {
            reviewDays: 'numberCheck',
            correctDays: 'numberCheck'
        },
        disclosure: {
            dueDate: 'numberCheck',
        }
    },

    closing: {
        closeDays: 'numberCheck',
        sellerVacateDays: 'numberCheck',
        verificationDays: 'numberCheck',
        cost: 'stringCheck',
    },
}

export const requestFieldOfPurchaseRequest = {
    property: {
        address: 'addressCheck',
        type: 'stringCheck',
    },
    buyers: 'personsCheck',
    agent: {
        commission: '',
        type: 'stringCheck',
        remote: 'stringCheck',
        dayToMakeOffer: 'numberCheck',
    },
    purchase: 'purchaseCheckOfRequest',
}

export const validationMethods = {
    purchaseCheck: (value) => {
        const errors = {}

        errors['price'] = addPrefix("Price: ", validationMethods.numberCheck(value.price));
        errors['priceInWords'] = addPrefix("Amount in Words: ", validationMethods.stringCheck(value.priceInWords));
        errors['type'] = validationMethods.stringCheck(value.type);
        errors['dayOfVerificationFund'] = addPrefix("Funding verficaion day: ", validationMethods.numberCheck(value.dayOfVerificationFund));
        if (value.type == "financing") {
            errors['downPayment'] = addPrefix("Down payment: ", validationMethods.numberCheck(value.downPayment));
            errors['loanType'] = addPrefix("Loan type: ", validationMethods.stringCheck(value.loanType));
            errors['loanApplicationDay'] = addPrefix("Loan application day :", validationMethods.numberCheck(value.loanApplicationDay));
            if (value.loanType == "seller") {
                errors['loanFixedRate'] = addPrefix("Loan rate: ", validationMethods.numberCheck(value.loanFixedRate));
                errors['lengthOfLoan'] = addPrefix("Length of loan: ", validationMethods.numberCheck(value.lengthOfLoan));
            }
        }
        const filteredErrors = Object.fromEntries(
            Object.entries(errors).filter(([key, value]) => value !== '')
        );
        return Object.keys(filteredErrors).length === 0 ? "" : filteredErrors;
    },
    purchaseCheckOfRequest: (value) => {
        const errors = {}

        errors['price'] = addPrefix("Price: ", validationMethods.numberCheck(value.price));
        errors['type'] = validationMethods.stringCheck(value.type);
        if (value.type == "financing") {
            errors['downPayment'] = addPrefix("Down payment: ", validationMethods.numberCheck(value.downPayment));
            errors['loanType'] = addPrefix("Loan type: ", validationMethods.stringCheck(value.loanType));
            errors['loanFixedRate'] = addPrefix("Loan rate: ", validationMethods.numberCheck(value.loanFixedRate));
            errors['lengthOfLoan'] = addPrefix("Length of loan: ", validationMethods.numberCheck(value.lengthOfLoan));
            errors['loanVerification'] = addPrefix("Loan verification letter: ", validationMethods.stringCheck(value.loanVerification));
        }
        const filteredErrors = Object.fromEntries(
            Object.entries(errors).filter(([key, value]) => value !== '')
        );
        return Object.keys(filteredErrors).length === 0 ? "" : filteredErrors;
    },
    contigenciesCheck: (value) => {
        const errors = {}

        const choice = validationMethods.emptyCheck(value.choice);
        if (choice !== "") {
            errors['choice'] = choice;
        }
        if (value.choice.includes("inspection")) {
            const removeDate = validationMethods.numberCheck(value.inspection.removeDate);
            if (removeDate !== "") {
                errors['inspection'] = { ...errors['inspection'], removeDate: removeDate };
            }
            const negotiationPeriod = validationMethods.numberCheck(value.inspection.negotiationPeriod);
            if (negotiationPeriod !== "") {
                errors['inspection'] = { ...errors['inspection'], negotiationPeriod: negotiationPeriod };
            }
        }
        if (value.choice.includes("loan")) {
            const removeDate = validationMethods.numberCheck(value.loan.removeDate);
            if (removeDate !== "") {
                errors['loan'] = { removeDate: removeDate };
            }
        }
        if (value.choice.includes("appraisal")) {
            const removeDate = validationMethods.numberCheck(value.appraisal.removeDate);
            if (removeDate !== "") {
                errors['appraisal'] = { ...errors['appraisal'], removeDate: removeDate };
            }
            const negotiationPeriod = validationMethods.numberCheck(value.appraisal.negotiationPeriod);
            if (negotiationPeriod !== "") {
                errors['appraisal'] = { ...errors['appraisal'], negotiationPeriod: negotiationPeriod };
            }
        }
        if (value.choice.includes("exchange")) {
            const removeDate = validationMethods.numberCheck(value.exchange.removeDate);
            if (removeDate !== "") {
                errors['exchange'] = { ...errors['exchange'], removeDate: removeDate };
            }
            const address = validationMethods.addressCheck(value.exchange.address);
            if (address !== "") {
                errors['exchange'] = { ...errors['exchange'], address: address };
            }
        }

        return Object.keys(errors).length === 0 ? "" : errors;
    },
    stringCheck: (value) => {
        if (typeof value === 'string') {
            if (value.trim() === '') {
                return "Input required!"
            }
        } else {
            return "Input invalid!"
        }
        return "";
    },
    numberCheck: (value) => {
        if (typeof value === 'string') {
            if (value.trim() === '') {
                return "Input required!"
            }
        }
        const num = Number(value);
        if (isNaN(num)) {
            return "Input invalid";
        }
        if (num < 0) {
            return "The number can't be negetive";
        }
        return '';
    },
    emptyCheck: (value) => {
        if (value.length === 0) {
            return "Need make a choice";
        }
        return '';
    },
    personsCheck: (value) => {
        const errors = {};
        const result = validationMethods.stringCheck(value.type);
        if (result !== "") {
            errors['type'] = result;
        }
        if (value.type === "individual") {
            const result = validationMethods.individualCheck(value.data);
            if (result !== "") {
                errors['data'] = result;
            }
        } else if (value.type === "business") {
            const result = validationMethods.businessEntityCheck(value.data);
            if (result !== "") {
                errors['data'] = result;
            }
        }
        return Object.keys(errors).length === 0 ? "" : errors;
    },
    individualCheck: (value) => {
        const errors = []
        value.map((person) => {
            const error = {}
            const result = validationMethods.addressCheck(person.address);
            if (result)
                error["address"] = result;
            if (person.firstName.trim() === '') {
                error["firstName"] = "First name is required";
            }
            if (person.lastName.trim() === '') {
                error["lastName"] = "Last name is required";
            }
            if (person.email.trim() === '') {
                error["email"] = "Email is required";
            }
            if (person.phone.trim() === '') {
                error["phone"] = "Phone is required";
            }
            errors.push(error);
        })

        if (errors.every((error) => Object.keys(error).length === 0)) {
            return "";
        } else {
            return errors;
        }
    },
    businessEntityCheck: (value) => {
        const errors = []
        value.map((person) => {
            const error = {}
            const result = validationMethods.addressCheck(person.address);
            if (result)
                error["address"] = result;
            if (person.firstName.trim() === '') {
                error["firstName"] = "First name is required";
            }
            if (person.lastName.trim() === '') {
                error["lastName"] = "Last name is required";
            }
            if (person.email.trim() === '') {
                error["email"] = "Email is required";
            }
            if (person.phone.trim() === '') {
                error["phone"] = "Phone is required";
            }
            if (person.role.trim() === '') {
                error["role"] = "Role is required";
            }
            if (person.businessEntity.trim() === '') {
                error["businessEntity"] = "Business Entity is required";
            }
            errors.push(error);
        })

        if (errors.every((error) => Object.keys(error).length === 0)) {
            return "";
        } else {
            return errors;
        }
    },
    addressCheck: (value) => {
        const errors = {};
        if (value.street.trim() === '') {
            errors["street"] = "Street is required";
        }
        if (value.city.trim() === '') {
            errors["city"] = "City is required";
        }
        if (value.state.trim() === '') {
            errors["state"] = "State is required";
        }
        if (value.zipcode.trim() === '') {
            errors["zipcode"] = "Zipcode is required";
        }
        return Object.keys(errors).length === 0 ? "" : errors;
    },
};

function addPrefix(prefix, error) {
    if (error !== "")
        return prefix + error;
    return error;
}