import React from 'react';
import style from './LoadingPage.css';

function LoadingPage() {
    return (
        <div className="loading-container">
            <div className="loading-text">Loading</div>
            <div className="loading-spinner"></div>
        </div>
    );
}

export default LoadingPage;
