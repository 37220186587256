import React from 'react';
import LandingPage from './LandingPage/LandingPage';
import { Route, Switch } from 'react-router-dom';
import Login from './Login/Login';
import ResetPassword from './Login/ResetPassword'
import Signup from './Signup/Signup'
import Settings from './Settings/Settings'
import Profile from './Profile/Profile'
import EventsComponent from "./Events/EventsComponent";
import EventDetailComponent from "./Events/EventDetailComponent";
import ProfileView from "./Profile/ProfileView";
import ProfilesComponent from './Profile/ProfileList';
import PrivacyPage from './Terms/PrivacyPolicy';
import DisclaimerPage from './Terms/DisclaimerPage';
import TermsPage from './Terms/TermsService';
import NetworkErrorPage from './error/ErrorPageNetwork';
import NotFoundPage from './error/ErrorPage404';
import QuestionDetailView from './Questions/QuestionDetailView';
import QuestionListView from './Questions/QuestionListView';
import CreateQuestionView from './Questions/CreateQuestion';
import PurchaseFormView from './Offer/PurchaseFormView';
import OfferPage from './Offer/OfferPage';
import FormPreview from './Offer/FormPreview';
import OfferRequest from './Offer/OfferRequest';
import OfferPreview from './Offer/OfferPreview';


function App() {
  return (
    <Switch>
      <Route path='/create_question' component={CreateQuestionView} />
      <Route path='/questions/:page' component={QuestionListView} />
      <Route path='/offer' component={OfferPage} />
      <Route path='/edit/purchase-form' component={PurchaseFormView} />
      <Route path='/edit/purchase-request' component={OfferRequest} />
      <Route path='/preview/purchase-request' component={OfferPreview} />
      <Route path='/preview/purchase-form' component={FormPreview} />
      <Route path='/question/:id' component={QuestionDetailView} />
      <Route path='/terms' component={TermsPage} />
      <Route path='/privacy' component={PrivacyPage} />
      <Route path='/disclaimer' component={DisclaimerPage} />
      <Route path='/login' component={Login} />
      <Route path='/signup' component={Signup} />
      <Route path='/reset' component={ResetPassword} />
      <Route path='/settings' component={Settings} />
      <Route path='/profile-personal' component={Profile} />
      <Route path='/profiles/:pageOffset' component={ProfilesComponent} />
      <Route path='/events/:pageOffset' component={EventsComponent} />
      <Route path='/event/:id' component={EventDetailComponent} />
      <Route path='/profile/:id' component={ProfileView} />
      <Route path='/network-error' component={NetworkErrorPage} />
      <Route path='/not-found' component={NotFoundPage} />
      <Route path='/' component={LandingPage} />
    </Switch>
  );
}

export default App;
