import React, { useEffect, useState, useRef } from "react";
import { inject, observer } from "mobx-react";
import PopupDialog from "../shared/PopupDialog"
import OptionGroup from "../shared/OptionGroup";
import reportOptions from "../shared/ReportOptions";
import ProfileLink from "../Profile/ProfileLink";
import { hiddenEmail } from "../shared/hidenEmail";
import { parseJson } from "../shared/SafeJsonParser";
import { timeAgo } from "../shared/TimeConverter";

import styles from "./CommentsComponent.css";


const CommentComponent = inject("commentsStore")(observer((props) => {
    const [isReplying, setIsReplying] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isCommentMenuVisible, setIsCommentMenuVisible] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [options, setOptions] = useState([]);
    const [reportInput, setReportInput] = useState("");
    const ref = useRef();
    const dropDownRef = useRef();

    useEffect(() => {
        function handleOutsideClick(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsReplying(false);
            }

            if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
                setIsCommentMenuVisible(false);
            }
        }

        document.addEventListener("click", handleOutsideClick, { capture: true });
        return () => document.removeEventListener("click", handleOutsideClick);
    }, [ref, dropDownRef]);

    const showPopUpForComment = e => {
        setIsCommentMenuVisible(!isCommentMenuVisible);
    };

    const replyComment = e => {
        setIsReplying(!isReplying);
    };

    const replyCommentDone = e => {
        setIsReplying(false);
    };

    const updateCommentDone = e => {
        setIsUpdating(false);
    }

    const editComment = e => {
        setIsCommentMenuVisible(false);
        setIsUpdating(true);
    }

    const deleteComment = e => {
        props.commentsStore.deleteComment(props.targetId, props.targetType, props.comment.id);
        setIsCommentMenuVisible(false);
    }

    const reportComment = e => {
        setIsCommentMenuVisible(false);
        setShowPopup(true);
    }

    const handleReportCancelClick = () => {
        setShowPopup(false);
    };

    const handleReportConfirmClick = () => {
        if (!options || options.length === 0) {
            return;
        }
        const contents = [];
        options.forEach((option) => {
            const content = option === reportOptions.length - 1 && reportInput !== "" ?
                `${option}_${reportOptions[option]}_${reportInput}` : `${option}_${reportOptions[option]}`;
            contents.push(content);
        })
        const reportContent = contents.join(",");
        props.commentsStore.reportComment(props.comment.id, reportContent).then(() => {
            setShowPopup(false);
        }).catch();
    };

    const handleCheckboxChange = (options) => {
        setOptions(options)
    }

    const handleReportInputChange = (e) => {
        setReportInput(e.target.value);
    }

    const checkBox = (
        <div>
            <OptionGroup
                options={reportOptions}
                onChange={handleCheckboxChange}
                containerStyle="checbox-group"
            />
            <input
                id="reportOthers"
                style={{ "marginLeft": "35px", "height": "30px", "width": "80%" }}
                type="text"
                onChange={handleReportInputChange}
            />
        </div>
    );

    const parentId = props.comment.parentId.includes("comment") ? props.comment.parentId.split("_")[1] : props.comment.id;
    const childrenComments = parseJson(props.comment.children);
    const creationTime = timeAgo(Date.parse(props.comment.creation_time)); //`${props.comment.creation_time?.split(".")[0]}`
    return (
        <div>
            <li
                ref={ref}
                key={props.index}
                className="comment-item"
            >
                <div className="comment-container">
                    <div className="flex-box-row">
                        <ProfileLink
                            author={props.comment.author}
                            userId={props.comment.authorId}
                            hiddenEmail={!props.comment.is_editable}
                        />
                        <div className="large-view" style={{ "alignSelf": "center", "marginLeft": "6px" }}>{creationTime}</div>
                    </div>
                    <div className="dropdown">
                        <span className="hide" onClick={showPopUpForComment}><i className="fa fa-angle-down"></i></span>
                        {isCommentMenuVisible && <div ref={dropDownRef} className="dropdown-content">
                            {props.comment.is_editable && <div onClick={editComment}>Edit</div>}
                            {props.comment.is_editable && <div onClick={deleteComment}>Delete</div>}
                            {!props.comment.is_editable && <div onClick={reportComment}>Report</div>}
                        </div>}
                    </div>
                </div>
                <div className="flex-box-row" style={{ "marginLeft": "10px" }}>
                    <span className="hide" onClick={replyComment}><i className="fas fa-comments"></i></span>
                    {!isUpdating && <div style={{ "marginLeft": "20px" }}>{props.comment.content}</div>}
                    {isUpdating && <PostCommentComponent
                        targetId={props.targetId}
                        targetType={props.targetType}
                        onReplyCommentDone={updateCommentDone}
                        commentId={props.comment.id}
                        comment={props.comment.content} />
                    }
                </div>
                {isReplying && <PostCommentComponent
                    targetId={props.targetId}
                    targetType={props.targetType}
                    replyTo={props.comment.author}
                    replyToUserId={props.comment.authorId}
                    replyType={"comment"}
                    onReplyCommentDone={replyCommentDone}
                    parentId={parentId} />}
            </li>
            {props.comment.children && <ul style={{ "marginLeft": "40px" }}>
                {childrenComments && childrenComments.map((comment, index) => (
                    <CommentComponent key={index} comment={comment} targetId={props.targetId} targetType={props.targetType} />
                ))}
            </ul>}
            {showPopup && (
                <PopupDialog
                    title="Report problems"
                    message={checkBox}
                    onCancel={handleReportCancelClick}
                    onConfirm={handleReportConfirmClick}
                />
            )}
        </div>
    );
}));


const CommentsComponent = inject("commentsStore")(observer((props) => {
    useEffect(() => {
        if (props.targetId) {
            props.commentsStore.loadComments(props.targetId, props.targetType);
        }
    }, [props.targetId]);

    const Comments = observer((props) => {
        return (
            <ul>
                {props.comments.map((comment, index) => (
                    <CommentComponent key={index} comment={comment} targetId={props.targetId} targetType={props.targetType} />
                ))}
            </ul>
        );
    });

    return (
        <div style={{ "paddingTop": "20px" }}>
            <p>Comments:</p>
            <PostCommentComponent targetId={props.targetId} parentId={props.targetId} replyType={props.targetType} targetType={props.targetType} />
            <Comments comments={props.commentsStore.comments} targetId={props.targetId} targetType={props.targetType} />
        </div>
    );
}));


const PostCommentComponent = inject("commentsStore", "userStore")(observer((props) => {
    const [comment, setComment] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const ref = useRef();
    useEffect(() => { handleCommentClick() }, []);

    const handleCommentClick = e => {
        setIsEditing(true);
        if (props.replyType === "comment") {
            ref.current.innerHTML = `#Reply to ${hiddenEmail(props.replyTo)
                }: `;
        } else {
            if (props.commentId) {
                ref.current.innerHTML = props.comment;
            }
        }
        setComment(ref.current.innerHTML);
        const range = document.createRange();
        const sel = window.getSelection();
        range.selectNodeContents(ref.current);
        range.collapse(false);
        sel.removeAllRanges();
        sel.addRange(range);
    };

    const addComment = e => {
        setIsEditing(false);
        if (props.commentId) {
            props.commentsStore.updateComment(props.targetId, props.targetType, props.commentId, comment)
                .then(() => {
                    setComment("");
                    if (ref?.current) {
                        ref.current.innerHTML = ""
                    }
                    if (props.onReplyCommentDone) {
                        props.onReplyCommentDone();
                    }
                })
                .catch((error) => {
                    if (props.history) {
                        props.history.push("/login");
                    } else {
                        window.location.replace('/login');
                    }
                });
        } else {
            props.commentsStore.createComment(
                comment,
                props.targetId,
                props.targetType,
                props.parentId,
                props.replyToUserId,
                props.replyType)
                .then(() => {
                    setComment("");
                    if (ref?.current) {
                        ref.current.innerHTML = ""
                    }
                    if (props.onReplyCommentDone) {
                        props.onReplyCommentDone();
                    }
                })
                .catch((error) => {
                    if (error.status === 401) {
                        if (props.history) {
                            props.history.push("/login");
                        } else {
                            window.location.replace('/login');
                        }
                    }
                });
        }
    }

    const cancelComment = e => {
        setComment("");
        setIsEditing(false);
        ref.current.innerHTML = ""
        if (props.onReplyCommentDone) {
            props.onReplyCommentDone();
        }
    }

    const onContentBlur = e => {
        const cleanComment = removeHtmlJs(e.currentTarget.innerHTML);
        setComment(cleanComment);
    }

    return (
        <div style={{ "width": "100%" }}>
            <div className="flex-box-row">
                <p style={{ "alignSelf": "center" }}>{props.userStore.currentUser}</p>
                <div ref={ref}
                    className="comment-input"
                    contentEditable="true"
                    onClick={handleCommentClick}
                    onBlur={onContentBlur}
                    placeholder={"Add comment..."}>
                </div>
            </div>
            {isEditing && <div className="comment-button-container">
                <button className="comment-button" onClick={addComment}>Send</button>
                <button className="comment-button" onClick={cancelComment}>Cancel</button>
            </div>}
        </div>
    );
}));

function removeHtmlJs(inputString) {
    inputString = inputString.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');
    inputString = inputString.replace(/<[^>]+>/gi, '');
    return inputString;
}


export default CommentsComponent;