import React, { useState } from 'react';
import AddressComponent from "../shared/AddressComponent2";
import RadioButtonGroup from '../shared/RadioButtonGroup';
import 'react-datepicker/dist/react-datepicker.css';
import { personForm } from '../shared/PurchaseForm';

const AuthorForm = ({ label, value, onChange, errors, skip = false }) => {
    const [formData, setFormData] = useState(value);

    const handleTypeChange = (value) => {
        if (value === "unknonw") {
            setFormData({ ...formData, type: value, data: [] });
            onChange('type', value);
            onChange('data', []);
        } else {
            setFormData({ ...formData, type: value, data: [personForm] });
            onChange('type', value);
            onChange('data', [personForm]);
        }
    };

    const handlePersonChange = (index, name, value) => {
        const updatedPersonInfo = formData.data.map((person, i) => {
            if (i === index) {
                return { ...person, [name]: value };
            }
            return person;
        });

        setFormData({ ...formData, data: updatedPersonInfo });
        onChange('data', updatedPersonInfo);
    };

    const addPerson = () => {
        const updatedData = [...formData.data, personForm];
        setFormData(prevFormData => ({
            ...prevFormData,
            data: updatedData
        }));

        onChange("data", updatedData);
    };


    const removePerson = (indexToRemove) => {
        const updatedData = formData.data.filter((_, index) => index !== indexToRemove);
        setFormData(prevFormData => ({
            ...prevFormData,
            data: updatedData
        }));

        onChange("data", updatedData);
    };

    const personOptions = [
        { value: 'individual', label: "Individual(s)" },
        { value: 'business', label: "A Business Entity" }
    ];

    if (skip) {
        personOptions.push({ value: 'unknown', label: "I don't know" });
    }
    const businessOptions = [
        { value: 'president', label: "President" },
        { value: 'ceo', label: "CEO" },
        { value: 'member', label: "Member" },
        { value: 'officer', label: "Officer" },
    ];
    const labelText = `Who is the ${label}`;
    return (
        <div>
            <div className='category-title'>{labelText}</div>
            <RadioButtonGroup
                options={personOptions}
                value={value.type}
                onChange={handleTypeChange}
            />
            {errors && errors.type && <label className='error-message-form '>{errors.type}</label>}
            {value.type === "individual" && formData.data.map((item, index) => {
                const error = errors?.data ? errors.data[index] : null;
                return <div key={index} style={{ "alignItems": "center", "marginBottom": "10px" }}>
                    <div className='flex-box-row' style={{ "alignItems": "center" }}>
                        <label
                            style={{ "marginRight": "10px" }}
                            className='category-title'>
                            {label} {index + 1}:
                        </label>
                        {index !== 0 && <span style={{ "marginTop": "10px" }} className='contact-icon'
                            onClick={() => removePerson(index)}>
                            <i className='fa fa-trash'></i>
                        </span>
                        }
                    </div>
                    <div className='flex-box-row'>
                        <input
                            style={{ "marginRight": "10px" }}
                            onChange={(e) => handlePersonChange(index, 'firstName', e.target.value)}
                            className="form-input"
                            placeholder='First name'
                            value={item.firstName}
                        />
                        <input
                            style={{ "marginRight": "10px" }}
                            onChange={(e) => handlePersonChange(index, 'lastName', e.target.value)}
                            className="form-input"
                            placeholder='Last name'
                            value={item.lastName}
                        />
                    </div>
                    {error && error.firstName && <div className='error-message-form '>{error.firstName}</div>}
                    {error && error.lastName && <div className='error-message-form '>{error.lastName}</div>}
                    <div className='flex-box-column'>
                        <label className='category-title'>Contact</label>
                        <div className='flex-box-row'>
                            <input
                                style={{ "marginRight": "10px" }}
                                onChange={(e) => handlePersonChange(index, 'phone', e.target.value)}
                                className="form-input"
                                placeholder='Phone'
                                value={item.phone}
                            />
                            <input
                                style={{ "marginRight": "10px" }}
                                onChange={(e) => handlePersonChange(index, 'email', e.target.value)}
                                className="form-input"
                                placeholder='Email'
                                value={item.email}
                            />
                        </div>
                    </div>
                    {error && error.phone && <div className='error-message-form '>{error.phone}</div>}
                    {error && error.email && <div className='error-message-form '>{error.email}</div>}
                    <div className='flex-box-column'>
                        <label className='category-title'>Address</label>
                        <AddressComponent
                            value={item.address}
                            onChange={(value) => handlePersonChange(index, 'address', value)}
                            errors={error?.address}
                        />
                    </div>
                </div>
            })}
            {value.type === "individual" && <span onClick={addPerson} className='form-icon'><i className='fa fa-plus' ></i></span>}
            {value.type === "business" && formData.data.map((item, index) => {
                const error = errors?.data ? errors.data[index] : null;
                return <div key={index} style={{ "alignItems": "center", "marginBottom": "10px" }}>
                    <div>
                        <div className='flex-box-column'>
                            <label className='category-title'>Business Entity's Name</label>
                            <input
                                style={{ "marginRight": "10px" }}
                                onChange={(e) => handlePersonChange(index, 'businessEntity', e.target.value)}
                                className="form-input"
                                value={item.businessEntity}
                            />
                            {error && error.businessEntity && <label className='error-message-form '>{error.businessEntity}</label>}
                        </div>
                        {error && error.entity && <label className='error-message-form '>{error.entity}</label>}
                        <div className='flex-box-column'>
                            <label className='category-title'>Who is signing on behalf of the Business Entity</label>
                            <div className='flex-box-row'>
                                <input
                                    style={{ "marginRight": "10px" }}
                                    onChange={(e) => handlePersonChange(index, 'firstName', e.target.value)}
                                    className="form-input"
                                    placeholder='First name'
                                    value={item.firstName}
                                />
                                <input
                                    style={{ "marginRight": "10px" }}
                                    onChange={(e) => handlePersonChange(index, 'lastName', e.target.value)}
                                    className="form-input"
                                    placeholder='Last name'
                                    value={item.lastName}
                                />
                            </div>
                            {error && error.firstName && <div className='error-message-form '>{error.firstName}</div>}
                            {error && error.lastName && <div className='error-message-form '>{error.lastName}</div>}
                            <div className='flex-box-column'>
                                <label className='category-title'>Contact</label>
                                <div className='flex-box-row'>
                                    <input
                                        style={{ "marginRight": "10px" }}
                                        onChange={(e) => handlePersonChange(index, 'phone', e.target.value)}
                                        className="form-input"
                                        placeholder='Phone'
                                        value={item.phone}
                                    />
                                    <input
                                        style={{ "marginRight": "10px" }}
                                        onChange={(e) => handlePersonChange(index, 'email', e.target.value)}
                                        className="form-input"
                                        placeholder='Email'
                                        value={item.email}
                                    />
                                </div>
                                {error && error.phone && <div className='error-message-form '>{error.phone}</div>}
                                {error && error.email && <div className='error-message-form '>{error.email}</div>}
                            </div>
                        </div>
                        <div className='flex-box-column'>
                            <label className='category-title'>Who is signing on behalf of the Business Entity</label>
                            <RadioButtonGroup
                                options={businessOptions}
                                value={item.role}
                                onChange={(value) => handlePersonChange(index, "role", value)}
                            />
                            {error && error.role && <label className='error-message-form '>{error.role}</label>}
                        </div>
                    </div>
                    <div className='flex-box-column'>
                        <label className='category-title'>Address</label>
                        <AddressComponent
                            value={item.address}
                            onChange={(value) => handlePersonChange(index, 'address', value)}
                            errors={error?.address}
                        />
                    </div>
                </div>
            })}
        </div>
    );
};

export default AuthorForm;