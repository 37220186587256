import React from 'react';
import './PopupDialog.css';

const PopupDialog = ({ title, message, onClose, onCancel, onConfirm }) => {
  return (
    <div className="popup-dialog">
      <div className="popup-dialog-content">
        <div className="popup-dialog-header">
          <h2>{title}</h2>
        </div>
        <div className="popup-dialog-body">
          {message}
        </div>
        <div className="popup-dialog-footer">
          {onConfirm && (
            <button className="popup-dialog-button" onClick={onConfirm}>Confirm</button>
          )}
          {onCancel && (
            <button className="popup-dialog-button" onClick={onCancel}>Cancel</button>
          )}
        </div>
      </div>
    </div>
  );
}

export default PopupDialog;
