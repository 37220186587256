import { makeAutoObservable, action } from 'mobx';
import agent from '../agent';
import userStore from './userStore';
import CryptoJS from 'crypto-js';
import { parseJson } from "../shared/SafeJsonParser";

class AuthStore {
  constructor() {
    makeAutoObservable(this);
  }

  errorMessage = '';

  handleError(error) {
    if (error.status === 401) {
      this.errorMessage = "Email or password is wrong."
      userStore.getLoginStatus();
    } else {
      this.errorMessage = "Unknow error. Please try again.";
      if (error.response?.text) {
        const errorJson = parseJson(error.response.text)
        if (errorJson) {
          const message = errorJson.message;
          if (message !== undefined && message !== "") {
            this.errorMessage = message;
          }
        }
      } else if (error.message) {
        this.errorMessage = error.message;
      }
    }
  }

  login(email, password) {
    this.errorMessage = "";
    const encryptedPassword = encryptPassword(password);
    return agent.Auth.login(email, encryptedPassword)
      .then((response) => {
        userStore.setToken(response.access_token);
        userStore.setRefreshToken(response.refresh_token);
        userStore.setCurrentUser(email);
      }).catch((error) => {
        this.handleError(error);
      });
  }

  register(email, password, code) {
    this.errorMessage = "";
    const encryptedPassword = encryptPassword(password);
    return agent.Auth.register(email, encryptedPassword, code)
      .then((response) => {
        userStore.setToken(response.access_token);
        userStore.setRefreshToken(response.refresh_token);
      }).catch((error) => {
        this.handleError(error);
      });
  }

  validateEmail(email) {
    return agent.Auth.validateEmail(email);
  }

  logout() {
    this.errorMessage = "";
    return agent.Auth.logout(userStore.refreshToken)
      .then(() => {
        userStore.setToken(undefined);
        userStore.setRefreshToken(undefined);
        userStore.setCurrentUser(undefined);
      }).catch(action((err) => {
        this.handleError(err);
      }));
  }

  resetPassword(email, password) {
    this.errorMessage = "";
    password = generatePassword();
    const encryptedPassword = encryptPassword(password);
    return agent.Auth.resetPassword(email, password, encryptedPassword)
      .then(() => {
        userStore.setToken(undefined);
        userStore.setRefreshToken(undefined);
        userStore.forgetUser();
      }).catch(action((err) => {
        this.handleError(err);
      }));
  }

  resetPasswordSettings(oldPassword, newPassword) {
    this.errorMessage = "";
    const oldEncryptedPassword = encryptPassword(oldPassword)
    const newEncryptedPassword = encryptPassword(newPassword)
    return agent.Auth.resetPasswordSettings(oldEncryptedPassword, newEncryptedPassword)
      .then(() => {
      }).catch(action((err) => {
        this.handleError(err);
      }));
  }

  sendFeedback(feedback) {
    return agent.Auth.sendFeedback(feedback)
      .then(() => { });
  }
}

function encryptPassword(password) {
  const hash = CryptoJS.SHA256(password);
  return hash.toString(CryptoJS.enc.Hex);
}

function generatePassword() {
  const charSet = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!@#$%^&*()_+~`|}{[]:;?><,./-=';
  let newPassword = '';
  for (let i = 0; i < 12; i++) {
    const randomIndex = Math.floor(Math.random() * charSet.length);
    newPassword += charSet[randomIndex];
  }
  return newPassword;
}

export default new AuthStore();
