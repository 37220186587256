import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import TopNav from '../LandingPage/TopNav/TopNav';
import RadioButtonGroup from '../shared/RadioButtonGroup';
import DownloadButton from "../shared/FileDownload";
import CheckoutButton from "../shared/payment";
import agent from "../agent";
import { HOST_NAME } from "../shared/Constant";
import { getOfferRequestStatusText } from "../shared/options";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import styles from "./OfferPage.css";


const fullService = {
    pros: [
        "1. Comprehensive assistance throughout buying process: property search, negotiation, closing.",
        "2. Access to professional agent's expertise and market knowledge.",
        "3. Guidance navigating complex paperwork and legal processes."
    ],
    cons: [
        "1. Typically higher costs; agents charge percentage of final sale price.",
        "2. Less direct involvement in decision-making; agent takes on more responsibilities.",
        "3. Reliance on agent's schedule for property viewings and communication.",
    ]
}

const faltRate = {
    pros: [
        "1. Predictable, often lower costs compared to full- service; fixed flat - rate fees.",
        "2. Simplified pricing structure, no need to calculate commissions based on sale price.",
        "3. Some assistance and support, though more limited than full - service"
    ],
    cons: [
        "1. Less personalized service, potentially reduced access to market insights.",
        "2. Limited scope of services may not meet all buyer needs.",
        "3. Buyers may need to take on more responsibilities independently."
    ]
}

const makeOfferByYourself = {
    pros: [
        "1. Complete autonomy over buying process: making offers, negotiating directly.",
        "2. Potential cost savings by avoiding agent commissions or flat - rate fees.",
        "3. Flexibility to move at own pace, tailor process to preferences."
    ],
    cons: [
        "1. Requires strong understanding of real estate market, negotiation skills.",
        "2. Lack of professional guidance may lead to missed opportunities, mistakes.",
        "3. Time - consuming, potentially stressful, especially for inexperienced buyers."
    ]
};

function ProsConsList({ title, pros, cons }) {
    return (
        <div>
            <div style={{ "fontSize": "large" }}><b>{title}</b></div>
            <br />
            <ul>
                <div><b>Pros:</b></div>
                {pros.map((pro, index) => (
                    <li key={index} style={{ "fontSize": "small" }}>{pro}</li>
                ))}
            </ul>
            <br />
            <ul>
                <div><b>Cons:</b></div>
                {cons.map((con, index) => (
                    <li key={index} style={{ "fontSize": "small" }}>{con}</li>
                ))}
            </ul>
            <hr />
        </div>
    );
}

const OfferPage = inject("userStore")(observer((props) => {
    const [choice, setChoice] = useState("");
    const [docs, setDocs] = useState(null);
    const [requests, setRequests] = useState(null);
    const [refreshList, setRefreshList] = useState(true);
    const [error, setError] = useState("");
    const [isLoggedIn, setIsLoggedIn] = useState(!!props.userStore.currentUser);
    const [isRightContainerVisiable, setRightContainerVisiable] = useState(false);

    const businessOptions = [
        {
            value: 'fullService',
            label: "Seek a full-service agent",
            notes: [
                "Typically charges 2.5% of the property price per closing",
                "Service provided but not limited to: Offer preparation, property viewings, property search, home-buying process guidance, closing support, after-sales services, due diligence, represents the buyer's best interests during negotiations and transactions",
            ]
        },
        {
            value: 'faltRate',
            label: "Opt for a flat-rate agent",
            notes: [
                "Pay a fixed amount per closing, e.g., $5,000",
                "Service provided: Offer preparation, essential coordination, basic representation, basic closing support, limited after-sale services"
            ]
        },
        {
            value: 'purchaseForm',
            label: "Create your own purchase offer",
            notes: [
                "Enjoy unlimited modifications and downloads for just $25 within six months",
                "Creating a purchase offer on our platform is intended for informational purposes only and does not constitute legal advice",
                "It is advisable to review and make any necessary revisions to the downloaded purchase agreement before delivering it to the seller"
            ]
        },
    ];

    useEffect(() => {
        props.userStore.getLoginStatus().then(() => {
            setIsLoggedIn(!!props.userStore.currentUser);
        });
        const handleResize = () => {
            setRightContainerVisiable(false);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (refreshList) {
            try {
                agent.Offer.getOfferList().then((response) => {
                    if (response && response.docs) {
                        setDocs(response.docs);
                        setRequests(response.requests);
                    }
                }).catch(() => {
                    setDocs([]);
                    setRequests([]);
                });
            } catch (error) {
                console.error('Error during checkout:', error.response || error);
            }
            setRefreshList(false);
        }
    }, [refreshList]);

    const showRightContainer = () => {
        setRightContainerVisiable(!isRightContainerVisiable);
    }
    const handlStactClick = () => {
        if (choice === "") {
            setError("Choose any option to start.");
            return;
        }

        const url = choice === "purchaseForm" ? "/edit/purchase-form" : '/edit/purchase-request';
        if (!props.userStore.currentUser) {
            props.userStore.getLoginStatus().then(() => {
                if (!props.userStore.currentUser) {
                    localStorage.setItem('prevLocation', url);
                    props.history.push('/login');
                } else {
                    props.history.push(url);
                }
            })
        } else {
            props.history.push(url);
        }
    };
    const showList = (docs && docs.length > 0) || (requests && requests.length > 0);

    return (
        <div className="page">
            <TopNav isLoggedIn={isLoggedIn} />
            <div className="offer-page">
                <div className="left-container">
                    <div className="form-title" style={{ "marginTop": "30px" }}>
                        How would you like to submit the offer to purchase your dream home?
                    </div>
                    <RadioButtonGroup
                        options={businessOptions}
                        value={choice}
                        onChange={setChoice}
                    />
                    <button style={{ "marginTop": "20px" }} onClick={handlStactClick}>Start</button>
                    <div style={{ "color": 'red', "marginTop": "10px" }}>{error}</div>
                    <div style={{ "backgroundColor": "#eee", "padding": "10px", "borderRadius": "8px", "marginTop": "20px" }}>
                        <div style={{ "marginTop": "20px" }}>
                            As you may know, after 07/15/204, seller will not pay commission fee to buyer's agent. Buyers paying agent commissions can lead to increased transparency and agent accountability. But the bad point is that it may impact affordability for buyers, introduce complexity in negotiations and potentially affect market dynamics.
                        </div>
                        <div style={{ "marginTop": "20px" }}>
                            Our website offers a personalized agent matching service, allowing you to find the perfect agent for your needs. You have the flexibility to decide the commission fee and can even draft offers independently for a small fee.
                        </div>
                    </div>
                </div>
                <span className="offer-list-icon collpaseButton" onClick={showRightContainer}><i className="fa fa-bars"></i></span>
                <div className={`my-form ${isRightContainerVisiable ? 'visible' : ''}`}>
                    {docs && requests && !showList && <div className="scrollable-container" style={{ "height": "600px" }}>
                        <ProsConsList title="Full service"
                            pros={fullService.pros}
                            cons={fullService.cons} />
                        <ProsConsList title="Flat rate"
                            pros={faltRate.pros}
                            cons={faltRate.cons} />
                        <ProsConsList title="Make Offer by Yourself"
                            pros={makeOfferByYourself.pros}
                            cons={makeOfferByYourself.cons} />
                    </div>}
                    {showList &&
                        <DocumentsList
                            docs={docs}
                            requests={requests}
                            history={props.history}
                            onChange={() => setRefreshList(true)}
                        />
                    }
                    {(!docs || !requests) &&
                        <div className="loading-spinner"></div>
                    }
                </div>
            </div>
        </div>
    );
}));

const DocumentsList = (observer((props) => {
    const [agreeDisclaimer, setAgreeDisclaimer] = useState(false);

    const onDocDelete = (id) => {
        agent.Offer.deleteDoc(id).then(() => {
            props.onChange();
        })

    }

    const onRequestDelete = (id) => {
        agent.Offer.deletePurchaseRequest(id).then(() => {
            props.onChange();
        })
    }

    const onNavigateToLink = (link) => {
        props.history.push(link);
    }

    const previewTooltip = <Tooltip>Preview</Tooltip>;
    const deleteTooltip = <Tooltip>Delete</Tooltip>;
    const editTooltip = <Tooltip>Edit</Tooltip>;

    return <div className="scrollable-container" style={{ "height": "600px" }}>
        {props.docs && props.docs.map((doc, index) => {
            const status = getDocStatus(doc.status, doc.expiration);
            return <div key={index}>
                <div><b>{`Purchase agreement(${status})`}</b></div>
                {status !== "Active" && <div className="flex-box-row">
                    <div style={{ "fontSize": "small", "color": "grey", "alignSelf": "center", "marginRight": "10px" }}>Pay $25 to unlock download</div>
                    <CheckoutButton
                        id={doc.mongoDocId}
                        redirect_url={`${HOST_NAME}/preview/purchase-form`}
                        text="Pay $25"
                        iconButton={true}
                        iconClassname="offer-list-icon"
                    />
                </div>}
                <div className="flex-box-row" style={{ "justifyContent": "space-between", "marginTop": "20px" }}>
                    {(status !== "Active" && status !== "Exprired") && <div style={{ "fontSize": "small" }}>Created on:{getDateOnly(doc.creatTime)}</div>}
                    {(status === "Active" || status === "Exprired") && <div style={{ "fontSize": "small" }}>Expired on: {getDateOnly(doc.expiration)}</div>}
                    <div>
                        <OverlayTrigger placement="top" overlay={previewTooltip}>
                            <span
                                style={{ "marginLeft": "10px" }}
                                onClick={() => onNavigateToLink(`/preview/purchase-form?id=${doc.mongoDocId}`)}
                                className="offer-list-icon"
                            >
                                <i className="fa fa-eye"></i>
                            </span>
                        </OverlayTrigger>
                        <OverlayTrigger placement="top" overlay={editTooltip}>
                            <span
                                style={{ "marginLeft": "10px" }}
                                onClick={() => onNavigateToLink(`/edit/purchase-form?id=${doc.mongoDocId}`)}
                                className="offer-list-icon"
                            >
                                <i className="fa fa-edit"></i>
                            </span>
                        </OverlayTrigger>
                        <OverlayTrigger placement="top" overlay={deleteTooltip}>
                            <span
                                style={{ "marginLeft": "10px" }}
                                onClick={() => onDocDelete(doc.id)}
                                className="offer-list-icon"
                            >
                                <i className="fa fa-trash"></i>
                            </span>
                        </OverlayTrigger>
                    </div>
                </div>
                {status === "Active" &&
                    <div className="flex-box-row" style={{ "justifyContent": "space-between", "alignItems": "center", "marginTop": "10px" }}>
                        <label style={{ "fontSize": "12px" }}>
                            <input
                                type="checkbox"
                                value={agreeDisclaimer}
                                onClick={() => setAgreeDisclaimer(!agreeDisclaimer)}
                                style={{ "marginRight": "5px" }}
                            />
                            Check to agree the <Link to="/disclaimer">disclaimer</Link> of purchase offer
                        </label>
                        <DownloadButton
                            id={doc.mongoDocId}
                            enabled={getDocStatus(doc.status, doc.expiration) === "Active" && agreeDisclaimer}
                            iconButton={true}
                            iconClassname="offer-list-icon"
                        />
                    </div>
                }
                <hr />
            </div >;
        })
        }
        {props.requests && props.requests.map((doc, index) => {
            return <div key={index}>
                <div><b>{`Offer made request(${getOfferRequestStatusText(doc.status)})`}</b></div>
                <div style={{ "fontSize": "small", "color": "grey" }}>Price: $ {doc.price}</div>
                <div style={{ "fontSize": "small", "color": "grey" }}>Address: {doc.address}</div>
                <div style={{ "fontSize": "small", "color": "grey" }}>Agent: {getAgentText(doc.agentType, doc.agentCommission)}</div>
                <div className="flex-box-row" style={{ "justifyContent": "space-between", "marginTop": "20px" }}>
                    <div style={{ "fontSize": "small" }}>Created on:{getDateOnly(doc.creatTime)}</div>
                    <div>
                        <OverlayTrigger placement="top" overlay={previewTooltip}>
                            <span
                                style={{ "marginLeft": "10px" }}
                                onClick={() => onNavigateToLink(`/preview/purchase-request?id=${doc.mongoDocId}`)}
                                className="offer-list-icon"
                            >
                                <i className="fa fa-eye"></i>
                            </span>
                        </ OverlayTrigger>
                        {doc.status !== 0 && <OverlayTrigger placement="top" overlay={previewTooltip}>
                            <span
                                style={{ "marginLeft": "10px" }}
                                onClick={() => onNavigateToLink(`/edit/purchase-request?id=${doc.mongoDocId}`)}
                                className="offer-list-icon"
                            >
                                <i className="fa fa-edit"></i>
                            </span>
                        </ OverlayTrigger>}
                        <OverlayTrigger placement="top" overlay={previewTooltip}>
                            <span
                                style={{ "marginLeft": "10px" }}
                                onClick={() => onRequestDelete(doc.id)}
                                className="offer-list-icon"
                            >
                                <i className="fa fa-trash"></i>
                            </span>
                        </ OverlayTrigger>
                    </div>
                </div>
                <hr />
            </div >;
        })
        }
    </div>
}));

function getDocStatus(status, expiration) {
    const now = new Date();
    const expirationDate = new Date(expiration);
    if (status === 0)
        return "Unpaid";
    else if (status === 1 && expirationDate >= now)
        return "Active";
    else if (status === 1 && expirationDate < now)
        return "Exprired";
    else if (status == -1)
        return "Draft";
    else
        return "Unknown";
}

function getDateOnly(createTime) {
    const dateObj = new Date(createTime);
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1; // Month is zero-based
    const day = dateObj.getDate();
    // Format the date as 'YYYY-MM-DD'
    const formattedDate = `${year} -${month.toString().padStart(2, '0')} -${day.toString().padStart(2, '0')} `;
    return formattedDate;
}

function getAgentText(agentType, agentCommission) {
    if (agentType === "fullService") {
        return "Full service agent with " + agentCommission + "%" + "commission";
    } else if (agentType === "flatRate") {
        return "Flat rate agent with " + "$" + agentCommission + "commission";
    } else {
        return "Unknown";
    }

}
export default OfferPage;