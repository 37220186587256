import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";

const ImageSequence = observer((props) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % props.images.length);
        }, props.interval);
        return () => {
            clearInterval(timer);
        };
    }, [props.images, props.interval]);

    return (
        <div  >
            <img src={props.images[currentIndex]} alt="Image Sequence" className={props.className} />
        </div>
    );
});

export default ImageSequence;
