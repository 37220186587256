import { makeAutoObservable, observable, action } from 'mobx';
import agent from '../agent';

class VoteStore {
    constructor() {
        makeAutoObservable(this);
    }

    getVote(type) {
        return agent.Vote.getVote(type);
    }

    postVote(id, vote, description) {
        return agent.Vote.postVote(id, vote, description);
    }

    getVoteResult(id) {
        return agent.Vote.getVoteResult(id);
    }
}

export default new VoteStore();
