import React, { useEffect, useState } from "react";
import TopNav from '../LandingPage/TopNav/TopNav';
import { inject, observer } from "mobx-react";
import CheckoutButton from "../shared/payment";
import DownloadButton from "../shared/FileDownload";

import styles from "./FormPreview.css";
import { useLocation, Link } from 'react-router-dom';
import { HOST_NAME, API_ROOT } from "../shared/Constant";
import agent from "../agent";
import ResultPopup from "../shared/ResultPopup";

const FormPreview = inject("authStore", "userStore")(observer((props) => {
    const { pathname, search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const id = queryParams.get('id');
    const [documentState, setDocumentState] = useState(false);
    const [preview, setPreview] = useState('');
    const [agreeDisclaimer, setAgreeDisclaimer] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [isSuccess, setIsSuccess] = useState(true);
    const [message, setMessage] = useState('Payment successful');
    const [isLoggedIn, setIsLoggedIn] = useState(!!props.userStore.currentUser);

    const onNavigateToLink = (link) => {
        props.history.push(link);
    };

    const handlePopupClose = () => {
        setShowPopup(false);
        props.history.push(`${pathname}?id=${id}`);
    };

    const showReminder = (show, message, isSuccess) => {
        setIsSuccess(isSuccess);
        setMessage(message);
        setShowPopup(show);
    }

    useEffect(() => {
        props.userStore.getLoginStatus().then(() => {
            setIsLoggedIn(!!props.userStore.currentUser);
        });
    }, []);

    useEffect(() => {
        try {
            if (!props.userStore.currentUser) {
                props.userStore.getLoginStatus();
            }
            const state = queryParams.get('state')

            if (state !== null && state !== undefined) {
                const sessionId = queryParams.get('payment_id');
                if (state === '1' && sessionId) {
                    agent.Payment.paymentSuccess(sessionId, id, state, "purchasedoc")
                        .then(response => {
                            if (response.result === "success") {
                                agent.Offer.getPurchaseForm(id, 1).then((response) => {
                                    if (response) {
                                        const now = new Date();
                                        const expirationDate = new Date(response.doc_status.expiration);
                                        setDocumentState((response.doc_status.status == 1) && (expirationDate > now));
                                        setPreview(response.preview);
                                        showReminder(true, "Payment successful", true)
                                    }
                                });
                            } else {
                                showReminder(true, "Payment failed", false)
                                console.error('Error handling payment');
                            }
                        })
                        .catch(error => {
                            showReminder(true, "Payment failed", false)
                            console.error('Error handling payment', error);
                        });
                    return;
                } else {
                    showReminder(true, "Payment failed", false)
                }
            }
            if (!!id && id !== "") {
                agent.Offer.getPurchaseForm(id, 1).then((response) => {
                    if (response) {
                        const now = new Date();
                        const expirationDate = new Date(response.doc_status.expiration);
                        setDocumentState((response.doc_status.status == 1) && (expirationDate > now));
                        setPreview(response.preview);
                        if (state === '0') {
                            showReminder(true, "Payment failed", false)
                        }
                    }
                });
            }
        } catch (error) {
            console.error('Error during checkout:', error.response || error);
        }
    }, []);

    return (
        <div className="page">
            <TopNav isLoggedIn={isLoggedIn} />
            <div className="offer-container">
                {preview !== "" && <iframe src={`${API_ROOT}/doc-preview?token=${preview}`} width="100%" height="600px" />}
                <div className="flex-box-row" style={{ "justifyContent": "flex-end", "marginTop": "10px" }}>
                    {documentState &&
                        <div>
                            <label>
                                <input
                                    type="checkbox"
                                    value={agreeDisclaimer}
                                    onClick={() => setAgreeDisclaimer(!agreeDisclaimer)}
                                    style={{ "marginRight": "10px" }}
                                />
                                Check to agree the <Link to="/disclaimer">disclaimer</Link> of purchase offer
                            </label>
                            <DownloadButton
                                id={id}
                                enabled={documentState && agreeDisclaimer}
                                className="form-preview-button"
                            />
                        </div>
                    }
                    {!documentState &&
                        <CheckoutButton
                            id={id}
                            redirect_url={`${HOST_NAME}/preview/purchase-form`}
                            text="Pay $25 to unlock"
                            className="form-preview-button"
                        />
                    }
                    <button
                        className="form-preview-button"
                        onClick={() => onNavigateToLink(`/edit/purchase-form?id=${id}`)}
                    >
                        Edit
                    </button>
                </div>
            </div>
            <div>
                {showPopup && <ResultPopup message={message} isSuccess={isSuccess} onClose={handlePopupClose} />}
            </div>
        </div>
    );
}));

export default FormPreview;