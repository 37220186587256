import React from "react";
import TopNav from "../LandingPage/TopNav/TopNav";

const NetworkErrorPage = () => {
    return (
        <div className="page">
            <TopNav isLoggedIn={false} />
            <h1>Network Error</h1>
            <p>Unable to connect to the server. Please check your internet connection and try again.</p>
        </div>
    );
};

export default NetworkErrorPage;
