import { makeAutoObservable, observable, action } from 'mobx';
import agent from '../agent';

class ProfileStore {
  profiles = observable(new Map());;
  profile = null;
  personalProfile = null;
  personalProfileOrg = null;
  pagesCount = 0;

  constructor() {
    makeAutoObservable(this);
  }

  resetPersonalProfile() {
    this.personalProfile = this.personalProfileOrg;
  }

  getPersonalProfile() {
    return agent.Profile.getPersonalProfile()
      .then((response) => {
        if (response.status == "not created") {
          this.personalProfile = null;
          this.personalProfileOrg = null;
        } else {
          this.personalProfile = response;
          this.personalProfileOrg = response;
        }
      }
      );
  }

  savePersonalProfile(profile) {
    return agent.Profile.savePersonalProfile(profile)
      .then((response) => {
        this.personalProfile = response;
      });
  }

  getProfiles(page, params) {
    return agent.Profile.getProfiles(page, params)
      .then((response) => {
        this.profiles = response.profiles;
        this.pagesCount = response.page_count;
      })
      .catch((error) => {
      })
      .finally()
  }

  getProfileView(shortUrl) {
    return agent.Profile.getProfileView(shortUrl)
      .then((response) => {
        this.profile = response;
      });
  }

  getProfileByUserId(userId) {
    return agent.Profile.getProfileByUserId(userId);
  }

  uploadImage(image) {
    return agent.Profile.uploadImage(image);
  }

  getFollowingStatus(followeeId) {
    return agent.Profile.getFollowingStatus(followeeId);
  }

  changeFollowStates(followeeId) {
    return agent.Profile.changeFollowStates(followeeId);
  }
}

export default new ProfileStore();
