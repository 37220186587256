import React, { useEffect, useState } from 'react';
import { inject, observer } from "mobx-react";
import TopNav from '../LandingPage/TopNav/TopNav';
import PreviewPage from "./ProfilePreview";

import styles from './ProfileView.css';
import LoadingPage from '../shared/LoadingPage';

const ProfileView = inject("profileStore", "userStore")(observer((props) => {
	const pathnames = props.location.pathname.split("/");
	const [errorMessage, setErrorMessage] = useState("");
	const [isLoading, setIsLoading] = useState(true);
	const [following, setFollowing] = useState(null);

	useEffect(() => {
		if (!props.userStore.currentUser) {
			props.userStore.getLoginStatus();
		}
		props.profileStore.getProfileView(pathnames[pathnames.length - 1]
		).then(() => {
			setIsLoading(false);
			if (props.userStore.currentUser) {
				props.profileStore.getFollowingStatus(props.profileStore.profile.userId).then((response) => {
					if (response.showFollowButton) {
						setFollowing(response.following);
					}
				});
			}
		}
		).catch((error) => {
			setIsLoading(false);
			setErrorMessage("Failed to load profile");
		});
	}, []);

	return <div className="page">
		<TopNav isLoggedIn={!!props.userStore.currentUser} />
		{!isLoading && <div className='preview-container'>
			<PreviewPage profile={props.profileStore.profile} showComments={true} following={following} />
			<div>{errorMessage}</div>
		</div>}
		{isLoading && <LoadingPage />}
	</div>;
}));



export default ProfileView;