import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import TopNav from '../LandingPage/TopNav/TopNav';
import { inject, observer } from "mobx-react";
import DatePicker from "react-datepicker";
import AddressComponent from "../shared/AddressComponent";
import PopupDialog from "../shared/PopupDialog"
import OptionGroup from "../shared/OptionGroup";
import reportOptions from "../shared/ReportOptions";
import CommentsComponent from "../comment/CommentsComponent";
import { generateAddressString } from "../shared/GenerateAddressString";
import VoteComponent from "../shared/VoteComponent";
import ProfileLink from "../Profile/ProfileLink";
import { handleError } from "../shared/ErrorHandler";
import LoadingPage from "../shared/LoadingPage";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { modules, formats } from '../shared/QuillEditorConfig';

import styles from "./Events.css";


const EventDetailComponent = inject("eventsStore", "userStore")(observer((props) => {
	const pathnames = props.location.pathname.split("/");
	const [showPopup, setShowPopup] = useState(false);
	const [isAbandon, setIsAbandone] = useState(false);
	const [options, setOptions] = useState([]);
	const [reportInput, setReportInput] = useState("");
	const [error, setError] = useState("");
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (!props.userStore.currentUser) {
			props.userStore.getLoginStatus();
		}
		props.eventsStore.getEventDetail(pathnames[pathnames.length - 1]).then(() => {
			props.eventsStore.assignToDraftEvent(props.eventsStore.eventDetail);
			setIsAbandone(props.eventsStore.eventDetail.status < 0);
			setIsLoading(false);
		}).catch((error) => {
			setIsLoading(false);
			setError("Failed to load event. Please try again later.");
		});
	}, []);

	const handleLikes = id => {
		props.eventsStore.postEventLike(id);
	}

	const joinEvent = id => {
		props.eventsStore.joinEvent(id)
			.then()
			.catch((error) => {
				handleError(error, props.history);
			});
	}

	const abandonEvent = id => {
		props.eventsStore.abandonEvent(id, !isAbandon).then(() => {
			setIsAbandone(props.eventsStore.eventDetail.status < 0);
		}).catch(error => {
			handleError(error, props.history);
		});

	}

	const event = props.eventsStore.eventDetail;
	if (event === undefined) {
		return null;
	}

	const handleReportPopup = () => {
		setShowPopup(true);
	};

	const handleReportCancelClick = () => {
		setShowPopup(false);
	};

	const handleReportConfirmClick = () => {
		if (!options || options.length === 0) {
			return;
		}
		const contents = [];
		options.forEach((option) => {
			const content = option === reportOptions.length - 1 && reportInput !== "" ?
				`${option}_${reportOptions[option]}_${reportInput}` : `${option}_${reportOptions[option]}`;
			contents.push(content);
		})
		const reportContent = contents.join(",");
		props.eventsStore.reportEvent(props.eventsStore.eventDetail.id, reportContent).then(() => {
			setShowPopup(false);
		}).catch();
	};

	const handleCheckboxChange = (options) => {
		setOptions(options)
	}

	const handleReportInputChange = (e) => {
		setReportInput(e.target.value);
	}

	const checkBox = (
		<div>
			<OptionGroup
				options={reportOptions}
				onChange={handleCheckboxChange}
				containerStyle="checbox-group"
			/>
			<input
				id="reportOthers"
				style={{ "marginLeft": "35px", "height": "30px", "width": "80%" }}
				type="text"
				onChange={handleReportInputChange}
			/>
		</div>
	);
	const abandonLabel = isAbandon ? "Unabandon" : "Abandon";
	const eventDetail = (
		<div key={event.id} >
			<div className="events-info">
				<div className="flex-box-row">
					<div className="post-by-text large-view">Posted by:</div>
					<div style={{ "margin": "6px" }}>
						<ProfileLink
							author={event.author}
							userId={event.authorId}
							hiddenEmail={!event.is_editable}
						/>
					</div>
					<div className="post-by-text  large-view">at:</div>
					<div className="post-by-text  large-view">{event.creatTime?.split(".")[0]}</div>
				</div>
				<div className="flex-box-row ">
					<div style={{ "marginRight": "6px", "alignSelf": "center" }}><
						span style={{ "alignSelf": "center", "color": "#bd1f1f" }}><i className="fas fa-user-alt"></i></span>
					</div>
					<div style={{ "marginRight": "10px", "alignSelf": "center" }}>{event.attendees_count}</div>
					<div style={{ "marginRight": "4px", "alignSelf": "center" }} className="event-icon" onClick={() => handleLikes(event.id)}>
						<span style={{ "alignSelf": "center", "color": "#bd1f1f" }}><i className="fa fa-heart"></i></span></div>
					<div style={{ "marginRight": "10px", "alignSelf": "center" }}>{event.likes_count}</div>
					{!props.eventsStore.eventDetail.is_editable && <button
						className="join-event-button"
						type="submit"
						onClick={() => joinEvent(event.id)}>
						Join
					</button>}
					{props.eventsStore.eventDetail.is_editable && <button
						className="join-event-button"
						type="submit"
						onClick={() => abandonEvent(event.shortUrl)}>
						{abandonLabel}
					</button>}

				</div>
			</div>
			<EditText isEditable={props.eventsStore.eventDetail.is_editable} />
			<EditEventTime isEditable={props.eventsStore.eventDetail.is_editable} />
			<EditEventAddress isEditable={props.eventsStore.eventDetail.is_editable} />
			<EditTextArea isEditable={props.eventsStore.eventDetail.is_editable} />
			<div className="report-event" onClick={handleReportPopup} >Report this event</div>
			{showPopup && (
				<PopupDialog
					title="Report problems"
					message={checkBox}
					onCancel={handleReportCancelClick}
					onConfirm={handleReportConfirmClick}
				/>
			)}
		</div>);

	return (
		<div className="page ">
			<TopNav isLoggedIn={!!props.userStore.currentUser} />
			{!isLoading && <><div className="events-container">
				<div className="event-container">
					{eventDetail}
					<div style={{ "paddingLeft": "20px", "paddingRight": "10px" }}>
						<CommentsComponent targetId={event.id} targetType={"event"} />
					</div>
				</div>
				<VoteComponent type={1} />
			</div>
				<div>{error}</div>
			</>}
			{isLoading && <LoadingPage />}
		</div>
	);
}));


const EditEventTime = inject("eventsStore")(observer((props) => {
	const dateTimeFromProps = props.eventsStore.eventDetail.eventTime !== undefined
		&& props.eventsStore.eventDetail.eventTime !== "" ?
		new Date(Number(props.eventsStore.eventDetail.eventTime)) :
		null;
	const [editing, setEditing] = useState(false);
	const [dateTime, handleDateTimeChange] = useState(dateTimeFromProps);
	const ref = useRef();

	useEffect(() => {
		function handleOutsideClick(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				handleCancel();
			}
		}

		document.addEventListener("click", handleOutsideClick, { capture: true });
		return () => document.removeEventListener("click", handleOutsideClick);
	}, [ref]);

	const handleSave = (e) => {
		setEditing(false);
		props.eventsStore.setPostEventTime(dateTime.getTime());
		props.eventsStore.setPostEventTimezone(dateTime.getTimezoneOffset());
		props.eventsStore.postEvent(props.eventsStore.eventDetail.shortUrl)
			.then()
			.catch((error) => {
				handleError(error, props.history);
			});
	};

	const handleCancel = (e) => {
		setEditing(false);
	};

	const handleEditText = (e) => {
		setEditing(true);
	};

	if (dateTimeFromProps === null) {
		return null;
	}

	return (
		<div ref={ref}>
			{!editing && (
				<div className='event-edit-block'>
					<p>Event time: {dateTimeFromProps.toString()}</p>
					{props.isEditable && <div className="event-icon" onClick={handleEditText}><span><i className="fa fa-edit"></i></span></div>}
				</div>)}
			{props.isEditable && editing && (
				<div className='event-edit-block'>
					<p className="date-time-label">Event time: </p>
					<DatePicker
						showIcon
						showTimeSelect
						selected={dateTime}
						onChange={handleDateTimeChange}
						dateFormat="Pp"
						className="date-time-picker"
					/>
					<div className="event-icon" onClick={handleSave}><span><i className="fa fa-check"></i></span></div>
					<div className="event-icon" onClick={handleCancel}><span><i className="fa fa-times"></i></span></div>
				</div>
			)}
		</div>
	);
}));


const EditEventAddress = inject("eventsStore")(observer((props) => {
	const [editing, setEditing] = useState(false);
	const [typeOfEvent, setTypeOfEvent] = useState(props.eventsStore.eventDetail.eventType);
	const [meetingLink, setMeetingLink] = useState(props.eventsStore.eventDetail.meetingLink);
	const [address, setAddress] = useState(props.eventsStore.eventDetail.address);
	const [zipcode, setZipcode] = useState(props.eventsStore.eventDetail.zipcode);
	const ref = useRef();

	useEffect(() => {
		function handleOutsideClick(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				handleCancel();
			}
		}

		document.addEventListener("click", handleOutsideClick, { capture: true });
		return () => document.removeEventListener("click", handleOutsideClick);
	}, [ref]);

	const handleSave = (e) => {
		setEditing(false);
		props.eventsStore.setPostEventType(typeOfEvent);
		props.eventsStore.setPostEventMeetingLink(meetingLink);
		props.eventsStore.setPostEventAddress(address);
		props.eventsStore.setPostEventZipcode(zipcode);
		props.eventsStore.postEvent(props.eventsStore.eventDetail.shortUrl)
			.then()
			.catch((error) => {
				handleError(error, props.history);
			});
	};

	const handleCancel = (e) => {
		setEditing(false);
		setTypeOfEvent(props.eventsStore.eventDetail.eventType);
		setAddress(props.eventsStore.eventDetail.address);
		setZipcode(props.eventsStore.eventDetail.zipcode);

	};

	const handleTypeOfEventChange = e => {
		setTypeOfEvent(e.target.value);
	};

	const handleEditText = (e) => {
		setEditing(true);
	};

	const handleMeetingLinkChange = e => {
		setMeetingLink(e.target.value);
	}

	const handleAddressChange = address => {
		setAddress(address);
	};

	const handleZipcodeChange = zipcode => {
		setZipcode(zipcode);
	};

	const typeOfEventComp = (
		<div style={{ "width": "90%" }}>
			<div className="flex-box-row">
				<input
					type="radio"
					id="inperson"
					className="event-type-radio"
					name="eventtype"
					value="1"
					onChange={handleTypeOfEventChange}
					checked={typeOfEvent === "1"}
				/>
				<label className="event-type-label">In person</label>
				<input
					type="radio"
					id="online"
					className="event-type-radio"
					name="eventtype"
					value="2"
					onChange={handleTypeOfEventChange}
					checked={typeOfEvent === "2"}
				/>
				<label className="event-type-label">Online</label>
			</div>
			{typeOfEvent === "2" && <input className="post-event-input" type="text" placeholder={"Online event meeting link"} value={meetingLink} onChange={handleMeetingLinkChange} />}
			{typeOfEvent === "1" &&
				<AddressComponent
					address={address}
					zipcode={zipcode}
					handleAddressChange={handleAddressChange}
					handleZipcodeChange={handleZipcodeChange}
					oneLineLayout={true}
				/>
			}
		</div>);

	const addressString = props.eventsStore.eventDetail.address ? generateAddressString(props.eventsStore.eventDetail.address) : null;

	return (
		<div ref={ref}>
			{!editing && (
				<div className='event-edit-block'>
					{typeOfEvent === "1" && <p>Address: {addressString}</p>}
					{typeOfEvent === "2" && <p>Meeting link: {meetingLink}</p>}
					{props.isEditable && <div className="event-icon" onClick={handleEditText}><span><i className="fa fa-edit"></i></span></div>}
				</div>
			)}
			{props.isEditable && editing && (
				<div className='event-edit-block'>
					{typeOfEventComp}
					<div className="flex-end-group">
						<div className="event-icon" onClick={handleSave}><span><i className="fa fa-check"></i></span></div>
						<div className="event-icon" onClick={handleCancel}><span><i className="fa fa-times"></i></span></div>
					</div>
				</div>
			)}
		</div>
	);
}));


const EditText = inject("eventsStore")(observer((props) => {
	const [editing, setEditing] = useState(false);
	const [editingValue, setEditingValue] = useState(props.eventsStore.eventDetail.title);
	const ref = useRef(null);

	useEffect(() => {
		function handleOutsideClick(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				handleCancel();
			}
		}

		document.addEventListener("click", handleOutsideClick, { capture: true });
		return () => document.removeEventListener("click", handleOutsideClick);
	}, [ref, props.eventsStore.eventDetail.title]);

	const handleSave = (e) => {
		setEditing(false);
		props.eventsStore.setPostEventTitle(editingValue);
		props.eventsStore.postEvent(props.eventsStore.eventDetail.shortUrl).then(() => {
			setEditingValue(editingValue);
		}).catch((error) => {
			handleError(error, props.history);
		});
	};

	const handleCancel = (e) => {
		setEditing(false);
		setEditingValue(props.eventsStore.eventDetail.title);
	};

	const handleEditText = (e) => {
		setEditing(true);
	};

	const handleEditChange = (e) => {
		setEditingValue(e.target.value);
	};

	return (
		<div ref={ref}>
			{!editing && (
				<div className='event-edit-row'>
					<p>{props.eventsStore.eventDetail.title}</p>
					{props.isEditable && <div className="event-icon" onClick={handleEditText}><span><i className="fa fa-edit"></i></span></div>}
				</div>
			)}
			{props.isEditable && editing && (
				<div className='event-editing-row'>
					<input type="text" className="event-detail-title" value={editingValue} onChange={handleEditChange} />
					<div className="event-icon" onClick={handleSave}><span><i className="fa fa-check"></i></span></div>
					<div className="event-icon" onClick={handleCancel}><span><i className="fa fa-times"></i></span></div>
				</div>
			)}
		</div>
	);
}));


const EditTextArea = inject("eventsStore")(observer((props) => {
	const [editing, setEditing] = useState(false);
	const [editingValue, setEditingValue] = useState("");
	const ref = useRef();

	useEffect(() => {
		setEditingValue(props.eventsStore.eventDetail.content);
		function handleOutsideClick(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				handleCancel();
			}
		}

		document.addEventListener("click", handleOutsideClick, { capture: true });
		return () => document.removeEventListener("click", handleOutsideClick);
	}, [ref, props.eventsStore.eventDetail.content]);

	const handleSave = (e) => {
		setEditing(false);
		props.eventsStore.setPostEventContent(editingValue);
		props.eventsStore.postEvent(props.eventsStore.eventDetail.shortUrl).then(() => {
			setEditingValue(editingValue);
		}).catch((error) => {
			handleError(error, props.history);
		});
	};

	const handleCancel = (e) => {
		setEditing(false);
		setEditingValue(props.eventsStore.eventDetail.content);
	};

	const handleEditText = (e) => {
		setEditing(true);
	};

	const handleEditChange = (value) => {
		setEditingValue(value);
	};


	return (
		<div ref={ref}>
			{!editing && (
				<div className='event-text-area'>
					{props.isEditable && <div className="event-text-area-icon" onClick={handleEditText}><span><i className="fa fa-edit"></i></span></div>}
					<div dangerouslySetInnerHTML={{ __html: props.eventsStore.eventDetail.content }}></div>
				</div>
			)}
			{props.isEditable && editing && (
				<div className='event-editing-text-area'>
					<div className="flex-end-group">
						<div className="event-icon" onClick={handleSave}><span><i className="fa fa-check"></i></span></div>
						<div className="event-icon" onClick={handleCancel}><span><i className="fa fa-times"></i></span></div>
					</div>
					<ReactQuill
						id="event"
						className="event-detail-edit"
						value={editingValue}
						onChange={handleEditChange}
						modules={modules}
						formats={formats}
					/>
				</div>
			)}
		</div>
	);
}));

export default EventDetailComponent;