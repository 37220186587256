import { parseJson } from "./SafeJsonParser";

export function generateAddressString(addressJsonString, zipcode) {
    const addressArray = [];
    if (addressJsonString) {
        const addressJson = parseJson(addressJsonString);
        if (addressJson) {
            const apt = addressJson.apt !== "" ? `Apt ${addressJson.apt}` : "";
            addressArray.push(apt);
            addressArray.push(addressJson.street);
            addressArray.push(addressJson.city);
            addressArray.push(addressJson.state);
        }
    }

    if (zipcode) {
        addressArray.push(zipcode);
    }

    const addressFiltered = addressArray.filter(str => str !== "");
    return addressFiltered.join(",");
}

export function generateAddressStringFromObject(address) {
    const addressArray = [];
    if (address) {
        const apt = address.apt !== "" ? `Apt ${address.apt}` : "";
        addressArray.push(apt);
        addressArray.push(address.street);
        addressArray.push(address.city);
        addressArray.push(address.state);
        addressArray.push(address.zipcode);
    }


    const addressFiltered = addressArray.filter(str => str !== "");
    return addressFiltered.join(", ");
}