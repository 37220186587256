import React, { useState, useEffect } from 'react';
import './OverlayReminder.css';

const OverlayReminder = ({ show, message, onClose }) => {
    useEffect(() => {
        if (show) {
            const timeoutId = setTimeout(() => {
                onClose();
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [show]);

    return (
        <div>
            {show && (
                <div className="overlay-reminder">
                    <div className="overlay-content">
                        <p>{message}</p>
                    </div>
                </div>
            )}
        </div>
    );
}

export default OverlayReminder;
