import React, { useState } from 'react';
import { profileOptions } from "../shared/ProfileOptions";
import ComboBox from "../shared/Combobox";
import { observer } from "mobx-react";

import styles from './SearchBar.css';


const SearchProfile = observer((props) => {
    const [term, setTerm] = useState('');
    const [location, setLocation] = useState('');

    function submit(e) {
        const searchParams = `?profileType=${term}&&zipcode=${location}`;
        props.history.push(`/profiles/0${searchParams}`);
        e.preventDefault();
    }

    return (
        <div className='search-bar-container'>
            <div className="search-label first-label">Search</div>
            <ComboBox
                options={profileOptions}
                onChange={(value) => setTerm(value)}
                value={term}
                className="search-combo-box adapt-small-view" />
            <div className={"search-label"}>Near</div>
            <input className="input-control adapt-small-view "
                onChange={(e) => setLocation(e.target.value)}
                type="text"
                value={location}
                placeholder="zipcode" />
            <div className="search-button adapt-small-view " onClick={submit}>
                <span className='search-icon'><i className="fas fa-search"></i></span>
            </div>
        </div>
    );
});

export default SearchProfile;