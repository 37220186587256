import React, { useState, useRef, useEffect } from 'react';


function ComboBox(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(props.value);
    const [filteredOptions, setFilteredOptions] = useState(props.options);
    const inputRef = useRef(null);

    useEffect(() => {
        function handleOutsideClick(event) {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        document.addEventListener("click", handleOutsideClick, { capture: true });
        return () => document.removeEventListener("click", handleOutsideClick);
    }, [inputRef]);

    function toggleDropdown() {
        setIsOpen(!isOpen);
    }

    function handleInputChange(e) {
        const value = e.target.value;
        setSelectedValue(value);
        if (props.onChange) {
            props.onChange(value);
        }

        const filtered = props.options.filter(option =>
            option.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredOptions(filtered);
        if (filtered.length === 0) {
            setIsOpen(false);
        }
    }

    function handleOptionClick(option) {
        setSelectedValue(option);
        if (props.onChange) {
            props.onChange(option);
        }
        setIsOpen(false);
    }

    const filterOptions = filteredOptions.map((option, index) => {
        return <li className="combo-box-li" key={index} onClick={() => handleOptionClick(option)} value={option}>
            {option}
        </li>;
    });

    return (
        <div className={`combo-box ${props.className}`} ref={inputRef}>
            <input
                type="text"
                className="combo-box-input"
                value={selectedValue}
                onClick={toggleDropdown}
                onChange={handleInputChange}
                disabled={props.disabled}
                placeholder={props.placeholder}
            />
            {
                isOpen && (
                    <ul className="combo-box-select">
                        {filterOptions}
                    </ul>
                )
            }
        </div >
    );
}

export default ComboBox;