import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bulma/css/bulma.css';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "mobx-react";

import commentsStore from "./stores/commentsStore";
import authStore from "./stores/authStore";
import userStore from "./stores/userStore";
import profileStore from "./stores/profileStore";
import eventsStore from "./stores/eventsStore";
import ratingStore from "./stores/ratingStore";
import voteStore from "./stores/voteStore";
import answerStore from "./stores/answerStore";
import questionStore from "./stores/questionStore";
import Footer from './shared/Footer';

const stores = {
  commentsStore,
  authStore,
  userStore,
  profileStore,
  eventsStore,
  ratingStore,
  voteStore,
  answerStore,
  questionStore
};

ReactDOM.render(
  <Provider {...stores}>
    <BrowserRouter>
      <App />
      <Footer />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
