import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
import userStore from './stores/userStore';
import { API_ROOT } from "./shared/Constant";

const superagent = superagentPromise(_superagent, global.Promise);

const encode = encodeURIComponent;

const handleErrors = err => {
  return err;
};

const responseBody = res => res.body;

const tokenPlugin = req => {
  if (userStore.token) {
    req.set('authorization', `Bearer ${userStore.token}`);
  }
};

const requests = {
  del: url =>
    superagent
      .del(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .retry(2)
      .timeout({
        response: 5000,  // Wait 5 seconds for the server to start sending,
        deadline: 60000, // but allow 1 minute for the file to finish loading.
      })
      .end(handleErrors)
      .then(responseBody),
  get: (url) =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .retry(2)
      .timeout({
        response: 5000,  // Wait 5 seconds for the server to start sending,
        deadline: 60000, // but allow 1 minute for the file to finish loading.
      })
      .end(handleErrors)
      .then(responseBody),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .retry(2)
      .timeout({
        response: 5000,  // Wait 5 seconds for the server to start sending,
        deadline: 60000, // but allow 1 minute for the file to finish loading.
      })
      .end(handleErrors)
      .then(responseBody),
  post: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .retry(2)
      .timeout({
        response: 30000,  // Wait 5 seconds for the server to start sending,
        deadline: 60000, // but allow 1 minute for the file to finish loading.
      })
      .end(handleErrors)
      .then(responseBody),
  download: (url) => superagent
    .get(`${API_ROOT}${url}`)
    .use(tokenPlugin)
    .responseType('blob')
    .retry(2)
    .timeout({
      response: 5000,  // Wait 5 seconds for the server to start sending,
      deadline: 60000, // but allow 1 minute for the file to finish loading.
    })
    .end(handleErrors)
};

const Auth = {
  current: () =>
    requests.get(`/settings`),
  login: (email, password) =>
    requests.post('/login', { email, password }),
  getLoginStatus: () =>
    requests.get('/login'),
  register: (email, password, code) =>
    requests.post('/signup', { email, password, code }),
  validateEmail: (email) =>
    requests.post('/validate_email', { email }),
  save: (user) =>
    requests.post('/personal_settings', { user }),
  resetPassword: (email, password, encrypedPassword) =>
    requests.post('/password_reset', { email, password, encrypedPassword }),
  resetPasswordSettings: (oldPassword, newPassword) =>
    requests.post('/password_reset_settings', { oldPassword, newPassword }),
  logout: (refreshToken) =>
    requests.post('/logout', { refreshToken }),
  uploadUserPhoto: (file) =>
    superagent.post(`${API_ROOT}/upload_user_photo`)
      .use(tokenPlugin)
      .attach("file", file)
      .end(handleErrors)
      .then(responseBody),
  sendFeedback: (feedback) =>
    requests.post('/feedback', { feedback }),
  refreshToken: (refresh_token, token) =>
    requests.post('/refresh', { refresh_token, token }),
  sendInquiryEmail: (name, email, message, phone, toEmail) =>
    requests.post('/email_inquiry', { name, email, message, phone, toEmail }),
  shareViaEmail: (toEmail, content) =>
    requests.post('/email_share', { toEmail, content }),
};

const Comments = {
  create: (comment, targetId, parentId, replyUserId, replyType) =>
    requests.post(`/comment`, { comment, targetId, parentId, replyUserId, replyType }),
  delete: (commentId) =>
    requests.del(`/comment?commentId=${commentId}`),
  update: (id, comment) =>
    requests.put(`/comment?content=${encode(comment)}&&commentId=${id}`),
  loadComments: (targetId, commentType) =>
    requests.get(`/comments?targetId=${targetId}&&type=${commentType}`)
};

const Profile = {
  getProfileByUserId: userId =>
    requests.get(`/profile?userId=${userId}`),
  getProfileView: id =>
    requests.get(`/profile/${id}`),
  getProfiles: (page, params) =>
    requests.get(`/profiles/${page}${params}`),
  savePersonalProfile: (profile) =>
    requests.post("/personal_profile", { profile }),
  getPersonalProfile: () =>
    requests.get("/personal_profile"),
  uploadImage: (file) =>
    superagent.post(`${API_ROOT}/upload_profile_photo`)
      .use(tokenPlugin)
      .attach("file", file)
      .end(handleErrors)
      .then(responseBody),
  getFollowingStatus: (followeeId) =>
    requests.get(`/follow?followeeId=${followeeId}`),
  changeFollowStates: (followeeId) =>
    requests.post("/follow", { followeeId }),
};

const Events = {
  getEvents: (id, params) =>
    requests.get(`/events/${id}${params}`),
  postEvent: (eventId, event) =>
    requests.post(`/event/${eventId}`, { event }),
  getEventDetail: (eventId, email) =>
    requests.get(`/event/${eventId}`),
  postEventLike: (eventId) =>
    requests.post('/event_like', { eventId }),
  joinEvent: (eventId) =>
    requests.post('/join_event', { eventId }),
  abandonEvent: (eventId, isAbandoned) =>
    requests.put(`/event/${eventId}?abandon=${isAbandoned}`)
}

const Rating = {
  getRating: (targetId, targetType) =>
    requests.get(`/rating?targetId=${targetId}&&targetType=${targetType}`),
  postRating: (targetId, targetType, rating) =>
    requests.post(`/rating`, { targetId, targetType, rating }),
}

const Vote = {
  getVote: (type) =>
    requests.get(`/vote_options?type=${type}`),
  postVote: (id, vote, description) =>
    requests.post(`/vote`, { id, vote, description }),
  getVoteResult: (id) =>
    requests.get(`/vote?id=${id}`)
}

const Report = {
  reportProblem: (id, reportContent) =>
    requests.post(`/report_problem`, { id, reportContent }),
}

const Question = {
  createQuestion: (id, title, content, tags, state) =>
    requests.post("/question", { id, title, content, tags, state }),
  updateQuestion: (id, title, content, tags, state) =>
    requests.post("/question", { id, title, content, tags, state }),
  deleteQuestion: (id) =>
    requests.del(`/question?id=${id}`),
  getQuestion: (shortUrl) =>
    requests.get(`/question?shortUrl=${shortUrl}`),
  getQuestions: (page, params) =>
    requests.get(`/questions/${page}${params}`),
  voteQuestion: (id, vote) =>
    requests.post("/vote_question", { id, vote }),
}

const Answer = {
  addAnswer: (id, parentId, content) =>
    requests.post("/answer", { id, parentId, content }),
  updateAnswer: (id, parentId, content, tags, state) =>
    requests.post("/answer", { id, parentId, content, tags, state }),
  deleteAnswer: (id) =>
    requests.del(`/answer?id=${id}`),
  voteAnswer: (id, vote) =>
    requests.post("/vote_answer", { id, vote }),
  getAnswers: (parentId) =>
    requests.get(`/answers?parentId=${parentId}`)
}

const Offer = {
  getOfferList: () =>
    requests.get("/offer-list"),
  editPurchaseForm: (id, data, sumbit) =>
    requests.post("/purchase-agreement", { id, data, sumbit }),
  getPurchaseForm: (id, options = 0) =>
    requests.get(`/purchase-agreement?id=${id}&options=${options}`),
  deleteDoc: (id) =>
    requests.del(`/purchase-agreement?id=${id}`),

  editPurchaseRequest: (id, data, sumbit) =>
    requests.post("/offer-request", { id, data, sumbit }),
  getPurchaseRequest: (id) =>
    requests.get(`/offer-request?id=${id}`),
  deletePurchaseRequest: (id) =>
    requests.del(`/offer-request?id=${id}`)
}

const File = {
  downloadPDF: (id) =>
    requests.download(`/download-pdf?id=${id}`),
}

const Payment = {
  pay: (id, amount, redirect_url) =>
    requests.post("/create-checkout-session", { id, amount, redirect_url }),
  paymentSuccess: (paymentId, id, state, product) =>
    requests.post("/payment-success", { paymentId, id, state, product }),
}

export default {
  Auth,
  Comments,
  Profile,
  Events,
  Rating,
  Vote,
  Report,
  Question,
  Answer,
  Offer,
  File,
  Payment
};
