import React, { useState } from 'react';
import agent from '../agent';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


const CheckoutButton = ({ id, amount, redirect_url, text, iconClassname, className, iconButton = false }) => {
  const handleCheckout = async () => {
    try {
      const response = await agent.Payment.pay(id, amount, redirect_url);
      window.location.href = response.url;
    } catch (error) {
      console.error('Error during checkout:', error.response || error);
      if (error.status === 401) {
        const pathWithoutHost = window.location.pathname + window.location.search;
        localStorage.setItem('prevLocation', pathWithoutHost);
        window.location.href = "/login";
      }
    }
  };

  const checkoutTooltip = <Tooltip>Checkout</Tooltip>;
  return (
    <div>
      {!iconButton && <button onClick={handleCheckout} className={className}>{text}</button>}
      {iconButton &&
        <OverlayTrigger placement="bottom" overlay={checkoutTooltip}>
          <span onClick={handleCheckout} className={iconClassname}><i className='fa fa-credit-card'></i>
          </span>
        </OverlayTrigger>}
    </div>
  );
};

export default CheckoutButton;
