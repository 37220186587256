import React from 'react';
import style from "./Badge.css";

const Badge = ({ text, styles }) => {
    return (
        <div className="badge" style={styles}>
            {text}
        </div>
    );
};

export default Badge;
