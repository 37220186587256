import React from 'react';
import states from './states.json';

const StatesSelect = props => {
  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange(e);
    }
  };

  return (
    <select id={props.id} name="state" className={props.className} onChange={handleChange} value={props.value}>
      {states.map(item => <option key={item.abbreviation} value={item.name}>{item.abbreviation}</option>)}
    </select>
  );
}


export default StatesSelect;