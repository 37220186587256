import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';

const RadioButtonGroup = observer(({ options, value, onChange, placeholder = "", type = "radio", showOther = false }) => {
    const initializeSelectedOptions = () => {
        if (type === "checkbox") {
            const filteredOptions = value.filter(opt => !opt.startsWith('other:'));
            return filteredOptions.length === value.length ? filteredOptions : [...filteredOptions, "other"];
        }
        return value.startsWith('other:') ? 'other' : value;
    };

    const initializeOtherText = () => {
        const otherOption = value.find ? value.find(opt => opt.startsWith('other:')) : value;
        return otherOption && otherOption.startsWith('other:') ? otherOption.split('other:')[1] : '';
    };

    const [selectedOptions, setSelectedOptions] = useState('');
    const [otherText, setOtherText] = useState('');

    useEffect(() => {
        setSelectedOptions(initializeSelectedOptions());
        setOtherText(initializeOtherText());
    }, [value]);

    const handleInputChange = (event) => {
        const { value, checked } = event.target;
        if (type === 'checkbox') {
            setSelectedOptions(prev => {
                const newSelectedOptions = checked
                    ? [...prev, value]
                    : prev.filter(option => option !== value);

                onChange(newSelectedOptions.map(option =>
                    option === "other" ? `other:${otherText}` : option
                ));

                return newSelectedOptions;
            });
        } else if (type === 'radio') {
            setSelectedOptions(value);
            if (value !== 'other') {
                onChange(value);
            } else {
                onChange(`other:${otherText}`);
            }

        }
    };

    const handleOtherTextChange = (event) => {
        setOtherText(event.target.value);
        if (type === "radio") {
            onChange(`other:${event.target.value}`);
        } else if (type === "checkbox") {
            onChange(selectedOptions.map(option =>
                option === "other" ? `other:${event.target.value}` : option
            ));
        }
    };

    return (
        <div className='flex-box-column'>
            {options.map((option) => (
                <label key={option.value} style={{ marginTop: "10px" }}>
                    <input
                        type={type}
                        value={option.value}
                        checked={type === "checkbox" ? selectedOptions.includes(option.value) : selectedOptions === option.value}
                        onChange={handleInputChange}
                        style={{ marginRight: "10px" }}
                    />
                    <b>{option.label}</b>
                    {option.notes && option.notes.map((note, index) => {
                        return <li key={index} style={{ "fontSize": "small", "color": "grey", "marginLeft": "10px" }}>{note}</li>
                    })}
                </label>
            ))}
            {showOther && (
                <>
                    <label style={{ marginTop: "10px" }}>
                        <input
                            type={type}
                            value="other"
                            checked={type === "checkbox" ? selectedOptions.includes("other") : selectedOptions === "other"}
                            onChange={handleInputChange}
                            style={{ marginRight: "10px" }}
                        />
                        <b>Other</b>
                    </label>
                    {((type === "checkbox" && selectedOptions.includes("other")) || (type === "radio" && selectedOptions === "other")) && (
                        <input
                            type="text"
                            value={otherText}
                            onChange={handleOtherTextChange}
                            placeholder={placeholder !== "" ? placeholder : "Please specify"}
                            className='form-input'
                        />
                    )}
                </>
            )}
        </div>
    );
});


export default RadioButtonGroup;
