import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import StatesSelect from "./StatesSelect";
import { parseJson } from "./SafeJsonParser";

import styles from "./AddressComponent.css";


const AddressComponent = observer((props) => {
	const [street, setStreet] = useState("");
	const [aptNumber, setAptNumber] = useState("");
	const [city, setCity] = useState("");
	const [zipcode, setZipCode] = useState(props.zipcode);
	const [stateOfUS, setStateOfUS] = useState("");

	useEffect(() => {
		if (props.address) {
			const addressJson = parseJson(props.address);
			if (addressJson) {
				setStreet(addressJson.street);
				setAptNumber(addressJson.apt);
				setCity(addressJson.city);
				setStateOfUS(addressJson.state);
			}
		}
	}, [props.address]);

	const handleStreeChange = e => {
		setStreet(e.target.value);
		if (props.handleAddressChange) {
			props.handleAddressChange(JSON.stringify({
				"apt": aptNumber,
				"street": e.target.value,
				"city": city,
				"state": stateOfUS
			}));
		}
	};

	const handleAptNumberChange = e => {
		setAptNumber(e.target.value);
		if (props.handleAddressChange) {
			props.handleAddressChange(JSON.stringify({
				"apt": e.target.value,
				"street": street,
				"city": city,
				"state": stateOfUS
			}));
		}
	};

	const handleZipcodeChange = e => {
		setZipCode(e.target.value);
		if (props.handleZipcodeChange) {
			props.handleZipcodeChange(e.target.value);
		}
	};

	const handleCityChange = e => {
		setCity(e.target.value);
		if (props.handleAddressChange) {
			props.handleAddressChange(JSON.stringify({
				"apt": aptNumber,
				"street": street,
				"city": e.target.value,
				"state": stateOfUS
			}));
		}
	};

	const handleStateChange = e => {
		setStateOfUS(e.target.value);
		if (props.handleAddressChange) {
			props.handleAddressChange(JSON.stringify({
				"apt": aptNumber,
				"street": street,
				"city": city,
				"state": e.target.value
			}));
		}
	};

	const twoLineLayout = (<div>
		<input type="text" className="address_input_border" placeholder={"Address"} value={street} onChange={handleStreeChange} />
		<div className="address_container_2">
			<input
				className="address_input_border"
				type="text" placeholder={"City"}
				value={city}
				onChange={handleCityChange}
				style={{ "marginRight": "4px" }}
			/>
			<input
				className="address_input_border"
				type="text" placeholder={"Apt.#"}
				value={aptNumber}
				onChange={handleAptNumberChange}
				style={{ "marginRight": "4px" }}
			/>
			<input
				className="address_input_border"
				placeholder={"zipcode"}
				value={zipcode}
				onChange={handleZipcodeChange}
				style={{ "marginRight": "4px" }}
			/>
			<StatesSelect className="address_input_border" style={{ "margin-right": "-4px" }} value={stateOfUS} onChange={handleStateChange} />
		</div>
	</div>
	);

	const oneLineLayout = (
		<div className="address_container_1">
			<div className="address_item_large">
				<label htmlFor="street_input" className="address_label">Street: </label>
				<input id="street_input" className="address_input" type="text" value={street} onChange={handleStreeChange} />
			</div>
			<div className="address_item_medium">
				<label id="city_input" className="address_label">City: </label>
				<input id="city_input" className="address_input" type="text" value={city} onChange={handleCityChange} />
			</div>
			<div className="address_item_small">
				<label id="apt_input" className="address_label">Apt.: </label>
				<input id="apt_input" className="address_input" type="text" value={aptNumber} onChange={handleAptNumberChange} />
			</div>
			<div className="address_item_small">
				<label id="zipcode_input" className="address_label">Zip: </label>
				<input id="zipcode_input" className="address_input" type="text" value={zipcode} onChange={handleZipcodeChange} />
			</div>
			<div className="address_item_x_small">
				<label key="street">
					<StatesSelect className="address_input" value={stateOfUS} onChange={handleStateChange} />
				</label>
			</div>
		</div>
	);

	return (
		<>
			{props.oneLineLayout && oneLineLayout}
			{!props.oneLineLayout && twoLineLayout}
		</>
	)
});

export default AddressComponent;