export function TimezoneConverter(utcTime) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timezone) {
        const utcTimestamp = Date.parse(utcTime);
        const localTimestamp = utcTimestamp + new Date().getTimezoneOffset() * 60 * 1000;
        return new Date(localTimestamp).toLocaleString(undefined, { timeZone: timezone });
    }
    return utcTime;
}

export function timeAgo(timestamp) {
    const date = new Date(timestamp);
    const now = new Date();
    const seconds = Math.floor((now - date) / 1000);
    const interval = Math.floor(seconds / 31536000);

    if (interval >= 1) {
        return interval + " year" + (interval === 1 ? "" : "s") + " ago";
    }
    const days = Math.floor(seconds / 86400);
    if (days >= 1) {
        return days + " day" + (days === 1 ? "" : "s") + " ago";
    }
    const hours = Math.floor(seconds / 3600);
    if (hours >= 1) {
        return hours + " hour" + (hours === 1 ? "" : "s") + " ago";
    }
    const minutes = Math.floor(seconds / 60);
    if (minutes >= 1) {
        return minutes + " minute" + (minutes === 1 ? "" : "s") + " ago";
    }
    return "Just now";
}

//const timestamp = Date.parse("2022-05-05T10:00:00Z");
