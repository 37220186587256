import React, { useEffect, useState } from 'react';
import { inject, observer } from "mobx-react";
import TopNav from '../LandingPage/TopNav/TopNav';
import AddressComponent from '../shared/AddressComponent';
import ComboBox from "../shared/Combobox";
import PreviewPage from "./ProfilePreview";
import { profileOptions } from "../shared/ProfileOptions";
import profile1 from '../assets/profile1.jpg';
import profile2 from '../assets/profile2.jpg';
import profile3 from '../assets/profile3.jpg';
import ImageSequence from "../shared/ImageSequence";
import { API_ROOT, MAX_FILE_SIZE } from "../shared/Constant";
import { handleError } from '../shared/ErrorHandler';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { modules, formats } from '../shared/QuillEditorConfig';

import style from "./Profile.css";
import LoadingPage from '../shared/LoadingPage';


const Profile = inject("profileStore", "userStore")(observer((props) => {
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		let cancel = false;
		if (!props.userStore.currentUser) {
			props.userStore.getLoginStatus();
		}
		props.profileStore.getPersonalProfile().then((
		) => {
			setIsLoading(false);
			if (cancel) return;
		}).catch((error) => {
			setIsLoading(false);
			handleError(error, props.history, props.userStore.getLoginStatus);
		});
		return () => {
			cancel = true;
		};
	}, []);

	return (
		<div className="page">
			<TopNav isLoggedIn={!!props.userStore.currentUser} />
			{!isLoading && !props.profileStore.personalProfile && <IntroductionPage />}
			{!isLoading && props.profileStore.personalProfile && <div className='flex-box-row' style={{ "overflow": "scroll" }}>
				<FormPage />
				<div className='profile-right-pane'>
					<PreviewPage profile={props.profileStore.personalProfile} />
				</div>
			</div>}
			{isLoading && <LoadingPage />}
		</div >
	);

}));

const IntroductionPage = inject("profileStore")(observer((props) => {
	const createProfile = e => {
		props.profileStore.savePersonalProfile({ id: "create" }).then().catch((error) => {
			handleError(error, props.history);
		});
	}

	const images = [
		profile2,
		profile3,
		// Add more image URLs as needed
	];

	return (
		<div className='introduction-page'>
			<div className='introduction-container'>
				<img src={profile1} className="create-profile-image" alt="profile" />
				<div className='create-profile-container'>
					<div className='flex-box-row'>
						<span className="create-profile-icon"><i className="fas fa-chevron-right"></i></span>
						<p className='create-profile-text'>It is free to create and share your business page</p>
					</div>
					<div className='flex-box-row'>
						<span className="create-profile-icon"><i className="fas fa-chevron-right"></i></span>
						<p className='create-profile-text'>Help all business persons build your social network</p>
					</div>
					<div className='flex-box-row'>
						<span className="create-profile-icon"><i className="fas fa-chevron-right"></i></span>
						<p className='create-profile-text'>Help new realtors locate resources</p>
					</div>
					<div className='flex-box-row'>
						<span className="create-profile-icon"><i className="fas fa-chevron-right"></i></span>
						<p className='create-profile-text'>Real Estate Businesses we support: </p>
					</div>
					<div className='flex-box-row'>
						<ul className='list-type'>
							<li>Real Estate Broker</li>
							<li>Real Estate Agent</li>
							<li>Real Estate Appraiser</li>
							<li>Real Estate Inspector</li>
							<li>Landscaper</li>
							<li>Home Stager</li>
						</ul>
						<ul className='list-type'>
							<li>Real Estate Photographer</li>
							<li>Flip Investor</li>
							<li>Real Estate Developers</li>
							<li>Cleaning Service Provider</li>
							<li>Repair and remodel Service Provider</li>
							<li>Moving Service Provider</li>
						</ul>
					</div>
					<button onClick={createProfile} className="create-profile-button">Create profile</button>
				</div>
			</div>
			<div className='introduction-container'>
				<div className='create-profile-container' style={{ "paddingLeft": "0px" }}>
					<div className='flex-box-row'>
						<span className="create-profile-icon"><i className="fas fa-chevron-right"></i></span>
						<p className='create-profile-text'>Post event when your business profile verified</p>
					</div>
					<div className='flex-box-row'>
						<span className="create-profile-icon"><i className="fas fa-chevron-right"></i></span>
						<p className='create-profile-text'>Organize online or in-person event to build your social network</p>
					</div>
					<div className='flex-box-row'>
						<span className="create-profile-icon"><i className="fas fa-chevron-right"></i></span>
						<p className='create-profile-text'>Oragnize events like introducing your business or just have fun together</p>
					</div>
				</div>
				<ImageSequence images={images} interval={2000} className="create-profile-image" />
			</div>
		</div>
	);
}));

const FormPage = inject("profileStore")(observer((props) => {
	const [disableSave, setDisableSave] = useState(true);

	const handleSave = e => {
		if (props.profileStore.personalProfile.photoArray) {
			props.profileStore.personalProfile.photoArray.filter((photo) => {
				return photo.isActive === true;
			});
		}
		setDisableSave(true);
		props.profileStore.personalProfile.photoArray = props.profileStore.personalProfile.photoArray ?
			props.profileStore.personalProfile.photoArray : [];
		props.profileStore.savePersonalProfile(props.profileStore.personalProfile).then(() => {
		}).catch((error) => {
			setDisableSave(false);
			handleError(error, props.history);
		});
	};

	const handleCancel = e => {
		props.profileStore.resetPersonalProfile();
		setDisableSave(true);
	};

	const handleNameChange = e => {
		props.profileStore.personalProfile.name = e.target.value;
		setDisableSave(false);
	};

	const handlePhotoChange = e => {
		if (e.target.files[0] > MAX_FILE_SIZE) {
			return;
		}
		props.profileStore.uploadImage(e.target.files[0])
			.then((response) => {
				props.profileStore.personalProfile.photo = `${response.photoPath}`;
			});
		setDisableSave(false);
	}

	const handlePhoneChange = e => {
		props.profileStore.personalProfile.phone = e.target.value;
		setDisableSave(false);
	};

	const handleAddressChange = address => {
		props.profileStore.personalProfile.address = address;
		setDisableSave(false);
	};

	const handleZipcodeChange = zipcode => {
		props.profileStore.personalProfile.zipcode = zipcode;
		setDisableSave(false);
	};

	const handleEmailChange = e => {
		props.profileStore.personalProfile.email = e.target.value;
		setDisableSave(false);
	};

	const handleBusinessChange = business => {
		props.profileStore.personalProfile.business = business;
		setDisableSave(false);
	};

	const handleDescriptionChange = value => {
		props.profileStore.personalProfile.description = value;
		setDisableSave(false);
	};

	const handleLicenseIdChange = e => {
		props.profileStore.personalProfile.licenseId = e.target.value;
		setDisableSave(false);
	};

	const handleServingAreasChange = e => {
		props.profileStore.personalProfile.servingAreas = e.target.value;
		setDisableSave(false);
	};

	const handleLanguagesChange = e => {
		props.profileStore.personalProfile.languages = e.target.value;
		setDisableSave(false);
	};

	const handleSkillsChange = e => {
		props.profileStore.personalProfile.skills = e.target.value;
		setDisableSave(false);
	};

	const handleCompanyChange = e => {
		props.profileStore.personalProfile.company = e.target.value;
		setDisableSave(false);
	};

	const handlePhotosChange = photos => {
		props.profileStore.personalProfile.photoArray = photos;
		setDisableSave(false);
	}

	const uploadImage = image => {
		return props.profileStore.uploadImage(image);
	}

	const handleSocialMediaChange = e => {
		setDisableSave(false);
		props.profileStore.personalProfile.socialMediaArray = [e.target.value];
	}

	const handleFlatRateChange = e => {
		setDisableSave(false);
		props.profileStore.personalProfile.flatRate = !props.profileStore.personalProfile.flatRate;
	}

	if (!props.profileStore.personalProfile) {
		return null;
	}

	const disabled = Number(props.profileStore.personalProfile.status) > 0 ? true : false;
	const photoPath = props.profileStore.personalProfile.photo ? `${API_ROOT}${props.profileStore.personalProfile.photo}` : "";
	const isRealtor = props.profileStore.personalProfile.business === "Agent" || props.profileStore.personalProfile.business === "Broker" ? true : false;

	return (
		<div className="profile-left-pane">
			<div className="personal-profile-container">
				<div className="flex-box-row profile">
					<label>Name: </label>
					<input
						id="name"
						className="profile-input"
						value={props.profileStore.personalProfile.name}
						onChange={handleNameChange}
						disabled={disabled}
					/>
				</div>
				<div className="flex-box-row profile">
					<label>Business: </label>
					<ComboBox
						className="custom-combo-box"
						options={profileOptions}
						onChange={handleBusinessChange}
						value={props.profileStore.personalProfile.business}
						disabled={disabled}
						placeholder={"Add your business if it's not in the list"}
					/>
					{isRealtor && <label style={{ "marginLeft": "auto" }}><input
						type="checkbox"
						checked={props.profileStore.personalProfile.flatRate}
						onChange={handleFlatRateChange} /> Accept flat rate
					</label>
					}
				</div>
				<div className="flex-box-row profile">
					<label>License: </label>
					<input
						id="license_id"
						className="profile-input"
						value={props.profileStore.personalProfile.licenseId}
						onChange={handleLicenseIdChange}
						disabled={disabled}
					/>
					{disabled && <div style={{ "fontSize": "10px", "color": "#bd1f1f", "width": "120px" }}>{getStatus(props.profileStore.personalProfile.status)}</div>}
				</div>
				<div className="flex-box-row profile">
					<label>Photo: </label>
					<label className="flex-box-row" htmlFor="fileInput">
						<div className="add-icon flex-box-row" style={{ "margin": "10px" }}>
							<span><i className="fa fa-plus"></i></span>
							<div style={{ "marginLeft": "10px" }}>Image</div>
						</div>
					</label>
					<input id="fileInput" type="file" accept="image/*" onChange={handlePhotoChange} />
					<img className="thumbnail-image" src={photoPath} alt="profile_thumbnail" />
				</div>
				<div className="flex-box-row profile">
					<label>Phone: </label>
					<input id="phone" className="profile-input" placeholder="(XXX)XXX-XXXX" value={props.profileStore.personalProfile.phone} onChange={handlePhoneChange} />
				</div>
				<div className="flex-box-row profile">
					<label>Email: </label>
					<input id="email" className="profile-input" value={props.profileStore.personalProfile.email} onChange={handleEmailChange} />
				</div>
				<div className="flex-box-row profile">
					<label>Website: </label>
					<input
						id="website"
						className="profile-input"
						value={props.profileStore.personalProfile.socialMediaArray.length > 0 ? props.profileStore.personalProfile.socialMediaArray[0] : ""}
						onChange={handleSocialMediaChange}
					/>
				</div>
				<div className="flex-box-row profile">
					<AddressComponent
						handleAddressChange={handleAddressChange}
						handleZipcodeChange={handleZipcodeChange}
						address={props.profileStore.personalProfile.address}
						zipcode={props.profileStore.personalProfile.zipcode}
						oneLineLayout={true} />
				</div>
				<div className="flex-box-row profile">
					<label>Company: </label>
					<input id="company" className="profile-input" value={props.profileStore.personalProfile.company} onChange={handleCompanyChange} />
				</div>
				<div className="flex-box-row profile">
					<label>Skills: </label>
					<input id="skills" className="profile-input" value={props.profileStore.personalProfile.skills} onChange={handleSkillsChange} />
				</div>
				<div className="flex-box-row profile">
					<label>Serving Areas: </label>
					<input id="serving" className="profile-input" value={props.profileStore.personalProfile.servingAreas} onChange={handleServingAreasChange} />
				</div>
				<div className="flex-box-row profile">
					<label>Languages: </label>
					<input id="languages" className="profile-input" value={props.profileStore.personalProfile.languages} onChange={handleLanguagesChange} />
				</div>
				<div className="flex-box-row profile">
					<label style={{ "alignSelf": "flex-start", "marginTop": "8px" }}>Photos: </label>
					<PhotoComponent
						photos={props.profileStore.personalProfile.photoArray}
						onChange={handlePhotosChange}
						uploadImage={uploadImage} />
				</div>
				<div className="flex-box-row profile">
					<label style={{ "alignSelf": "flex-start" }}>Description: </label>
					<ReactQuill
						id="Description"
						className="profile-textarea"
						placeholder='Introduce yourself...'
						value={props.profileStore.personalProfile.description}
						onChange={handleDescriptionChange}
						modules={modules}
						formats={formats}
					/>
				</div>
				<div className="flex-box-row profile-button-container">
					<button className="profile-button" onClick={handleSave} disabled={disableSave}>Save</button>
					<button className="profile-button" onClick={handleCancel} disabled={disableSave}>Cancel</button>
				</div>
				<ProfileTips />
			</div>
		</div>
	);
}));

const ProfileTips = () => {
	return (
		<div className='ProfileTips'>
			<h2>Tips</h2>
			<div>1. Enter the correct business type, business license and your name, which will be used to check your business profile.</div>
			<div>2. Once you enter your business type, business license and your name, you can't change them. A status will indicate whether the profile has been verified or not.</div>
			<div>3. If it is not possible to verify your business profile, it will be open for modification.</div>
			<div>4. If the business type is not in the list, you could input it and propose one instead.We will extend the business list soon.</div>
		</div>
	)
}

const PhotoComponent = observer((props) => {
	const [status, setStatus] = useState("image");
	const [photos, setPhotos] = useState([]);
	const [file, setFile] = useState(null);
	const [imageCount, setImageCount] = useState(0);
	const [errorMesage, setErrorMessage] = useState("");
	const MAX_IMAGES = 6;

	useEffect(() => {
		setPhotos(props.photos);
		setImageCount(props.photos.length);
	}, [props.photos]);

	const handleImageChange = e => {
		setErrorMessage("");
		if (e.target.files[0].size > MAX_FILE_SIZE) {
			setErrorMessage("File exceed 200KB");
			return;
		}
		const lastPhotoId = photos && photos.length > 0 ? photos[photos.length - 1].id : -1;
		const tempPhoto = photos ?? [];
		tempPhoto.push({ id: lastPhotoId + 1, image: URL.createObjectURL(e.target.files[0]), link: "", isActive: false })
		setPhotos(tempPhoto);
		setStatus("upload");
		if (props.onChange) {
			props.onChange(photos);
		}
		setFile(e.target.files[0]);
	}

	const handleHyperLinkChange = (value, id) => {
		setErrorMessage("");
		setPhotos(prevPhotos => prevPhotos.map(photo => {
			if (photo.id === id) {
				photo.link = value;
			}
			return photo;
		}));
		if (props.onChange) {
			props.onChange(photos);
		}
	}

	const deletePhoto = id => {
		setErrorMessage("");
		const tempPhoto = photos.filter((photo) => {
			return photo.id !== id;
		});
		setPhotos(tempPhoto);
		if (props.onChange) {
			props.onChange(tempPhoto);
		}
		setStatus("image");
		setFile(null);
		setImageCount(imageCount - 1);
	}

	const uploadImage = e => {
		if (props.uploadImage) {
			props.uploadImage(file)
				.then((response) => {
					const tempPhoto = photos;
					tempPhoto[tempPhoto.length - 1].image = response.photoPath;
					tempPhoto[tempPhoto.length - 1].isActive = true;
					setPhotos(tempPhoto);
					if (props.onChange) {
						props.onChange(photos);
					}
					setImageCount(imageCount + 1);
				}).catch(() => {
					setErrorMessage("Upload image failed. Please try again");
				});
			setStatus("image");
		}
	}

	const photosComp = photos ? photos.map((photo) => {
		return <div className='photo-container' key={photo.id}>
			<div className='flex-box-row'>
				<img alt="photo_thumbnail"
					src={photo.isActive ? `${API_ROOT}${photo.image}` : photo.image}
					className="thumbnail-image"
				/>
				<input
					style={{ "width": "80%", "marginLeft": "16px" }}
					type="text"
					name="hyperlink"
					placeholder="Hyper link"
					onChange={(e) => handleHyperLinkChange(e.target.value, photo.id)}
					value={photo.link}
				/>
			</div>
			<span onClick={() => { deletePhoto(photo.id) }}><i className="fas fa-trash"></i></span>
		</div>
	}) : null;


	return <div className='photos-container'>
		{photosComp}
		<div className='flex-box-row'>
			{(imageCount < MAX_IMAGES) && status === "image" && <>
				<label htmlFor="photo">
					<div className="add-icon">
						<span><i className="fa fa-plus"></i> Image </span>
					</div>
				</label>
				<input id="photo" type="file" accept="image/*" onChange={handleImageChange} />
			</>}
			{status === "upload" && <div className='flex-box-row'>
				<button onClick={uploadImage}>Upload new added image</button>
			</div>}
			<div className='error-message'>{errorMesage}</div>
		</div>
	</div>;
});


function getStatus(status) {
	if (status === "-1") {
		return "Input your info and resumit for verification"
	}
	else if (status === "1") {
		return "It will take several days to validate your info."
	} else if (status === "2") {
		return "Verified"
	} else {
		return ""
	}
}

export default Profile;