import React, { useEffect, useState } from 'react';
import { inject, observer } from "mobx-react";
import defautPhoto from '../assets/default-photo.jpg';
import RatingComponent from "../shared/RatingComponent";
import CommentsComponent from "../comment/CommentsComponent";
import SharedInfoComponent from "../shared/SharedInfoComponent";
import { Link } from "react-router-dom";
import { generateAddressString } from "../shared/GenerateAddressString";
import { HOST_NAME, API_ROOT } from "../shared/Constant";
import Badge from "../shared/Badge";
import ReCAPTCHA from "react-google-recaptcha";


import style from "./ProfilePreview.css";


const PreviewPage = inject("profileStore", "userStore")(observer((props) => {
    const [isCollapse, setIsCollapse] = useState(true);
    const [following, setFollowing] = useState(null);
    const [followerCount, setFollowerCount] = useState(null);

    useEffect(() => {
        if (props.following !== null && props.following !== undefined) {
            setFollowing(props.following);
        }
        if (props.profile.followerCount !== null && props.profile.followerCount !== undefined) {
            setFollowerCount(Number(props.profile.followerCount));
        }
    }, [props.following, props.profile]);

    const expandPhoto = e => {
        setIsCollapse(false);
    }

    const collapsePhoto = e => {
        setIsCollapse(true);
    }

    const handleFollowChange = e => {
        props.profileStore.changeFollowStates(props.profile.userId).then(() => {
            if (following !== null) {
                setFollowing(!following);
                const offset = following === true ? -1 : 1;
                setFollowerCount(followerCount + offset);
            }
        });
    }

    if (!props.profile) {
        return null;
    }

    const photoPath = !!props.profile.photo && props.profile.photo !== "" ? `${API_ROOT}${props.profile.photo}` : defautPhoto;
    const businessAndLicense = [props.profile.business, props.profile.licenseId].filter(str => str !== "");
    const businessAndLicenseStr = businessAndLicense.join(": ");

    const contactCard = (<>< h2 > Contact detail:</h2>
        <div className='flex-box-row'>
            <span className='contact-icon'><i className="fa fa-mobile"></i></span>
            <p className='contact-text'>{props.profile.phone}</p>
        </div>
        <div className='flex-box-row'>
            <span className='contact-icon'><i className="fas fa-location-arrow"></i></span>
            <div className='contact-text'>{generateAddressString(props.profile.address, props.profile.zipcode)}</div>
        </div>
        <div className='flex-box-row'>
            <span className='contact-icon'><i className="fa fa-link"></i></span>
            <p className='contact-text'>{props.profile.socialMediaArray.length > 0 ? props.profile.socialMediaArray[0] : ""}</p>
        </div>
        <SharedInfoComponent url={`${HOST_NAME}/profile/${props.profile.shortUrl}`} />
    </>
    );

    const followButtonLabel = following === true ? "Unfollow" : "Follow";
    return (
        <div className='profile-preview-container'>
            <div className='profile-preview-left'>
                <div className='changable-row-container' style={{ "marginBottom": "20px" }}>
                    <img src={photoPath} className="photo" alt="Personal" />
                    <div className='photo-text'>
                        <Link to={`/profile/${props.profile.shortUrl}`}>{props.profile.name}</Link>
                        <p>{businessAndLicenseStr}</p>
                        <p>{props.profile.company}</p>
                        <RatingComponent isEditable={false} value={Number(props.profile.rating)} />
                        <div>
                            {Number(props.profile.status) > 1 && <Badge text={"Verified"} />}
                            {props.profile.flatRate && <Badge text={"Flat Rate"} />}
                        </div>
                        <div className='flex-box-row'>
                            {followerCount !== null && <div style={{ "marginRight": "10px" }}>Follower: {followerCount}</div>}
                            {following !== null && <button className='badge' onClick={handleFollowChange}>{followButtonLabel}</button>}
                        </div>
                    </div>
                </div>
                <div dangerouslySetInnerHTML={{ __html: props.profile.description }}></div>
                <hr />
                <div className="small-view">
                    <div>{contactCard}</div>
                    <hr />
                </div>
                <div>Serving area: {props.profile.servingAreas}</div>
                <hr />
                <div>Skills: {props.profile.skills}</div>
                <hr />
                <div>Serving languages: {props.profile.languages}</div>
                <hr />
                <div>
                    <div className='photo-title'>
                        <div>Photos: </div>
                        {isCollapse && <span className='icon' onClick={expandPhoto}><i className="fa fa-angle-down"></i></span>}
                        {!isCollapse && <span className='icon' onClick={collapsePhoto}><i className="fa fa-angle-up"></i></span>}
                    </div>
                    {!isCollapse && <PhotoPreviewComponent photos={props.profile.photoArray} showComments={props.showComments} />}
                </div>
                <hr />
                <div>
                    {props.userStore.currentUser && <div className='flex-box-row'>
                        <div style={{ "alignSelf": "center", "marginRight": "10px" }}>Add your rating:</div>
                        <RatingComponent targetId={props.profile.id} targetType={"profile"} isEditable={true} />
                    </div>}
                    {!props.showComments && <div>Comment...</div>}
                    {props.showComments && <CommentsComponent targetId={props.profile.id} targetType={"profile"} />}
                </div>
            </div>
            <div className='profile-preview-right'>
                <div className='contact-container'>
                    {contactCard}
                </div>
                <SendEmailForInquiryComponent toEmail={props.profile.email} disabled={!props.showComments} />
            </div>
        </div>);
}));


const PhotoPreviewComponent = observer((props) => {
    const photos = props.photos && props.photos.map((photo) => {
        return (
            <a href={`http://${photo.link}`} key={photo.id}>
                <img src={photo.isActive ? `${API_ROOT}${photo.image}` : photo.image} className="photo-image" alt="photo" />
            </a>
        );
    });
    return <div>{photos}</div>;
});

const SendEmailForInquiryComponent = inject("userStore")(observer((props) => {
    const [name, setName] = useState("");
    const [posting, setPosting] = useState(false);
    const [message, setMessage] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [disableSave, setDisableSave] = useState(true);
    const [isVerified, setIsVerified] = useState(false);

    const handleNameChange = e => {
        setName(e.target.value);
        setDisableSave(false);
    };

    const handleEmailChange = e => {
        setEmail(e.target.value);
        setDisableSave(false);
    };

    const handlePhoneChange = e => {
        setPhone(e.target.value);
        setDisableSave(false);
    }

    const handleMessageChange = e => {
        setMessage(e.target.value);
        setDisableSave(false);
    }

    const postEvent = e => {
        setDisableSave(true);
        props.userStore.sendInquiryEmail(name, email, message, phone, props.toEmail).then(() => {
            setPosting(false);
            setName("");
            setMessage("");
            setEmail("");
            setPhone("");
        }).catch((error) => {
            setErrorMessage("Post event error");
            setDisableSave(false);
        });
    };

    const handleCaptchaChange = (value) => {
        setIsVerified(!!value);
    };

    return (
        <div className="contact-container">
            <input
                className="contact-input"
                type="text"
                placeholder="Enter your name"
                value={name}
                onChange={handleNameChange}
            />
            <input
                className="contact-input"
                type="text"
                placeholder="Enter your email"
                value={email}
                onChange={handleEmailChange}
            />
            <input
                className="contact-input"
                type="text"
                placeholder="Enter your phone"
                value={phone}
                onChange={handlePhoneChange}
            />
            <textarea
                className="contact-textarea"
                type="text"
                placeholder="Message"
                value={message}
                onChange={handleMessageChange}
            />
            {!props.disabled && <ReCAPTCHA
                sitekey="6LdVU5YmAAAAAL-ybQCUKMeNbpt9R9LAcepevIJL"
                onChange={handleCaptchaChange}
            />}
            <button className="contact-button" type="submit" onClick={postEvent} disabled={disableSave || !isVerified || props.disabled}>
                {!posting && <div>Don't hesitate ping me</div>}
            </button>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
        </div>
    );
}));

export default PreviewPage;

/*<div className='flex-box-row'>
     <span className='contact-icon'><i className="fa fa-envelope"></i></span>
     <p className='contact-text'>{props.profile.email}</p>
 </div>*/