import React, { useState } from "react";
import { inject, observer } from "mobx-react";

import styles from "./SharedInfoComponent.css";


const SharedInfoComponent = inject("userStore")(observer((props) => {
    const [enableSendEmail, setEnableSendEmail] = useState(false);
    const [email, setEmail] = useState("");

    const shareOnTwitter = e => {
        const sharedUrl = `https://twitter.com/intent/tweet?url=${props.url}`;
        window.open(sharedUrl);
    };

    const shareOnFacebook = e => {
        const sharedUrl = `https://www.facebook.com/sharer/sharer.php?u=${props.url}`;
        window.open(sharedUrl);
    };

    const shareViaEmail = e => {
        setEnableSendEmail(!enableSendEmail);
    };

    const sendEmail = e => {
        setEnableSendEmail(false);
        props.userStore.shareViaEmail(email, props.url);

    }

    const inputEmail = e => {
        setEmail(e.target.value);
    }

    return (
        <div>
            <div className="shared-info-container">
                <div className="shared-info-text">Share profile: </div>
                <span className="twitter-icon" onClick={shareOnTwitter}><i className="fab fa-twitter"></i></span>
                <span className="facebook-icon" onClick={shareOnFacebook}><i className="fab fa-facebook"></i></span>
                <span className="email-icon" onClick={shareViaEmail}><i className="far fa-envelope-open"></i></span>
            </div>
            {enableSendEmail && <div className="send-email-container">
                <input className="send-email-input" placeholder="email" onChange={inputEmail} />
                <button className="send-email-button" onClick={sendEmail}>Send</button>
            </div>}
        </div>
    )
}));

export default SharedInfoComponent;