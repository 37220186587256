import React, { useEffect, useState } from 'react';
import { inject, observer } from "mobx-react";
import ReactStars from "react-rating-stars-component";

import style from "./RatingComponent.css";


const RatingComponent = inject("ratingStore")(observer((props) => {
    const [value, setValue] = useState(null);

    useEffect(() => {
        if (props.isEditable === true) {
            props.ratingStore.getRating(props.targetId, props.targetType)
                .then((response) => {
                    const rating = Number(response.rating);
                    setValue(rating);
                });
        } else {
            if (props.value) {
                setValue(props.value);
            }
        }
    }, [props.value, props.isEditable]);

    const ratingChange = rating => {
        props.ratingStore.postRating(props.targetId, props.targetType, rating)
            .then(() => {
                setValue(rating);
                if (props.onChange) {
                    props.onChange(rating);
                }
            });
    };

    return <div>
        {value && <ReactStars
            count={5}
            size={24}
            activeColor="#ffd700"
            isHalf={true}
            edit={props.isEditable}
            onChange={ratingChange}
            value={value}
        />}
    </div>;

}));


export default RatingComponent;
