const reportOptions = 
[
	"Threat to personal safety",
	"Cheating or hacking",
	"Inappropriate or abusive",
	"Violating website rules or guidelines",
	"Others"
];

export default reportOptions;
