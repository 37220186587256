import React, { useState, useRef, useEffect } from 'react';
import AddressComponent from "./AddressComponent2";
import RadioButtonGroup from './RadioButtonGroup';
import agent from '../agent';
import { purchaseDoc, booleanOptions, requestFieldOfPurchaseDoc, validationMethods } from '../shared/PurchaseForm';
import { useLocation } from 'react-router-dom';

import style from "./MultiStepForm.css";
import 'react-datepicker/dist/react-datepicker.css';
import { inject, observer } from 'mobx-react';
import AuthorForm from './AuthorForm';
import OverlayReminder from "../shared/OverlayReminder";

const PropertyPage = ({ formData, formChangeHandler, errors }) => {
  const radioOptions = [
    { value: 'singlefamily', label: "Single-Family Home" },
    { value: 'condo', label: "Condominium" },
    { value: 'townhouse', label: "Townhouse" },
    { value: 'pud', label: "Planned Unit Development(PUD)" },
  ];

  return <div className="step">
    <label className='form-title'>Property Info</label>
    <div>
      <div name="type" className='category-title'>Property type {errors && errors.type && <label className='error-message-form '>{errors.type}</label>}</div>
      <RadioButtonGroup
        id="propery-key"
        key="property-type"
        options={radioOptions}
        showOther={true}
        value={formData.type}
        onChange={(value) => formChangeHandler("type", value)}
      />
      <div name="includeLand" className='category-title'>Does the property include land? {errors && errors.includeLand && <label className='error-message-form '>{errors.includeLand}</label>}</div>
      <RadioButtonGroup
        id="include-land"
        key="include-land"
        options={booleanOptions}
        value={formData.includeLand}
        onChange={(value) => formChangeHandler("includeLand", value)}
      />
      <div className='category-title'>Property located at</div>
      <AddressComponent
        onChange={(value) => formChangeHandler("address", value)}
        value={formData.address}
        errors={errors?.address}
      />
      <div className='category-title'>Legal Description (Optional)</div>
      <textarea
        name="legal-description"
        className='form-input'
        style={{ "width": '100%', "height": "100px" }}
        value={formData.legalDescription}
        placeholder='Lot 42, Block 3, of North Lakes Subdivision #1, according to map or plat thereof as recorded in Plat Book 62, Page 9, of the Public Records of Orange County, Florida.'
        onChange={(value) => formChangeHandler("legalDescription", value)}
      />
      <div className='category-title'>Parcel Number (Optional)</div>
      <input
        name="parcel-number"
        className='form-input'
        value={formData.parcelNumber}
        placeholder='e.g. 200-212-0000'
        onChange={(value) => formChangeHandler("parcelNumber", value)}
      />
    </div>
  </div>
};

const BuyerInfo = ({ formData, formChangeHandler, errors }) => {
  return <div className="step">
    <label className='form-title'>Buyers' Infomation</label>
    <AuthorForm
      label={"Buyer"}
      value={formData}
      onChange={formChangeHandler}
      errors={errors}
    />
  </div>
};

const SellerInfo = ({ formData, formChangeHandler, errors }) => {
  return <div className="step">
    <label className='form-title'>Sellers' Infomation</label>
    <AuthorForm
      label={"Seller"}
      value={formData}
      onChange={formChangeHandler}
      errors={errors}
      skip={true}
    />
  </div>
};

const Purchase = ({ formData, formChangeHandler, errors }) => {
  const purchaseOptions = [
    { value: 'allcash', label: "All cash" },
    { value: 'financing', label: "Financing" },
  ];
  const typeOfFinancing = [
    { value: 'conventional', label: "Conventional Loan (Mortgage)" },
    { value: 'fha', label: "FHA Loan" },
    { value: 'va', label: "VA Loan" },
    { value: 'seller', label: "Seller Financing" },
  ];

  const downPaymentChange = (e) => {
    formChangeHandler("downPayment", e.target.value)
    formChangeHandler("loanAmount", Number(formData.price) - Number(e.target.value))
  }

  const priceChange = (e) => {
    formChangeHandler("price", e.target.value)
    formChangeHandler("loanAmount", Number(e.target.value) - Number(formData.downPayment))
  }


  return <div className="step">
    <label className='form-title '>Purchase</label>
    <label className='category-title'>Purchase Info</label>
    <div className='flex-box-row' style={{ "alignItems": "center" }}>
      <label style={{ "marginRight": "10px" }}>Purchase price: </label>
      <label style={{ "marginRight": "10px" }}>$</label>
      <input
        type="number"
        className="form-input"
        style={{ "marginRight": "10px" }}
        placeholder='300,000'
        value={formData.price}
        onChange={priceChange}
      />
      <input
        className="form-input"
        style={{ "marginRight": "10px", "minWidth": "350px" }}
        placeholder='Three hundred thousand'
        value={formData.priceInWords}
        onChange={(e) => formChangeHandler("priceInWords", e.target.value)}
      />
      <label>Dollars</label>
    </div>
    {errors && errors.price && <label className='error-message-form '>{errors.price}</label>}
    {errors && errors.priceInWords && <label className='error-message-form '>{errors.priceInWords}</label>}
    <label className='category-title'>Purchase method</label>
    <RadioButtonGroup
      options={purchaseOptions}
      value={formData.type}
      onChange={(value) => formChangeHandler("type", value)}
    />
    {errors && errors.type && <label className='error-message-form '>{errors.type}</label>}
    {formData.type === "financing" && <><label className='category-title'>Down payment amount</label>
      <div className='flex-box-row' style={{ "alignItems": "center" }}>
        <label style={{ "marginRight": "10px" }}>$</label>
        <input
          type="number"
          className="form-input"
          placeholder='60,000'
          value={formData.downPayment}
          onChange={downPaymentChange}
        />
        {formData.downPayment !== "" && <div style={{ "marginLeft": "10px" }}>Loan Amount: ${formData.loanAmount}</div>}
      </div>
      {errors && errors.downPayment && <label className='error-message-form '>{errors.downPayment}</label>}
      <div>
        <label className='category-title '>Type of Financing</label>
        <RadioButtonGroup
          options={typeOfFinancing}
          value={formData.loanType}
          onChange={(value) => formChangeHandler("loanType", value)}
          showOther={true}
        />
        {errors && errors.loanType && <label className='error-message-form '>{errors.loanType}</label>}
      </div>
      <label className='category-title '>Loan application</label>
      <div>
        Buyer need submit loan application within
        <input
          type="number"
          className="form-input"
          style={{ "marginRight": "10px", "marginLeft": "10px", width: '80px' }}
          placeholder='5'
          value={formData.loanApplicationDay}
          onChange={(e) => formChangeHandler("loanApplicationDay", e.target.value)}
        /> Days after this agreement acceptance.
      </div>
      {errors && errors.loanApplicationDay && <label className='error-message-form '>{errors.loanApplicationDay}</label>}
      {formData.loanType === "seller" && <div>
        <label className='category-title '>Seller's Financing</label>
        <div>
          Interest rate yearly:
          <input
            type="number"
            className="form-input"
            style={{ "marginRight": "10px", "marginLeft": "10px", width: '80px' }}
            placeholder='6'
            value={formData.loanFixedRate}
            onChange={(e) => formChangeHandler("loanFixedRate", e.target.value)}
          /> %
        </div>
        {errors && errors.loanFixedRate && <label className='error-message-form '>{errors.loanFixedRate}</label>}
        <div>
          Loan length:
          <input
            type="number"
            className="form-input"
            style={{ "marginRight": "10px", "marginLeft": "10px", width: '80px' }}
            placeholder='360'
            value={formData.lengthOfLoan}
            onChange={(e) => formChangeHandler("lengthOfLoan", e.target.value)}
          /> Months
        </div>
        {errors && errors.lengthOfLoan && <label className='error-message-form '>{errors.lengthOfLoan}</label>}
      </div>}
    </>}
    <div className='category-title '>Time to show funding evidence</div>
    <div>
      Buyer shall show Seller funding evidence within
      <input
        type="number"
        className="form-input"
        style={{ "marginRight": "10px", "marginLeft": "10px", width: '80px' }}
        placeholder='3'
        value={formData.dayOfVerificationFund}
        onChange={(e) => formChangeHandler("dayOfVerificationFund", e.target.value)}
      /> Days after this agreement acceptance.
    </div>
    {errors && errors.dayOfVerificationFund && <label className='error-message-form '>{errors.dayOfVerificationFund}</label>}
  </div>
};

const Deposit = ({ formData, formChangeHandler, errors }) => {
  const methods = [
    { value: 'cashier', label: "Cashier\'s check" },
    { value: 'personal', label: "Personal check" },
    { value: 'wire', label: "Wire transfer" },
  ];

  return <div className="step">
    <label className='form-title'>Deposit</label>
    <label className='category-title '>Deposit date</label>
    <div>
      Buyer shall deliver deposit within
      <input
        type="number"
        className="form-input"
        style={{ "marginRight": "10px", "marginLeft": "10px", width: '80px' }}
        placeholder='3'
        value={formData.dueDate}
        onChange={(e) => formChangeHandler("dueDate", e.target.value)}
      /> business Days after this agreement acceptance.
    </div>
    {errors && errors.dueDate && <label className='error-message-form '>{errors.dueDate}</label>}
    <label className='category-title '>Deposit method</label>
    <RadioButtonGroup
      options={methods}
      value={formData.method}
      showOther={true}
      onChange={(value) => formChangeHandler("method", value)}
    />
    {errors && errors.method && <label className='error-message-form '>{errors.method}</label>}
    <label className='category-title '>Deposit amount</label>
    <div className='flex-box-row' style={{ "alignItems": "center" }}>
      <label style={{ "marginRight": "10px" }}>$</label>
      <input
        type="number"
        className="form-input"
        style={{ "marginRight": "10px" }}
        placeholder='300,000'
        value={formData.amount}
        onChange={(e) => formChangeHandler("amount", e.target.value)}
      />
      <input
        className="form-input"
        style={{ "marginRight": "10px", "minWidth": "350px" }}
        placeholder='Three hundred thousand'
        value={formData.amountInWords}
        onChange={(e) => formChangeHandler("amountInWords", e.target.value)}
      />
      <label>Dollars</label>
    </div>
    {errors && errors.amount && <label className='error-message-form '>{errors.amount}</label>}
    {errors && errors.amountInWords && <label className='error-message-form '>{errors.amountInWords}</label>}
  </div>
};

const Contigencies = ({ formData, formChangeHandler, errors }) => {
  const options = [
    { value: 'appraisal', label: "Appraisal contigency" },
    { value: 'loan', label: "Loan contigency" },
    { value: 'inspection', label: "Inspection contigency" },
    { value: 'exchange', label: "Sell Buyer's home first" },
    { value: 'none', label: "None of above" },
  ];

  const handleChange = (event) => {
    const { name, checked } = event.target;
    const newChoice = checked ? [...formData.choice, name] : formData.choice.filter(option => option !== name)
    if (newChoice.includes('none')) {
      formChangeHandler("choice", ['none']);
      return;
    }
    formChangeHandler("choice", newChoice);
  };

  const onChange = (parentKey, key, value) => {
    formChangeHandler(parentKey, { [key]: value })
  }

  return <div className="step">
    <label className='form-title '>Contigency</label>
    <label className='category-title '>Which contigencies would you like to put on your purchase offer</label>
    <div className='options-container'>
      {options.map((item, index) => (
        <div key={index} className="option-container" style={{ marginTop: '10px' }}>
          <input
            type="checkbox"
            name={item.value}
            checked={formData.choice.includes(item.value)}
            onChange={handleChange}
            disabled={formData.choice.includes('none') && item.value !== "none"}
            style={{ "marginRight": "10px" }}
          />
          <div>{item.label}</div>
        </div>
      ))}
      {errors && errors.choice && <label className='error-message-form '>{errors.choice}</label>}
      {formData.choice.includes('inspection') && <div>
        <div className='category-title'>Inspection contigency</div>
        <div>
          Inspection contigency will be removed within
          <input
            type="number"
            className="form-input"
            style={{ "marginRight": "10px", "marginLeft": "10px", width: '80px' }}
            placeholder='15'
            value={formData.inspection.removeDate}
            onChange={(e) => onChange("inspection", "removeDate", e.target.value)}
          /> Days after this agreement acceptance
        </div>
        {errors && errors.inspection?.removeDate && <label className='error-message-form '>{errors.inspection.removeDate}</label>}
        <div>
          After Buyer report inspection defect to Seller, negotiation period is
          <input
            type="number"
            className="form-input"
            style={{ "marginRight": "10px", "marginLeft": "10px", width: '80px' }}
            placeholder='5'
            value={formData.inspection.negotiationPeriod}
            onChange={(e) => onChange("inspection", "negotiationPeriod", e.target.value)}
          /> Days.
        </div>
        {errors && errors.inspection?.negotiationPeriod && <label className='error-message-form '>{errors.inspection.negotiationPeriod}</label>}
      </div>}
      {formData.choice.includes('loan') && <div>
        <div className='category-title'>Loan contigency</div>
        <div>
          Loan contigency will be removed within
          <input
            type="number"
            className="form-input"
            style={{ "marginRight": "10px", "marginLeft": "10px", width: '80px' }}
            placeholder='21'
            value={formData.loan.removeDate}
            onChange={(e) => onChange("loan", "removeDate", e.target.value)}
          /> Days after this agreement acceptance
        </div>
        {errors && errors.loan?.removeDate && <label className='error-message-form '>{errors.loan.removeDate}</label>}
      </div>}
      {formData.choice.includes('appraisal') && <div>
        <div className='category-title'>Appraisal contigency</div>
        <div>
          Appraisal contigency will be removed within
          <input
            type="number"
            className="form-input"
            style={{ "marginRight": "10px", "marginLeft": "10px", width: '80px' }}
            placeholder='17'
            value={formData.appraisal.removeDate}
            onChange={(e) => onChange("appraisal", "removeDate", e.target.value)}
          /> Days after this agreement acceptance.
        </div>
        {errors && errors.appraisal?.negotiationPeriod && <label className='error-message-form '>{errors.appraisal.negotiationPeriod}</label>}
        <div>
          After Buyer deliver Appraisal report to Seller, negotiation period is
          <input
            type="number"
            className="form-input"
            style={{ "marginRight": "10px", "marginLeft": "10px", width: '80px' }}
            placeholder='5'
            value={formData.appraisal.negotiationPeriod}
            onChange={(e) => onChange("appraisal", "negotiationPeriod", e.target.value)}
          /> Days.
        </div>
        {errors && errors.appraisal?.removeDate && <label className='error-message-form '>{errors.appraisal.removeDate}</label>}
      </div>}
      {formData.choice.includes('exchange') && <div>
        <div className='category-title'>Home sale contigency</div>
        <div>
          If Home located:
          <AddressComponent
            value={formData.exchange.address}
            onChange={(value) => onChange("exchange", "address", value)}
            errors={errors?.exchange?.address}
          />
        </div>
        <div>
          isn't sold within
          <input
            type="number"
            className="form-input"
            style={{ "marginRight": "10px", "marginLeft": "10px", width: '80px' }}
            placeholder='20'
            value={formData.exchange.removeDate}
            onChange={(e) => onChange("exchange", "removeDate", e.target.value)}
          /> Days after this agreement acceptance, Buyer can get deposit back.
        </div>
        {errors && errors.exchange?.removeDate && <label className='error-message-form '>{errors.exchange.removeDate}</label>}
      </div>}
    </div>
  </div>
};

const ItemsInSale = ({ formData, formChangeHandler, errors }) => {
  const items = [
    { value: 'refrigator', label: "Refrigator" },
    { value: 'washer', label: "Washer" },
    { value: 'dryer', label: "Dryer" },
    { value: 'heater', label: "Heater" },
    { value: 'furnace', label: "Furnace" },
    { value: 'aircondition', label: "Air condition" },
    { value: 'stove', label: "Stove" },
  ];

  return <div className="step">
    <label className='form-title '>Items incluede in sale</label>
    <label className='category-title'>Will Buyer obtain any of Seller's personal property and fixtures</label>
    <RadioButtonGroup
      options={items}
      type="checkbox"
      showOther={true}
      value={formData.items}
      onChange={(value) => formChangeHandler("items", value)}
      placeholder="Item1, Item2"
    />
  </div>
};


const Closing = ({ formData, formChangeHandler, errors }) => {
  const payer = [
    { value: 'b100', label: "Buyer 100%" },
    { value: 's100', label: "Seller 100%" },
    { value: 'split', label: "Buyer and Seller (50/50)" },
  ];

  return <div className="step">
    <label className='form-title'>Closing</label>
    <label className='category-title'>Who will pay the closing cost</label>
    <RadioButtonGroup
      options={payer}
      value={formData.cost}
      onChange={(value) => formChangeHandler("cost", value)}
    />
    {errors && errors.cost && <label className='error-message-form '>{errors.cost}</label>}
    <label className='category-title'>Closing Date</label>
    <div>
      Close of ESCROW shall be occur on
      <input
        type="number"
        className="form-input"
        style={{ "marginRight": "10px", "marginLeft": "10px", width: '80px' }}
        placeholder='30'
        value={formData.closeDays}
        onChange={(e) => formChangeHandler("closeDays", e.target.value)}
      /> Days after this agreement acceptance.
    </div>
    {errors && errors.closeDays && <label className='error-message-form '>{errors.closeDays}</label>}
    <label className='category-title'>Seller vacate Date</label>
    <div>
      Seller shall vacate property on the date of Close of ESCROW, no later than
      <input
        type="number"
        className="form-input"
        style={{ "marginRight": "10px", "marginLeft": "10px", width: '80px' }}
        placeholder='1'
        value={formData.sellerVacateDays}
        onChange={(e) => formChangeHandler("sellerVacateDays", e.target.value)}
      /> calendar Days after closing day (Close of ESCROW).
    </div>
    {errors && errors.sellerVacateDays && <label className='error-message-form '>{errors.sellerVacateDays}</label>}
    <div>
      Buyer final walk through and verification day
      <input
        type="number"
        className="form-input"
        style={{ "marginRight": "10px", "marginLeft": "10px", width: '80px' }}
        placeholder='1'
        value={formData.verificationDays}
        onChange={(e) => formChangeHandler("verificationDays", e.target.value)}
      /> calendar Days before closing day (Close of ESCROW).
    </div>
    {errors && errors.verificationDays && <label className='error-message-form '>{errors.verificationDays}</label>}
  </div>
};

const OtherQuestions = ({ formData, formChangeHandler, errors }) => {
  const onChange = (parentKey, key, value) => {
    formChangeHandler(parentKey, { [key]: value })
  }

  return <div className="step">
    <label className='form-title'>Other Questions</label>
    <label className='category-title'>Seller Disclosure</label>
    <label>
      Seller has
      <input
        type="number"
        className="form-input"
        style={{ "marginRight": "10px", "marginLeft": "10px", width: '80px' }}
        placeholder='7'
        value={formData.disclosure.dueDate}
        onChange={(e) => onChange("disclosure", "dueDate", e.target.value)}
      /> Days after this agreement acceptance to Deliver to Buyer all reports
    </label>
    {errors && errors.disclosure.dueDate && <label className='error-message-form '>{errors.disclosure.dueDate}</label>}
    <label className='category-title'>Expiration of this agreement</label>
    <label>
      Seller has
      <input
        type="number"
        className="form-input"
        style={{ "marginRight": "10px", "marginLeft": "10px", width: '80px' }}
        placeholder='5'
        value={formData.expiration?.dueDate}
        onChange={(e) => onChange("expiration", "dueDate", e.target.value)}
      /> Days to accept this agreement after Buyer sign and deliver this agreement to Seller.
    </label>
    {errors && errors.expiration?.dueDate && <label className='error-message-form '>{errors.expiration.dueDate}</label>}
    <label className='category-title'>Title research</label>
    <label>
      Buyer has
      <input
        type="number"
        className="form-input"
        style={{ "marginRight": "10px", "marginLeft": "10px", width: '80px' }}
        placeholder='5'
        value={formData.title.reviewDays}
        onChange={(e) => onChange("title", "reviewDays", e.target.value)}
      /> Days to review title report and report problem to Seller.
    </label>
    {errors && errors.title.reviewDays && <label className='error-message-form '>{errors.title.reviewDays}</label>}
    <label>
      Seller has
      <input
        type="number"
        className="form-input"
        style={{ "marginRight": "10px", "marginLeft": "10px", width: '80px' }}
        placeholder='10'
        value={formData.title.correctDays}
        onChange={(e) => onChange("title", "correctDays", e.target.value)}
      /> Days to correct title issue after Buyer nofity Seller.
    </label>
    {errors && errors.title.correctDays && <label className='error-message-form '>{errors.title.correctDays}</label>}
    <label className='category-title'>Survey problem</label>
    <label>
      Buyer has
      <input
        type="number"
        className="form-input"
        style={{ "marginRight": "10px", "marginLeft": "10px", width: '80px' }}
        placeholder='5'
        value={formData.survey.reportDays}
        onChange={(e) => onChange("survey", "reportDays", e.target.value)}
      /> Days to report survey problem after this agreement acceptance.
    </label>
    {errors && errors.survey.reportDays && <label className='error-message-form '>{errors.survey.reportDays}</label>}
    <label>
      Sellar has
      <input
        type="number"
        className="form-input"
        style={{ "marginRight": "10px", "marginLeft": "10px", width: '80px' }}
        placeholder='10'
        value={formData.survey.correctDays}
        onChange={(e) => onChange("survey", "correctDays", e.target.value)}
      /> Days to to correct survey problem after Buyer nofity Seller.
    </label>
    {errors && errors.survey.correctDays && <label className='error-message-form '>{errors.survey.correctDays}</label>}
  </div>
};

const Addenda = ({ formData, formChangeHandler, errors }) => {
  const addItem = () => {
    const updatedData = [...formData.items, ''];
    formChangeHandler("items", updatedData);
  };

  const removeItem = (indexToRemove) => {
    const updatedData = formData.items.filter((_, index) => index !== indexToRemove);
    formChangeHandler("items", updatedData);
  };

  const handleAddendaChange = (index, e) => {
    const updatedData = formData.items.map((item, i) => i === index ? e.target.value : item);
    formChangeHandler("items", updatedData);
  };


  return <div className="step">
    <label className='form-title'>Addendas</label>
    <label className='category-title'>Add your filename of addendas</label>
    {formData.items.map((item, index) => (
      <div key={index}>
        <input
          type="text"
          className='form-input'
          value={item}
          onChange={(e) => handleAddendaChange(index, e)}
          placeholder={`Addenda file ${index + 1}`}
          style={{ "width": "90%" }}
        />
        {index !== 0 && <span className='form-icon' onClick={() => removeItem(index)}><i className='fa fa-trash' ></i></span>}
      </div>
    ))}
    <span onClick={addItem} className='form-icon'><i className='fa fa-plus' ></i></span>
  </div>
};

const ProgressBar = ({ currentStep, totalSteps }) => {
  const progressPercentage = ((currentStep - 1) / (totalSteps - 1)) * 100 + '%';
  return (
    <div className="progress-bar-container">
      <div className="progress-bar" style={{ width: progressPercentage }}></div>
    </div>
  );
};


const MultiStepForm = inject("authStore")(observer((props) => {
  const { pathname, search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const id = queryParams.get('id');
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState(purchaseDoc);
  const [errors, setErrors] = useState({});
  const [stepEnabled, setStepEnabled] = useState({ 1: true, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false, 8: false, 9: false, 10: false });
  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayMessage, setOverlayMessage] = useState("");
  const [isBottom, setIsBottom] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    try {
      if (!!id && id !== "")
        agent.Offer.getPurchaseForm(id, 2).then((response) => {
          if (response) {
            setFormData(response.doc);
            setCurrentStep(response.doc.currentStep ?? 1);
            setStepEnabled(prevState => {
              const newState = {};
              for (let step = 1; step <= response.doc.currentStep; step++) {
                newState[step] = true;
              }
              return { ...prevState, ...newState };
            });
          }
        });
      else {
        setFormData(purchaseDoc);
      }
      const saved = queryParams.get("saved")
      if (saved) {
        showReminder("Form saved successfully");
        const param = !!id && id !== "" ? `?id=${id}` : ""
        props.history.replace(`${pathname}${param}`)
      }
    } catch (error) {
      console.error('Error during checkout:', error.response || error);
    }
  }, [id]);

  const onCloseReminder = () => {
    setShowOverlay(false);
    setOverlayMessage("");
  }

  const showReminder = (message) => {
    setShowOverlay(true);
    setOverlayMessage(message);
  }

  const onScroll = () => {
    if (!containerRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    const resizeObserver = new ResizeObserver(entries => {
      onScroll();
    });

    resizeObserver.observe(container);
    container.addEventListener('scroll', onScroll);

    return () => {
      resizeObserver.unobserve(container);
      container.removeEventListener('scroll', onScroll);
    };
  }, []);


  const handleNext = () => {
    const current = steps.find(step => step.id === currentStep);
    if (current && !validateForm(current.value)) {
      return;
    }
    setCurrentStep(currentStep + 1);
    setStepEnabled(prevState => {
      const newState = {};
      for (let step = 1; step <= currentStep; step++) {
        newState[step] = true;
      }
      return { ...prevState, ...newState };
    });
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
    setStepEnabled(prevState => {
      const newState = {};
      for (let step = 1; step <= currentStep; step++) {
        newState[step] = true;
      }
      return { ...prevState, ...newState };
    });
  };

  const saveForm = async () => {
    try {
      formData.currentStep = currentStep;
      const response = await agent.Offer.editPurchaseForm(id, formData, false);
      if (response.id) {
        if (!!id && id !== "") {
          showReminder("Form saved successfully");
        } else {
          props.history.push(`/edit/purchase-form?id=${response.id}&saved=1`);
        }
      } else {
        showReminder("Form saved failed");
      }
    } catch (error) {
      showReminder("Form saved failed");
      console.error('Error during save:', error.response || error);
      if (error.status === 401) {
        const pathWithoutHost = window.location.pathname + window.location.search;
        localStorage.setItem('prevLocation', pathWithoutHost);
        props.history.push("/login");
      }
    }
  }

  const submitForm = async () => {
    const current = steps.find(step => step.id === currentStep);
    if (current && !validateForm(current.value)) {
      return;
    }
    try {
      formData.currentStep = currentStep;
      const response = await agent.Offer.editPurchaseForm(id, formData, true);
      if (response.id) {
        props.history.push(`/preview/purchase-form?id=${response.id}&saved=1`);
      }
    } catch (error) {
      console.error('Error during submit:', error.response || error);
      if (error.status === 401) {
        const pathWithoutHost = window.location.pathname + window.location.search;
        localStorage.setItem('prevLocation', pathWithoutHost);
        props.history.push("/login");
      }
    }
  }

  const handleChange = (section, key, value) => {
    setErrors({});
    setFormData(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [key]: Array.isArray(value) ? value : (typeof value === 'object' && value !== null ? { ...prev[section][key], ...value } : value)
      },
    }));
  };

  const setCurrentStepId = (id, e) => {
    const current = steps.find(step => step.id === currentStep);
    if (current && !validateForm(current.value)) {
      return;
    }
    setCurrentStep(id);
  };

  const validateForm = (name) => {
    const newErrors = {};

    Object.entries(requestFieldOfPurchaseDoc).forEach(([section, fields]) => {
      if (section != name) return;
      const method = typeof fields !== 'object' ? validationMethods[fields] : null;
      if (method) {
        const result = method(formData[section]);
        if (result !== "")
          newErrors[section] = result;
        return;
      }
      const sectionErrors = validateFields(fields, formData[section]);
      if (Object.keys(sectionErrors).length > 0) {
        newErrors[section] = sectionErrors;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateFields = (fields, data) => {
    const fieldErrors = {};

    Object.entries(fields).forEach(([key, value]) => {
      if (typeof value === 'object') {
        const nestedErrors = validateFields(value, data[key]);
        if (Object.keys(nestedErrors).length > 0) {
          fieldErrors[key] = nestedErrors;
        }
      } else {
        const fieldValue = data[key];
        const method = validationMethods[value];
        if (method) {
          const result = method(fieldValue);
          if (result !== "")
            fieldErrors[key] = result;
        }
      }
    });

    return fieldErrors;
  };

  const steps = [
    {
      id: 1, name: "Property information", value: "property", component: <PropertyPage formData={formData.property} formChangeHandler={(key, value) => handleChange("property", key, value)} errors={errors.property} />,
    },
    {
      id: 2, name: "Buyer information", value: "buyers", component: <BuyerInfo formData={formData.buyers} formChangeHandler={(key, value) => handleChange("buyers", key, value)} errors={errors.buyers} />,
    },
    {
      id: 3, name: "Seller information", value: "sellers", component: <SellerInfo formData={formData.sellers} formChangeHandler={(key, value) => handleChange("sellers", key, value)} errors={errors.sellers} />,
    },
    {
      id: 4, name: "Purchase", value: "purchase", component: <Purchase formData={formData.purchase} formChangeHandler={(key, value) => handleChange("purchase", key, value)} errors={errors.purchase} />,
    },
    {
      id: 5, name: "Deposit", value: "deposit", component: <Deposit formData={formData.deposit} formChangeHandler={(key, value) => handleChange("deposit", key, value)} errors={errors.deposit} />,
    },
    {
      id: 6, name: "Contigencies", value: "contigencies", component: <Contigencies formData={formData.contigencies} formChangeHandler={(key, value) => handleChange("contigencies", key, value)} errors={errors.contigencies} />,
    },
    {
      id: 7, name: "Items included in sale", value: "property", component: <ItemsInSale formData={formData.itemsOnSale} formChangeHandler={(key, value) => handleChange("itemsOnSale", key, value)} errors={errors.itemsOnSale} />,
    },
    {
      id: 8, name: "Closing", value: "closing", component: <Closing formData={formData.closing} formChangeHandler={(key, value) => handleChange("closing", key, value)} errors={errors.closing} />,
    },
    {
      id: 9, name: "Other questions", value: "questions", component: <OtherQuestions formData={formData.questions} formChangeHandler={(key, value) => handleChange("questions", key, value)} errors={errors.questions} />,
    },
    {
      id: 10, name: "Addenda", value: "addenda", component: <Addenda formData={formData.addenda} formChangeHandler={(key, value) => handleChange("addenda", key, value)} errors={errors.property} />,
    },
  ];

  const finalStep = steps.length;

  const renderStep = () => {
    const current = steps.find(step => step.id === currentStep);
    return current ? current.component : <div>Step not found</div>;
  };

  if (!formData) {
    return;
  }
  return <div className="form-container">
    <ProgressBar currentStep={currentStep} totalSteps={finalStep} />
    <div className='flex-box-row'>
      <div style={{ marginRight: '20px', "width": "20%" }}>
        {steps.map((step) => (
          <div
            key={step.id}
            className={`nav-item ${!stepEnabled[step.id] ? 'disabled' : ''}`}
            style={{ color: currentStep === step.id ? '#bd1f1f' : 'black' }}
            onClick={() => setCurrentStepId(step.id)}
          >
            {step.name}
          </div>
        ))}
      </div>
      <div style={{ "width": "80%" }} >
        <div className='scrollable-container' ref={containerRef} onScroll={onScroll}>
          {renderStep()}
        </div>

        <div className='flex-box-row flex-box-space-between'>
          <button onClick={saveForm}>Save for later</button>
          {!isBottom && <span className="contact-icon"><i className='fa fa-arrow-down'></i></span>}
          <div>
            {currentStep !== 1 && <button onClick={handleBack} style={{ "marginRight": "10px" }}><i className='fas fa-arrow-left'></i> Back</button>}
            {currentStep !== finalStep && <button onClick={handleNext}>Next <i className='fas fa-arrow-right'></i></button>}
            {currentStep === finalStep && <button onClick={submitForm}>Submit</button>}
          </div>
        </div>
      </div>
    </div>
    <OverlayReminder show={showOverlay} message={overlayMessage} onClose={onCloseReminder} />
  </div >;
}));

export default MultiStepForm;