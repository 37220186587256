import React, { useState, useRef, useEffect } from 'react';
import AddressComponent from "../shared/AddressComponent2";
import RadioButtonGroup from '../shared/RadioButtonGroup';
import agent from '../agent';
import { purchaseRequest, booleanOptions, validationMethods, requestFieldOfPurchaseRequest } from '../shared/PurchaseForm';
import { useLocation } from 'react-router-dom';
import TopNav from '../LandingPage/TopNav/TopNav';

import style from "./OfferRequest";
import 'react-datepicker/dist/react-datepicker.css';
import { inject, observer } from 'mobx-react';
import AuthorForm from '../shared/AuthorForm';
import { loanVerification } from "../shared/options";
import OverlayReminder from "../shared/OverlayReminder";


const PropertyPage = ({ formData, formChangeHandler, errors }) => {
  const radioOptions = [
    { value: 'singlefamily', label: "Single-Family Home" },
    { value: 'condo', label: "Condominium" },
    { value: 'townhouse', label: "Townhouse" },
    { value: 'pud', label: "Planned Unit Development(PUD)" },
  ];

  return <div className="step">
    <label className='form-title'>Property Info</label>
    <div>
      <div name="type" className='category-title'>Property type</div>
      <RadioButtonGroup
        id="propery-key"
        key="property-type"
        options={radioOptions}
        showOther={true}
        value={formData.type}
        onChange={(value) => formChangeHandler("type", value)}
      />
      {errors && errors.type && <label className='error-message-form '>{errors.type}</label>}
      <div className='category-title'>Property located at</div>
      <AddressComponent
        onChange={(value) => formChangeHandler("address", value)}
        value={formData.address}
        errors={errors?.address}
      />
    </div>
  </div>
};

const BuyerInfo = ({ formData, formChangeHandler, errors }) => {
  return <div className="step">
    <label className='form-title'>Buyers' Infomation</label>
    <AuthorForm
      label={"Buyer"}
      value={formData}
      onChange={formChangeHandler}
      errors={errors}
    />
  </div>
};

const Agent = ({ formData, formChangeHandler, errors }) => {
  const agentTypeOptions = [
    { value: 'fullService', label: "Full service" },
    { value: 'flatRate', label: "Flat rate" },
  ];

  return <div className="step">
    <label className='form-title'>Agent</label>
    <div className='category-title'>Which type of service would you like to choose?</div>
    <RadioButtonGroup
      options={agentTypeOptions}
      value={formData.type}
      onChange={(value) => formChangeHandler("type", value)}
    />
    {errors && errors.type && <label className='error-message-form '>{errors.type}</label>}
    {!!formData.type && formData.type !== '' && <div>
      <div className='category-title'>Commission</div>
      {formData.type == "flatRate" && <div>
        <label style={{ "marginRight": "10px" }}>Commission you would like to pay agent. $</label>
        <input
          className="form-input"
          style={{ "marginRight": "10px" }}
          placeholder='7000'
          value={formData.commission}
          onChange={(e) => formChangeHandler("commission", e.target.value)}
        />
      </div>}
      {formData.type == "fullService" && <div>
        <label style={{ "marginRight": "10px" }}>Commission you would like to pay agent. Percentage of property price. (%)</label>
        <input
          className="form-input"
          placeholder='2.5'
          style={{ "width": "60px" }}
          value={formData.commission}
          onChange={(e) => formChangeHandler("commission", e.target.value)}
        />
      </div>
      }
      {errors && errors.commission && <label className='error-message-form '>{errors.commission}</label>}
    </div>}
    <label className='category-title'>Do you accept agent work remotely?</label>
    <RadioButtonGroup
      options={booleanOptions}
      value={formData.remote}
      onChange={(value) => formChangeHandler("remote", value)}
    />
    {errors && errors.remote && <label className='error-message-form '>{errors.remote}</label>}
    <label className='category-title'>Comment</label>
    <textarea
      name="legal-description"
      className='form-input'
      style={{ "width": '100%', "height": "100px" }}
      value={formData.comment}
      placeholder='What else you would tell agent'
      onChange={(e) => formChangeHandler("comment", e.target.value)}
    />
    <label>You would agent complete your offer and sent it to Seller within
      <input
        className="form-input"
        placeholder='2'
        style={{ "width": "60px", "marginLeft": "10px", "marginRight": "10px" }}
        value={formData.dayToMakeOffer}
        onChange={(e) => formChangeHandler("dayToMakeOffer", e.target.value)}
      /> day(s)</label>
    {errors && errors.dayToMakeOffer && <label className='error-message-form '>{errors.dayToMakeOffer}</label>}
  </div>
};

const Purchase = ({ formData, formChangeHandler, errors }) => {
  const purchaseOptions = [
    { value: 'allcash', label: "All cash" },
    { value: 'financing', label: "Financing" },
  ];
  const typeOfFinancing = [
    { value: 'conventional', label: "Conventional Loan (Mortgage)" },
    { value: 'fha', label: "FHA Loan" },
    { value: 'va', label: "VA Loan" },
    { value: 'seller', label: "Seller Financing" },
  ];

  const downPaymentChange = (e) => {
    formChangeHandler("downPayment", e.target.value)
    formChangeHandler("loanAmount", Number(formData.price) - Number(e.target.value))
  }

  const priceChange = (e) => {
    formChangeHandler("price", e.target.value)
    formChangeHandler("loanAmount", Number(e.target.value) - Number(formData.downPayment))
  }

  return <div className="step">
    <label className='form-title '>Purchase</label>
    <label className='category-title'>Purchase Info</label>
    <div className='flex-box-row' style={{ "alignItems": "center" }}>
      <label style={{ "marginRight": "10px" }}>Purchase price: </label>
      <label style={{ "marginRight": "10px" }}>$</label>
      <input
        type="number"
        className="form-input"
        style={{ "marginRight": "10px" }}
        placeholder='300,000'
        value={formData.price}
        onChange={priceChange}
      />
      {errors && errors.type && <label className='error-message-form '>{errors.type}</label>}
    </div>
    <label className='category-title'>Purchase method</label>
    <RadioButtonGroup
      options={purchaseOptions}
      value={formData.type}
      onChange={(value) => formChangeHandler("type", value)}
    />
    {errors && errors.type && <label className='error-message-form '>{errors.type}</label>}
    {formData.type === "financing" && <><label className='category-title'>Down payment amount</label>
      <div className='flex-box-row' style={{ "alignItems": "center" }}>
        <label style={{ "marginRight": "10px" }}>$</label>
        <input
          type="number"
          className="form-input"
          placeholder='60,000'
          value={formData.downPayment}
          onChange={downPaymentChange}
        />
        {formData.downPayment !== "" && <div style={{ "marginLeft": "10px" }}>Loan Amount: ${formData.loanAmount}</div>}
      </div>
      {errors && errors.downPayment && <label className='error-message-form '>{errors.downPayment}</label>}
      <label className='category-title '>Type of Financing</label>
      <RadioButtonGroup
        options={typeOfFinancing}
        value={formData.loanType}
        onChange={(value) => formChangeHandler("loanType", value)}
        showOther={true}
      />
      {errors && errors.loanType && <label className='error-message-form '>{errors.loanType}</label>}
      <label className='category-title '>Which loan verification you have?</label>
      <RadioButtonGroup
        options={loanVerification}
        value={formData.loanVerification}
        onChange={(value) => formChangeHandler("loanVerification", value)}
        showOther={true}
      />
      {errors && errors.loanVerification && <label className='error-message-form '>{errors.loanVerification}</label>}
      <label className='category-title '>Financing information</label>
      <div>
        Interest rate yearly:
        <input
          type="number"
          className="form-input"
          style={{ "marginRight": "10px", "marginLeft": "10px", width: '80px' }}
          placeholder='6'
          value={formData.loanFixedRate}
          onChange={(e) => formChangeHandler("loanFixedRate", e.target.value)}
        /> %
      </div>
      {errors && errors.loanFixedRate && <label className='error-message-form '>{errors.loanFixedRate}</label>}
      <div>
        Loan length:
        <input
          type="number"
          className="form-input"
          style={{ "marginRight": "10px", "marginLeft": "10px", width: '80px' }}
          placeholder='360'
          value={formData.lengthOfLoan}
          onChange={(e) => formChangeHandler("lengthOfLoan", e.target.value)}
        /> Months
      </div>
      {errors && errors.lengthOfLoan && <label className='error-message-form '>{errors.lengthOfLoan}</label>}
    </>}
  </div>
};

const ProgressBar = ({ currentStep, totalSteps }) => {
  const progressPercentage = ((currentStep - 1) / (totalSteps - 1)) * 100 + '%';
  return (
    <div className="progress-bar-container">
      <div className="progress-bar" style={{ width: progressPercentage }}></div>
    </div>
  );
};


const OfferRequest = inject("authStore", "userStore")(observer((props) => {
  const { pathname, search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const id = queryParams.get('id');
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState(purchaseRequest);
  const [errors, setErrors] = useState({});
  const [isBottom, setIsBottom] = useState(false);
  const [stepEnabled, setStepEnabled] = useState({ 1: true, 2: false, 3: false, 4: false });
  const containerRef = useRef(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayMessage, setOverlayMessage] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(!!props.userStore.currentUser);

  useEffect(() => {
    props.userStore.getLoginStatus().then(() => {
      setIsLoggedIn(!!props.userStore.currentUser);
    });
  }, []);

  useEffect(() => {
    try {
      if (!!id && id !== "")
        agent.Offer.getPurchaseRequest(id).then((response) => {
          if (response) {
            setFormData(response.content);
            setCurrentStep(response.content.currentStep ?? 1);
            setStepEnabled(prevState => {
              const newState = {};
              for (let step = 1; step <= response.content.currentStep; step++) {
                newState[step] = true;
              }
              return { ...prevState, ...newState };
            });
          }
        });
      else {
        setFormData(purchaseRequest);
      }
      const saved = queryParams.get("saved")
      if (saved) {
        showReminder("Form saved successfully");
        const param = !!id && id !== "" ? `?id=${id}` : ""
        props.history.replace(`${pathname}${param}`)
      }
    } catch (error) {
      console.error('Error during checkout:', error.response || error);
    }
  }, [id]);

  const onScroll = () => {
    if (!containerRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    const resizeObserver = new ResizeObserver(entries => {
      onScroll();
    });

    resizeObserver.observe(container);
    container.addEventListener('scroll', onScroll);

    return () => {
      resizeObserver.unobserve(container);
      container.removeEventListener('scroll', onScroll);
    };
  }, []);

  const onCloseReminder = () => {
    setShowOverlay(false);
    setOverlayMessage("");
  }

  const showReminder = (message) => {
    setShowOverlay(true);
    setOverlayMessage(message);
  }

  const handleNext = () => {
    const current = steps.find(step => step.id === currentStep);
    if (current && !validateForm(current.value)) {
      return;
    }
    setCurrentStep(currentStep + 1);
    setStepEnabled(prevState => {
      const newState = {};
      for (let step = 1; step <= currentStep; step++) {
        newState[step] = true;
      }
      return { ...prevState, ...newState };
    });
  };

  const handleBack = () => {
    const current = steps.find(step => step.id === currentStep);
    if (current && !validateForm(current.value)) {
      return;
    }
    setCurrentStep(currentStep - 1);
  };

  const saveForm = async () => {
    try {
      formData.currentStep = currentStep;
      const response = await agent.Offer.editPurchaseRequest(id, formData, false);
      if (response.id) {
        if (!!id && id !== "") {
          showReminder("Form saved successfully");
        } else {
          props.history.push(`/edit/purchase-request?id=${response.id}&saved=1`);
        }
      } else {
        showReminder("Form saved failed");
      }
    } catch (error) {
      showReminder("Form saved failed");
      console.error('Error during save:', error.response || error);
      if (error.status === 401) {
        const pathWithoutHost = window.location.pathname + window.location.search;
        localStorage.setItem('prevLocation', pathWithoutHost);
        props.history.push("/login");
      }
    }
  }

  const submitForm = async () => {
    const current = steps.find(step => step.id === currentStep);
    if (current && !validateForm(current.value)) {
      return;
    }
    try {
      formData.currentStep = currentStep;
      const response = await agent.Offer.editPurchaseRequest(id, formData, true);
      if (response.id) {
        props.history.push(`/preview/purchase-request?id=${response.id}`);
      }
    } catch (error) {
      console.error('Error during submit:', error.response || error);
      if (error.status === 401) {
        const pathWithoutHost = window.location.pathname + window.location.search;
        localStorage.setItem('prevLocation', pathWithoutHost);
        props.history.push("/login");
      }
    }
  }

  const handleChange = (section, key, value) => {
    setErrors({});
    setFormData(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [key]: Array.isArray(value) ? value : (typeof value === 'object' && value !== null ? { ...prev[section][key], ...value } : value)
      },
    }));
  };

  const validateForm = (name) => {
    const newErrors = {};

    Object.entries(requestFieldOfPurchaseRequest).forEach(([section, fields]) => {
      if (section != name) return;
      const method = typeof fields !== 'object' ? validationMethods[fields] : null;
      if (method) {
        const result = method(formData[section]);
        if (result !== "")
          newErrors[section] = result;
        return;
      }
      const sectionErrors = validateFields(fields, formData[section]);
      if (Object.keys(sectionErrors).length > 0) {
        newErrors[section] = sectionErrors;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateFields = (fields, data) => {
    const fieldErrors = {};

    Object.entries(fields).forEach(([key, value]) => {
      if (typeof value === 'object') {
        const nestedErrors = validateFields(value, data[key]);
        if (Object.keys(nestedErrors).length > 0) {
          fieldErrors[key] = nestedErrors;
        }
      } else {
        const fieldValue = data[key];
        const method = validationMethods[value];
        if (method) {
          const result = method(fieldValue);
          if (result !== "")
            fieldErrors[key] = result;
        }
      }
    });

    return fieldErrors;
  };

  const setCurrentStepId = (id, e) => {
    const current = steps.find(step => step.id === currentStep);
    if (current && !validateForm(current.value)) {
      return;
    }
    setCurrentStep(id);
  };

  const steps = [
    {
      id: 1, name: "Property information", value: "property", component: <PropertyPage formData={formData.property} formChangeHandler={(key, value) => handleChange("property", key, value)} errors={errors.property} />,
    },
    {
      id: 2, name: "Buyer information", value: "buyers", component: <BuyerInfo formData={formData.buyers} formChangeHandler={(key, value) => handleChange("buyers", key, value)} errors={errors.buyers} />,
    },
    {
      id: 3, name: "Agent information", value: "agent", component: <Agent formData={formData.agent} formChangeHandler={(key, value) => handleChange("agent", key, value)} errors={errors.agent} />,
    },
    {
      id: 4, name: "Purchase", value: "purchase", component: <Purchase formData={formData.purchase} formChangeHandler={(key, value) => handleChange("purchase", key, value)} errors={errors.purchase} />,
    }
  ];

  const finalStep = steps.length;

  const renderStep = () => {
    const current = steps.find(step => step.id === currentStep);
    return current ? current.component : <div>Step not found</div>;
  };

  return <div className="page">
    <TopNav isLoggedIn={isLoggedIn} />
    <div className="offer-container">
      <div className="form-container">
        <ProgressBar currentStep={currentStep} totalSteps={finalStep} />
        <div className='flex-box-row'>
          <div style={{ marginRight: '20px', "width": "20%" }}>
            {steps.map((step) => (
              <div
                key={step.id}
                className={`nav-item ${!stepEnabled[step.id] ? 'disabled' : ''}`}
                style={{ color: currentStep === step.id ? '#bd1f1f' : 'black' }}
                onClick={() => setCurrentStepId(step.id)}
              >
                {step.name}
              </div>
            ))}
          </div>
          <div style={{ "width": "80%" }} >
            <div className='scrollable-container' ref={containerRef} onScroll={onScroll}>
              {renderStep()}
            </div>

            <div className='flex-box-row flex-box-space-between'>
              <button onClick={saveForm}>Save for later</button>
              {!isBottom && <span className="contact-icon"><i className='fa fa-arrow-down'></i></span>}
              <div>
                {currentStep !== 1 && <button onClick={handleBack} style={{ "marginRight": "10px" }}><i className='fas fa-arrow-left'></i> Back</button>}
                {currentStep !== finalStep && <button onClick={handleNext}>Next <i className='fas fa-arrow-right'></i></button>}
                {currentStep === finalStep && <button onClick={submitForm}>Submit</button>}
              </div>
            </div>
          </div>
        </div>
      </div >
    </div>
    <OverlayReminder show={showOverlay} message={overlayMessage} onClose={onCloseReminder} />
  </div>;
}));

export default OfferRequest;

