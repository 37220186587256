import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { modules, formats } from '../shared/QuillEditorConfig';
import TagsInput from "../shared/TagsComp";
import TopNav from '../LandingPage/TopNav/TopNav';
import StatesSelect from "../shared/StatesSelect"
import { handleError } from "../shared/ErrorHandler";

import styles from "./CreateQuestion.css";


const CreateQuestionView = inject("questionStore", "userStore")(observer((props) => {
    const [error, setError] = useState("");
    const [content, setContent] = useState("");
    const [title, setTitle] = useState("");
    const [state, setState] = useState("");
    const [tags, setTags] = useState([]);
    const MAX_QUESTION_CHARS = 3000;
    const MAX_TITLE_CHARS = 200;
    const [leftChars, setLeftChars] = useState(MAX_QUESTION_CHARS);
    const [enablePostButton, setEnablePostButton] = useState(false);


    const handleEditDone = e => {
        props.questionStore.createQuestion(title, content.substring(0, MAX_QUESTION_CHARS), tags.join(','), state).then((response) => {
            props.history.push(`/question/${response.question.shortUrl}`)
        }).catch((error) => {
            handleError(error, props.history);
        });
    }

    const handleTitleChange = e => {
        const title = e.target.value.length > MAX_TITLE_CHARS ? e.target.value.substring(0, MAX_TITLE_CHARS) : e.target.value;
        setTitle(title);
        if (title.length > 0) {
            setEnablePostButton(true);
        } else {
            setEnablePostButton(false);
        }
    }

    const handleContentChange = value => {
        setContent(value);
        setLeftChars(MAX_QUESTION_CHARS - value.length);
    }

    const handleStateChange = e => {
        setState(e.target.value);
    };

    const handleTagsChange = tags => {
        setTags(tags);
    }

    return (<div className="page">
        <TopNav isLoggedIn={!!props.userStore.currentUser} />
        <div className="create-question-container">
            <div className="flex-box-row">
                <input
                    className="create-question-input"
                    value={title}
                    onChange={handleTitleChange}
                    placeholder="Title"
                />
                <StatesSelect
                    className="state-select"
                    value={state}
                    onChange={handleStateChange}
                />
            </div>
            <ReactQuill
                id="question"
                className="create-question-textarea"
                value={content}
                onChange={handleContentChange}
                modules={modules}
                formats={formats}
                placeholder="Write your question"
            />
            <div className="question-content-comment">Maximum characters {MAX_QUESTION_CHARS}, {leftChars} characters left.</div>
            <TagsInput className="create-question-input" onChange={handleTagsChange} />
            <button className="create-question-button" onClick={handleEditDone} disabled={!enablePostButton}>Create</button>
        </div>
    </div>);
}));


export default CreateQuestionView;