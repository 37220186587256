import { makeAutoObservable, reaction } from 'mobx';
import agent from '../agent';

class UserStore {

  currentUser;
  userInfo;
  photoPath;
  refreshToken;
  token;

  constructor() {
    makeAutoObservable(this);
    const storedUser = window.localStorage.getItem('currentUser');
    if (storedUser) {
      this.currentUser = storedUser;
    }

    const token = window.localStorage.getItem('jwt');
    if (token) {
      this.token = token;
    }

    const refreshToken = window.localStorage.getItem('refreshToken');
    if (refreshToken) {
      this.refreshToken = refreshToken;
    }

    window.addEventListener('storage', event => {
      if (event.key === 'currentUser') {
        const storedUser = window.localStorage.getItem('currentUser');
        this.currentUser = storedUser;
      }
      if (event.key === 'jwt') {
        const token = window.localStorage.getItem('jwt');
        this.token = token;
      }
      if (event.key === 'refreshToken') {
        const refreshToken = window.localStorage.getItem('refreshToken');
        this.refreshToken = refreshToken;
      }
    });
  }

  pullUser() {
    return agent.Auth.current()
      .then((response) => {
        this.setUserInfo(response);
        this.setCurrentUser(response.email);
      });
  }

  updateUser() {
    return agent.Auth.save(this.userInfo)
      .then((response) => { this.userInfo = response; });
  }

  uploadUserPhoto(file) {
    return agent.Auth.uploadUserPhoto(file)
      .then((response) => { this.photoPath = response.photoPath; });
  }

  setCurrentUser(user) {
    this.currentUser = user;
    if (user) {
      window.localStorage.setItem('currentUser', user);
    } else {
      window.localStorage.removeItem('currentUser');
    }
  }

  setUserInfo(userInfo) {
    this.userInfo = userInfo
  }

  getLoginStatus() {
    return agent.Auth.refreshToken(this.refreshToken, this.token)
      .then((response) => {
        this.setCurrentUser(response.email)
        this.setToken(response.access_token);
      })
      .catch((error) => {
        this.setCurrentUser(undefined)
        this.setToken(undefined);
        this.setRefreshToken(undefined);
      });
  }
  setToken(token) {
    this.token = token;
    if (token) {
      window.localStorage.setItem('jwt', token);
    } else {
      window.localStorage.removeItem('jwt');
    }
  }

  setRefreshToken(token) {
    this.refreshToken = token;
    if (token) {
      window.localStorage.setItem('refreshToken', token);
    } else {
      window.localStorage.removeItem('refreshToken');
    }
  }

  setAppLoaded() {
    this.appLoaded = true;
  }

  sendInquiryEmail(name, email, message, phone, toEmail) {
    return agent.Auth.sendInquiryEmail(name, email, message, phone, toEmail).then(() => {
    }).catch((error) => {
    });;
  }

  shareViaEmail(toEmail, content) {
    return agent.Auth.shareViaEmail(toEmail, content).then(() => {
    }).catch((error) => {
    });
  }
}

export default new UserStore();
